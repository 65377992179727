.routeContainer {
    display: flex;
    max-width: 100%;
    gap: 20px 50px;
    font-family: Inter, sans-serif;
    color: rgb(82, 82, 82);
    flex-wrap: wrap;
  }

  .route {
    display: flex;
    gap: 6px;
    font-size: 18px;
    font-weight: 600;
    flex: 1;
    width: 30%;
    align-items: center;
  }
  
  .routeLine {
    aspect-ratio: 9.01;
    width: 126px;
    object-fit: contain;
  }
  
  .routeIcon {
    aspect-ratio: 1.04;
    width: 27px;
    object-fit: contain;
  }
  
  .mealTypes {
    display: flex;
    gap: 28px;
    font-size: 16px;
    width: 65%;
    font-weight: 500;
  }
  
  .mealType {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .indicator {
    border-radius: 6px;
    width: 18px;
    height: 18px;
    border: 1px solid rgb(115, 115, 115);
  }
  
  @media (max-width: 991px) {
    .routeContainer,
    .route,
    .mealType {
      white-space: initial;
    }
  }