.heroUpperLayer {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.sections {
  width: 78rem;
  margin: 0 auto;
}
.herosidebarPrice1 {
  .herosidebarPrice {
    display: flex;
    border-radius: 0.625rem;
    border: 1px solid #bebebe;
    background: linear-gradient(
      90deg,
      rgba(61, 134, 177, 0.8) 0%,
      rgba(188, 221, 239, 0.8) 100%
    );
    width: 20rem;
    margin: 0.2rem 0;
    height: -moz-fit-content;
    height: 4rem;
  }
}
.heroContainer {
  overflow: hidden;
  width: 53.4375rem;
  background-size: cover;
  position: relative;

  height: 30.6875rem;
  flex-shrink: 0;
  border-radius: 0.9375rem;
  box-shadow: 0px 5px 4px 1px rgba(0, 0, 0, 0.25);
}
.heroSidebarWrapper {
  width: 22.4375rem;
  height: 28rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.herosidebarPrice {
  display: flex;
  border-radius: 0.625rem;
  border: 1px solid #bebebe;
  background: linear-gradient(
    90deg,
    rgba(61, 134, 177, 0.8) 0%,
    rgba(188, 221, 239, 0.8) 100%
  );
  width: 100%;
  height: -moz-fit-content;
  height: 6rem;
}
.herosidebarPrice1 {
  display: flex;
  border-radius: 0.625rem;
  border: 1px solid #bebebe;
  background: linear-gradient(
    90deg,
    rgba(61, 134, 177, 0.8) 0%,
    rgba(188, 221, 239, 0.8) 100%
  );
  width: 20rem;
  margin: 0.2rem 0;
  height: -moz-fit-content;
  height: 4rem;
}
.herosidebarPrice2 {
  display: flex;
  padding: 0.5rem;
  border-radius: 0.625rem;
  border: 1px solid #bebebe;
  background: linear-gradient(
    90deg,
    rgba(61, 134, 177, 0.8) 0%,
    rgba(188, 221, 239, 0.8) 100%
  );
  width: 100%;
  height: -moz-fit-content;
  height: 6rem;
}
.herosidebarPriceBtn {
  color: #fff;
  font-family: Helvetica;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 9rem;
  height: 3rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  background: linear-gradient(90deg, #fe6f61 0%, #fe6f61 100%);
}
.herosidebarfinalspan {
  color: #f2f2f2;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.herosidebarnotfinalspan {
  font-size: 0.72rem;
}
.sidepara {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 85%;
}
.sideparap {
  position: relative;
  color: black;
  top: 0.4rem;
  font-size: 0.6rem;
}
.herosidebarPricetext2 {
}
.herosidebarPricetext2 svg {
  width: 4rem;
  height: 5rem;
}
.herosidebarPricetext2 p {
  font-size: 0.8rem;
  font-weight: 600;
}
.herosidebarPricetext2 h1 {
  color: #fff;
  font-family: Helvetica;
  font-size: 1.5rem;
  height: 2.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.itinarybox {
  display: flex;
  flex-direction: column;
}
.itniaryboxchild1 {
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  border: 1px solid #bebebe;
  padding: 0rem 1rem;
  height: 7rem;
  display: flex;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  gap: 0.2rem;
}
.itniaryboxchild2 {
  padding: 0rem 1rem;
  height: 7rem;
  display: flex;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  gap: 0.2rem;
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  border: 1px solid #bebebe;
}
.itinaiaryabsoluteChild {
  position: absolute;
  margin: 0 AUTO;
  border-radius: 1.5625rem;
  border: 1px solid #000;
  color: #000;
  font-family: Helvetica;
  font-size: 1rem;
  font-style: normal;
  padding: 0.5rem 1rem;
  font-weight: 400;
  line-height: normal;
  background: #e9e9e9;
  transform: translate(7rem, 5.8rem);
}
.itniaryboxchild1Details {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: black;
  font-weight: bold;
}
.itniaryboxchild1Details span {
  color: #ff4e00;
}
.itniaryboxchild1Detailsicons svg {
  width: 1.6rem;
  height: 2rem;
}
.itniaryboxchild1Detailsicons p {
  font-size: 0.8rem;
}
.itniaryboxchild1Detailsicons {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* iTINEASY sECTION cSS sTARTS hERE------------------------------- */

.content-section {
  height: 3467px;
}
.Itinearytabs1 {
  width: 100%;
  color: #6a6f71;
  font-size: 1.3rem;
  gap: 3rem;
  justify-content: center;
  background: #fff;
  box-shadow: 2px solid gray !important;
  display: flex;
  position: sticky;
  top: 3.3rem;
  z-index: 99;
}
.Itinearytabs {
  margin: 0.2rem 0;
  width: 54rem;
  height: 4rem;
  padding: 1rem 4rem;
  color: #6a6f71;
  font-size: 1.3rem;
  gap: 3rem;
  border-radius: 0.9375rem;
  border: 1px solid;
  background: #fff;
  box-shadow: 2px solid gray !important;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 9;
}
.highlightsDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}
.highlightsDivBox {
  width: 38.4375rem;
  overflow: hidden;
  height: 19.4375rem;
  flex-shrink: 0;
  border-radius: 0.9375rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.highlightsDivBoximg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.highlightsDivBoximg:hover {
  transition: all 0.5s;
  scale: 1.06;
}

.cancellationPolicySubmenus {
  color: #4c4c4c;
  font-family: Poppins;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.itinearyFeaturediv {
  width: 31.8125rem;
  /* height: 20.125rem; */
  /* height: fit-content; */
  flex-shrink: 0;
  border-radius: 0.625rem;
}
[data-viewInclusion="true"] {
  height: 20.125rem;
  overflow: hidden;
}
.incButton {
  z-index: 102;
  width: 100%;
  overflow: hidden;
  position: absolute;
  background: rgba(159, 212, 149, 0.7);
  bottom: 10px;
  border: 2px solid;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.itinearyFeaturediviNCLUSION {
  padding: 2rem;
  position: relative;
  border-radius: 0.625rem;
  background: rgba(159, 212, 149, 0.7);
}
.itinearyFeaturedivEXCLUSION {
  padding: 2rem;
  border-radius: 0.625rem;
  background: #fecaca;
}
/* CusomizeItinearyBanner styles starts here */

.CusomizeItinearyBanner {
  width: 100%;
  height: 26.8125rem;
  background-size: 20rem;
  transform: scaleX(-1);
  border-radius: 1.8125rem;
  border: 1px solid #fe6f61;
  box-shadow: 12px 12px 8px 0px rgba(0, 0, 0, 0.16);
}
.heroContainerimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.heroContainerimg_desktop{
  background-image: var(--background-image);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 53.4375rem;
  height: 30.6875rem;
}
.itineary_mobile_hero .slick-arrow.slick-prev, .itineary_mobile_hero .slick-arrow.slick-next{
  display: none !important;
}

/* Initially hidden and positioned off-screen */
/* Initially hidden and positioned off-screen */
.p-hidden {
  opacity: 0;
  transform: translateY(-50px);
  transition: all 0.5s ease;
}

.p-visible {
  opacity: 1;
  transform: translateY(0);
}

.p-hidden {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.1s ease-in-out;
}

.p-visible {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

.heroConatinerFont {
  color: #fff;
  font-family: var(--font-family-niccone);
  font-size: 5.125rem;
  font-style: normal;
  padding: 7rem 2rem;
  font-weight: 400;
  line-height: normal;
}

/* Mobile Styles Starts Here */
.mobileHeroBanner {
  width: 100%;
  overflow: hidden;
  height: 71.6875rem;
  flex-shrink: 0;
  border-radius: 4.625rem;
}
.mobileHeroBanner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.MbHeroBannerheading {
  color: #000;
  font-family: var(--font-family-poppins);
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.MbHeroBannerh1packName {
  color: #000;
  font-family: var(--font-family-poppins);
  font-size: 3.8rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.MbHeroBannerh1span {
  color: #ff4e00;
  font-family: var(--font-family-poppins);
  font-size: 4rem;
  font-style: normal;
  font-weight: bold;
  padding-left: 2rem;
  line-height: normal;
}
.mobileHeroBanner_img{
  background-image: var(--background-image);
  background-size: cover;
  height: 100%;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
}
.DaywiseMobileCardimgwrapper_img{
  background-image: var(--background-image);
  background-size: cover;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}
@media screen and (max-width: 468px) {
  .tabsSticky {
    width: 30rem;
    height: 7.5rem;
    color: white;
    font-family: var(--font-family-poppins);
    scroll-snap-align: start;
    scrollbar-width: none;
    flex-shrink: 0;
    border-radius: 5rem;
    background: #fe6f61;
  }

  .mobiletabwarpper {
    overflow: auto;
    width: 97%;
    margin: 0 auto;
    display: flex;
    padding: 3rem;
    font-size: 2.5rem;
    gap: 2rem;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
  }
  .PackageMobOverview {
    width: 95%;
    margin: 0 auto;
    padding: 3.4rem;
    flex-shrink: 0;
    border-radius: 2.5rem;
    border: 1px solid #b2b2b2;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .PackageMobCentalize {
    width: 97%;
    margin: 0 auto;
  }
  .PackageMobOverview h1 {
    color: #333;
    font-family: Helvetica;
    font-size: 5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .PackageMobOverview ul li {
    color: #5c5c5c;
    font-family: Inter;
    margin-top: 4rem;
    font-weight: bold;
    font-size: 3rem;
    font-style: normal;
    line-height: normal;
  }
  .PackageMobOverview ul li span {
    font-weight: 400;
    margin-left: 1rem;
  }
  .DaywiseMobileCard {
    width: 87rem;
    display: flex;
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 80%;
    margin-right: 10px;
    gap: 1.6rem;
    flex-direction: column;
    height: 100%;
  }
  .DaywiseMobileCardimgwrapper {
    overflow: hidden;
    height: 55rem;
    border-radius: 2.5rem 2.5rem 0rem 0rem;
  }
  .DaywiseMobileCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .herosidebarPrice2 {
    display: flex;
    margin: 5rem 0;
    padding: 0.5rem;
    border-radius: 2.625rem;
    border: 1px solid #bebebe;
    background: linear-gradient(
      90deg,
      rgba(61, 134, 177, 0.8) 0%,
      rgba(188, 221, 239, 0.8) 100%
    );
    width: 100%;
    height: -moz-fit-content;
    height: 22rem;
  }

  .herosidebarPricetext2 h1 {
    color: #fff;
    margin-top: 3rem;
    font-family: Helvetica;
    font-size: 4.5rem;
    height: 6.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .herosidebarPricetext2 p {
    font-size: 2.8rem;
    font-weight: 600;
    color: #585454;
  }
  .herosidebarPricetext2 svg {
    width: 18rem;
    height: 18rem;
  }
  .DayWiseMobbtmLayer {
    width: 100%;
    height: 4rem;
    max-height: 8rem;
    color: black;
    font-weight: bold;
    border-radius: 0rem 0rem 2.5rem 2.5rem;
    background: #f4f4f4;
  }
  .DaywiseMobileCardDescription {
    /* height: 27rem; */
    overflow-y: auto;
    padding: 2rem 1rem;
    scrollbar-width: none;
    background-color: #f0f0f0;
    border-radius: 5px;
    font-size: 3rem;
  }
  .datywiseMobscroll {
    width: 97%;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    margin: 7rem auto;
  }

  .ItinerayPlanningWrapper {
    width: 100%;
    overflow: hidden;
    height: 32.75rem;
    font-family: var(--font-family-poppins);
    padding: 4rem;
    flex-shrink: 0;
    border-radius: 6.25rem;
    background: rgba(129, 207, 252, 0.5);
    margin: 4rem 0;
  }
  .contactdiv {
    border-radius: 3.25rem;
    padding: 2rem 4rem;
    background: rgba(129, 207, 252, 0.5);
    gap: 4rem;
    font-size: 0rem;
  }
  .itnearybtn {
    width: 33rem;
    font-size: 3rem;
    border-radius: 5rem;
    color: white;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .ItinerayPlanningWrapper h1 {
    color: #3d3d41;
    position: relative;
    font-family: Helvetica;
    font-size: 5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ItinerayPlanningWrapper p {
    position: relative;
    color: #625d5d;
    font-family: Helvetica;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .inclusionExclusionDetail {
    display: flex;
    flex-direction: column;
    margin: 4rem 0;
    gap: 2rem;
  }
  /* .itinearyFeaturediv {
    width: 100%;
    height: 40.125rem;
  } */
  .itinearyFeaturediv {
    padding: 3rem 2rem;
    width: 100%;
    height: fit-content;
  }
  .book-now-exclusion p {
    display: flex;
    /* width: 108px; */
    font-size: 2.7rem;
  }
  .cancellationHeading {
    color: #000;
    font-family: var(--font-family-poppins);
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .cancellationPolicySubmenus {
    color: #4c4c4c;
    font-family: Poppins;
    font-size: 3.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .itninearyFaqhead {
    font-size: 4rem;
    padding: 4rem 0;
  }
  .itninearyFaqhr {
    display: none;
  }
  .cancellationPolicySubmenus {
    color: #4c4c4c;
    font-family: Poppins;
    font-size: 2.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .itinaiaryabsoluteChild {
    position: absolute;
    margin: 0 auto;
    border-radius: 3.5625rem;
    border: 1px solid #000;
    color: #000;
    font-family: Helvetica;
    font-size: 4rem;
    font-style: normal;
    padding: 1.5rem 5rem;
    font-weight: 400;
    line-height: normal;
    background: #e9e9e9;
    transform: translate(26rem, 20.8rem);
  }
  .itniaryboxchild1,
  .itniaryboxchild2 {
    height: 24rem;
  }
  .itniaryboxchild1Detailsicons svg {
    width: 6.6rem;
    height: 7rem;
  }
  .itniaryboxchild1Details {
    display: flex;
    align-items: center;
    gap: 1.3rem;
    padding: 0 3rem;
    margin: 0.7rem 0;
    font-size: 3.5rem;
    color: black;
    font-weight: bold;
  }
  .itniaryboxchild2icondetail {
    justify-content: flex-start;
    gap: 4rem;
    margin-left: 4rem;
  }
  .itniaryboxchild1Detailsicons p {
    font-size: 2.8rem;
    color: gray;
  }
  /* .herosidebarPrice {
  height: 18rem;
  border-radius: 2rem;
} */

  .herosidebarPrice {
    position: fixed;
    height: 18rem;
    z-index: 1;
    background: linear-gradient(
      90deg,
      rgb(20 168 255 / 80%) 0%,
      rgb(0 147 227 / 80%) 100%
    );

    border-radius: 2rem;
    bottom: 0;
    left: 0;
  }
  .sideparap {
    position: relative;
    color: #dbd7d7;
    font-weight: 100;
    top: 0.4rem;
    font-size: 2.6rem;
  }

  .herosidebarPriceBtn {
    color: #fff;
    font-family: Helvetica;
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 39rem;
    height: 12rem;
    margin-left: 4rem;
    flex-shrink: 0;
    border-radius: 2.625rem;
    background: linear-gradient(90deg, #fe6f61 0%, #fe6f61 100%);
  }
  .sidepara {
    display: none;
  }
  .herosidebarfinalspan {
    position: relative;
    color: #f2f2f2;
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    top: 3rem;
    left: 1rem;
  }
  .sideparas {
    font-size: 6rem;
    font-weight: bold;
  }
}
.inclusionExclusionDetail {
  display: flex;
  margin: 4rem 0;
  gap: 2rem;
}
