.filtersWrapper {
  border-radius: 0;
  display: flex;
  position: sticky;
  gap: 2.5rem 6.25rem;
  flex-wrap: wrap;
  top: 4rem;
}
.flwrapper {
  display: flex;
  padding: 2rem;
  background-color: #eaeaea;
}
.flwrapperc1 {
  flex: 3;
}
.starContainer {
  display: flex;
}
.flwrapperc2 {
  flex: 6;
}
.filterContainer {
  border-radius: 0.625rem;

  background-color: #fff;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.filterTitle {
  color: #0a0a0a;
  font: 600 1.4rem Poppins, sans-serif;
  margin: 1rem 0;
}

.checkboxContainer {
  display: flex;
  gap: 0.8125rem;
  margin-top: 1.0625rem;
  color: #0a0a0a;
  font: 400 1.2rem Poppins, sans-serif;
}

.checkbox {
  display: flex;
  width: 2rem;
  height: 2rem;
  border: 0.0625rem solid #fe6f61;
}

.starRatingContainer {
  display: flex;
  margin-top: 1.0625rem;
  gap: 1.0625rem;
  align-items: center;
}

.starIcon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 1.5rem;
}

.ratingImage {
  aspect-ratio: 3.66;
  object-fit: contain;
  width: 8.6875rem;
  max-width: 100%;
}

.bottomImage {
  aspect-ratio: 1;
  object-fit: contain;
  width: 1.8125rem;
  align-self: start;
  margin-top: 4.5rem;
}

@media (max-width: 61.9375rem) {
  .filterContainer {
    max-width: 100%;
    padding: 0 1.25rem 6.25rem;
  }

  .bottomImage {
    margin-top: 2.5rem;
  }
}
