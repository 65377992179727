.Blogs-Para table,
.Blogs-Para th,
.Blogs-Para td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 8px;
  width: -webkit-fill-available !important;
}

.Blogs_parent {
  /* width: 79rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Content_Blogs {
  width: 72rem;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 4rem;
  font-size: 1rem;
  font-family: sans-serif;
}
.BlogheroImg {
  position: relative;
  width: 72rem;
  object-fit: cover;
  height: 31rem;
}
.BlogheroImg img {
  width: 100%;
  height: 100%;
}
.Blogs-Para {
  /* color: #02507f; */
  font-family: Poppins;
  font-size: 1rem;
  margin: 1rem 0rem;
  font-style: normal;
  /* font-weight: 800; */
  line-height: normal;
}

/* Blog Card css Begins Here */
.blogCardWrapper {
  height: 24.0625rem;
  width: 21.0625rem;
}

.blogCardWrapper .bloggroup {
  background-color: #ffffff;
  border-radius: 1.2rem;
  box-shadow: 0px 3px 3px #00000040;
  height: 24.0625rem;
  width: 21.0625rem;
}

.blogCardWrapper .div {
  position: relative;
}

.blogCardWrapper .source-times-travel {
  color: #000000;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 0.9rem;
  font-weight: 400;
  left: 1.125rem;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 15.5rem;
}

.blogCardWrapper .sabarimala {
  color: #000000;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 1rem;
  font-weight: 600;
  left: 1.125rem;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 17.25rem;
}

.blogCardWrapper .overlap {
  height: 6rem;
  left: 1.125rem;
  position: absolute;
  top: 19rem;
  width: 21.5625rem;
}

.blogCardWrapper .international {
  color: #000000;
  width: 90%;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 0.9rem;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.blogCardWrapper .overlap-group-wrapper {
  height: 1.375rem;
  left: 0.0625rem;
  position: absolute;
  top: 3rem;
  width: 5.5625rem;
}
.darkContent {
  padding: 1rem 2rem;
  color: white;
  position: absolute;
  background-color: rgba(11, 11, 11, 0.541);
  width: 100%;
  height: 10rem;
  bottom: 0;
}

.HeroBannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blogCardWrapper .overlap-group {
  border: 0.0625rem solid;
  border-color: #000000;
  border-radius: 2.375rem;
  height: 1.375rem;
  position: relative;
  width: 5.4375rem;
}

.blogCardWrapper .text-wrapper {
  color: #000000;
  font-family: var(--font-family-poppins);
  font-size: 0.75rem;
  font-weight: 500;
  left: 1.375rem;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
}

.blogCardWrapper .overlap-wrapper {
  height: 1.375rem;
  left: 6.875rem;
  position: absolute;
  top: 3rem;
  width: 5.5625rem;
}
.btn-grpblog {
  padding: 0.4rem 0rem;
}
.darkContent .head {
  font-size: 1.5rem;
}
.source_para {
  font-size: 0.8rem;
}
.minifooter {
  font-size: 0.9rem;
  font-weight: 500;
}
.btn-grpblog button {
  border: 1px solid white;
  padding: 0.2rem;
  width: 6rem;
  font-size: 0.8rem;
  border-radius: 25px;
}
.blogCardWrapper .text-wrapper-2 {
  color: #000000;
  font-family: var(--font-family-poppins);
  font-size: 0.75rem;
  font-weight: 500;
  left: 1.6875rem;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
}

.blogCardWrapper .text-wrapper-3 {
  color: #000000;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 0.9rem;
  font-weight: 400;
  left: 12.5rem;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 15.5rem;
}
.blogsections h1 {
  text-align: center;
  font-size: 2rem;
  margin: 2rem 0;
}
.blgfeatures h1 {
  text-align: center;
  font-size: 1.5rem;
  margin: 2rem 0;
}
.Pagination p:first-child {
  background-color: black;
  color: white;
  border-radius: 5px;
  padding: 0.3rem 0.8rem;
}
.Pagination p {
  /* background-color: black; */
  color: black;
  border-radius: 5px;
  padding: 0.3rem 0.8rem;
}
.blogCardWrapper .ellipse {
  background-color: #02507f;
  border-radius: 0.1875rem;
  height: 0.375rem;
  left: 11.75rem;
  position: absolute;
  top: 15.8rem;
  width: 0.375rem;
}
.blgfeatures {
  width: 78rem;
  margin: 0 auto;
}

.blogCardWrapper .mask-group {
  height: 14.375rem;
  left: 0;
  position: absolute;
  object-fit: cover;
  top: 0;
  overflow: hidden;
  border-radius: 2rem;
  width: 21.0625rem;
}

/* neeed to bre check */

.Blogs-moreSection {
  display: relative;
  margin: 0 auto;
  width: 79rem;
}
.blogSearch {
  border-radius: 0.5625rem;
  background: #fff;
  box-shadow: 12px 12px 8px 0px rgba(0, 0, 0, 0.16);
}
.blogsearchWrapper {
  width: 28rem;
  margin: 0 auto;
}
.blogSearch input {
  width: 100%;
  padding: 0 2rem;
  outline: none;
  border: 0;
}
.blogSearch .btn {
  padding: 0.6rem 1.5rem;
  color: white;
  border-radius: 0.5625rem;
  background: #fe6f61;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
}

/* snapscroll css */
.feature-type-main-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}
.ctrlcentralize {
  width: 71rem;
  margin: 7% auto;
}
@media screen and (max-width: 489px) {
  .Blogs-Para {
    color: black;
    font-size: 3.5rem;
    margin: 0 4.75rem;
  }
  .blogsearchWrapper {
    display: block;
  }
  .darkContent {
    height: fit-content;
  }
  .blogsections {
    margin: 6rem 0rem;
  }
  .leftCtrl,
  .rightCtrl {
    gap: 1rem;
    font-weight: bold;
    font-size: 2rem;
  }
  .darkContent .head {
    font-size: 2.25rem;
  }
  .blogsearchWrapper {
    width: 85%;
    margin: 0% 7% 8% auto;
  }
  .blogSearch {
    box-shadow: 1px 0px 8px 0px rgba(0, 0, 0, 0.16);
    border-radius: 3.5625rem;
    padding: 1rem;
  }
  .blogSearch .btn {
    border-radius: 2.5625rem;
    padding: 1.6rem 4.5rem;
    color: white;
    font-size: 2.5rem;
  }

  /* searchbar csss ends herr */
  .blogsections h1 {
    text-align: center;
    font-size: 3rem;
    margin: 2rem 0;
    font-weight: bolder;
  }
  .ctrlcentralize {
    width: 75rem;
    margin: 7% auto;
  }
  .featureOuter {
    width: 95%;
    margin: 0 auto;
  }
  .Pagination {
    display: none;
  }
  .liststyle li {
    list-style: decimal-leading-zero !important;
  }
  .feature-type-main-div {
    margin-top: 0;
    margin: 0 auto;
    flex-wrap: nowrap;
    padding: 1rem 3.8rem;
    position: relative;
    justify-content: normal;
    width: 95%;
    height: 100%;
    display: grid;
    grid-auto-flow: column;
    scroll-behavior: auto;
    overflow: visible;
    overflow-y: hidden;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
  }

  .feature-type-main-div .Link {
    scroll-snap-align: start;
    width: 190px;
  }
  /* Card Css */
  .blogCardWrapper .bloggroup {
    width: 175px;
    height: 188.999px;
  }

  .blogSearch input {
    width: 100%;
    padding: 0 3rem;
    font-size: 3rem;
    outline: none;
    border: 0;
  }
  .blogCardWrapper .mask-group {
    width: 100%;
    border-radius: 15px;
    height: 115px;
  }
  .blogCardWrapper .div {
    height: 100%;
    position: relative;
    width: 100%;
  }
  .blogCardWrapper {
    scroll-snap-align: start;
    height: 100%;
    width: 100%;
  }

  .blogCardWrapper .source-times-travel,
  .blogCardWrapper .text-wrapper-3 {
    font-size: 1.6rem;
    top: 29.9rem;
    left: 2.125rem;
  }
  .blogCardWrapper .sabarimala {
    left: 2.125rem;
    font-size: 2.25rem;
    top: 33rem;
  }
  .blogCardWrapper .text-wrapper-3 {
    left: 26.5rem;
  }
  .blogCardWrapper .ellipse {
    background-color: #02507f;
    border-radius: 1.1875rem;
    height: 1rem;
    left: 24.75rem;
    position: absolute;
    top: 30.3rem;
    width: 1rem;
  }
  .blogCardWrapper .overlap {
    height: 100%;
    left: 2.125rem;
    position: absolute;
    top: 36rem;
    width: 100%;
  }
  .blogCardWrapper .international {
    font-size: 1.9rem;
  }
  .blogCardWrapper .overlap-wrapper,
  .blogCardWrapper .overlap-group-wrapper {
    top: 6rem;
  }
  .blogCardWrapper .overlap-wrapper {
    left: 13.875rem;
  }
  .blogCardWrapper .text-wrapper,
  .blogCardWrapper .text-wrapper-2 {
    font-size: 1.75rem;
    text-align: center;
    position: static;
  }
  .blogCardWrapper .overlap-group {
    border: 0.0625rem solid;
    border-color: #000000;
    border-radius: 1.375rem;
    height: 3.375rem;
    position: relative;
    width: 11.4375rem;
  }

  /* text heading css */

  .blgfeatures h1 {
    text-align: left;
    font-weight: bolder;
    font-family: var(--font-family-sofia);
    font-size: 3rem;
    margin: 5rem 0rem 2rem 4rem;
  }
  .blgfeatures {
    width: 100%;
    margin: 0 auto;
  }

  /* Hero Banner Css */
  .btn-grpblog button {
    border: 1px solid white;
    padding: 0.2rem 3rem;
    width: 12rem;
    margin-top: 1rem;
    font-size: 1.5rem;
    border-radius: 5px;
  }

  .minifooter {
    font-size: 1.5rem;
  }
  .source_para {
    font-size: 1.75rem;
  }
  .BlogheroImg {
    width: 79.00725rem;
    height: 76.83775rem;
    min-width: 89.00725rem;
    min-height: 46.83775rem;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid;
    object-fit: cover;
  }
  .Content_Blogs {
    width: 100%;
    color: black;
    margin-top: 5.34rem;
  }
}
