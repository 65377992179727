.Portrait-card-parent {
    position: relative;
    width: 16.75rem;
    /* height: 32rem; */
    border-radius: 1.6875rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Portrait-card-parent::before {
    content: "";
    background: #000000;
    opacity: 0.28;
    height: 19.775rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    border-radius: 1.4rem;
}

.Portrait-card-Img {
    width: 100%;
    height: 19.775rem;
    flex-shrink: 0;
    border-radius: 1.1875rem;
    /* opacity: 0.37; */
    background: #000;
}

.Portrait-card-details {
    position: absolute;
    width: 15.5625rem;
    height: 18rem;
    color: #FFF;
    margin-top: 0.5rem;
}

.Portrait-card-cart-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.6347rem;
    height: 1.7067rem;
    border-radius: 4px;
    border: 2px solid #FFF;
}

.Portrait-card-cart-days-div {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 3.6347rem; */
    /* height: 1.7067rem; */
    border-radius: 4px;
    border: 2px solid #ffffff;
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    flex-direction: column;
    padding: 0.3rem;
    row-gap: 0.2rem;
}

.Portrait-card-cart {
    width: 1.5539rem;
    height: 1.1614rem;
    flex-shrink: 0;
    fill: #FFF;
}

.Portrait-card-details-row1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.Portrait_card_bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.Portrait-card-details-row2 {
    margin-top: 1.125rem;
    color: #FFF;
    /* font-family: Poppins; */
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Portrait-card-details-row3 {
    color: #FFF;
    font-family: var(--font-family-merriweather);
    font-size: 1.2rem;
    font-style: normal;
    /* font-weight: 600; */
    line-height: normal;
    margin: 0 0 0.4rem 0.5rem;
}

.icon-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    /* font-family: Poppins; */
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.icons {
    width: 1.3719rem;
    height: 1.1521rem;
    flex-shrink: 0;
}

.Portrait-card-details-row4 {
    margin-top: 1rem;
    width: 48%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Portrait-card-details-row5 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 0.4rem 0.5rem;
    font-family: var(--font-family-merriweather);
}

.Portrait-card-details-price {
    color: #FFF;
    /* font-family: Poppins; */
    font-size: 1rem;
    font-style: normal;
    /* font-weight: 600; */
    line-height: 1rem;
}

.Portrait-card-details-per-person {
    color: #FFF;
    /* font-family: Poppins; */
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    margin-left: 0.4rem;
    text-transform: lowercase;
}

.Portrait-card-details-button {
    /* color: #FFF; */
    width: 4.5605rem;
    height: 1.7068rem;
    flex-shrink: 0;
    background: #FE6F61;
    border-radius: 0.4375rem;
    border: none;
    /* filter: drop-shadow(0rem 0.1875rem 0.1875rem rgba(0, 0, 0, 0.16)); */
    color: #FFF;
    /* font-family: Poppins; */
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.Portrait_Card_Inclusions {
    height: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Portrait_Card_Inclusions::before {
    content: "";
    background-color: #000000;
    position: absolute;
    border-radius: 0 0 1.3rem 1.3rem;
    width: 100%;
    height: 100%;
    bottom: 0;
    opacity: 0.3;
}
.Portrait_Card_Inclusions_inner {
    display: flex;
    flex-wrap: wrap;
    width: 85%;
    font-size: 0.5rem;
    column-gap: 2rem;
    row-gap: 0.5rem;
    z-index: 0;
    position: inherit;
    padding: 0.4375rem 0 0.75rem 0;
}

.Portrait_Card_text_img {
    display: flex;
    color: #fff;
    align-items: center;
}
.Portrait_Card_text_img span{
    margin-left: 0.2rem;
}
img.portrait_card_green_tick {
    width: 0.65rem;
}

@media screen and (max-width:1024px) {
    .Portrait-card-parent {
        width: 17.75rem;
    }
    
}