/* Timeline container */
.timeline-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 20px 0;
}

/* Timeline item (each day) */
.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

/* Day circle */
.bigCircle {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 50%;
  background-color: lightgray;
  border: 2px solid lightgray;
  margin-bottom: 5px;
}
.circle {
  width: 10px;
  transform: translate(0.2rem, 0.2rem);
  position: absolute;
  height: 10px;
  border-radius: 50%;
  background-color: lightgray;
  border: 2px solid lightgray;
  margin-bottom: 5px;
}

/* Active circle (blue) */
.circle.active {
  background-color: #75b1d2;
  border-color: #75b1d2;
}

/* Day label */
.day-label {
  color: black;
  font-size: 14px;
}

.day-label.active {
  color: #75b1d2;
}

/* Line connecting the circles */
.timeline-container::before {
  content: "";
  position: absolute;
  top: 10px; /* Adjust to fit the position of the circles */
  left: 0;
  right: 0;
  height: 2px;
  background-color: lightgray;
  z-index: -1;
}

.timeline-item:first-child::before {
  content: none; /* Remove line on the first element */
}

@keyframes pulse {
  0% {
    color: transparent;
    background: rgba(229, 231, 235, 1);
    border: none;
    filter: brightness(0);
    background-image: none;
    opacity: 0.06;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    color: transparent;
    background: rgba(229, 231, 235, 1);
    border: none;
    filter: brightness(0);
    background-image: none;
    opacity: 0.06;
  }
}

[data-skeleton="true"] {
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) 2;
  animation-fill-mode: none;
}
