.price-comparison {
    border-radius: 15px;
    display: flex;
    max-width: 390px;
    flex-direction: column;
    color: rgba(0, 0, 0, 1);
    text-align: right;
    font: 400 14px Inter, sans-serif;
    margin-bottom: 20px;
}

.comparison-container {
    border-radius: 15px;
    background-color: rgba(239, 239, 239, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    padding: 1px 29px;
    border: 1px solid rgba(227, 227, 227, 1);
}

.price-option {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
}

.pricecomparison-price {
    font-weight: 600;
    align-self: stretch;
}

.pricecomparison-price.active-price {
    color: rgba(254, 111, 97, 1);
}

.vertical-divider {
    align-self: start;
    width: 1px;
    height: 85px;
    border: 1px solid rgba(0, 0, 0, 1);
}