.testimonialCard {
  border-radius: 0;
  display: flex;
  max-width: 25.5rem;
  flex-direction: column;
}
.mainwrappertestimonail {
  display: flex;
  gap: 1rem;
  justify-content: center;
  background: #d5abab;
  padding: 2rem;
  margin: 2rem 0rem;
}
.cardWrapper {
  border-radius: 1.25rem;
  background-color: rgba(238, 238, 238, 1);
  width: 100%;
  padding: 0.625rem 1rem 0.625rem;
}

.contentContainer {
  display: flex;
}

.testimonialInfo {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 44%;
  margin-left: 0;
}

.testimonialContent {
  display: flex;
  margin-top: 0.875rem;
  flex-direction: column;
  color: rgba(0, 0, 0, 1);
  font: 1.25rem Poppins, sans-serif;
}

.authorSection {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  padding: 0 0.25rem;
}

.authorName {
  font-size: 1rem;
  align-self: start;
}

.authorSignature {
  aspect-ratio: 5.41;
  object-fit: contain;
  object-position: center;
  width: 4.8125rem;
}

.testimonialText {
  font-size: 0.8125rem;
  font-weight: 400;
  margin-top: 0.25rem;
}

.location {
  font-weight: 500;
  font-size: 1rem;
  align-self: center;
  margin-top: 1rem;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 12rem;
  height: 10rem;
  margin-left: 1.25rem;
}

.testimonialImage {
  aspect-ratio: 1.34;
  object-fit: cover;
  object-position: center;
  width: 100%;
  border-radius: 3.375rem;
  flex-grow: 1;
}

@media (max-width: 468px) {
  .cardWrapper {
    width: 84rem;
    padding-left: 1.25rem;
    padding-bottom: 4rem;
    border-radius: 4rem;
  }

  .contentContainer {
    align-items: stretch;
    gap: 0;
  }
  .mainwrappertestimonail {
    display: flex;
    scrollbar-width: none;
    justify-content: unset;
    gap: 1rem;
    overflow: scroll;
    padding: 2rem;
    margin: 2rem 0rem;
  }
  .testimonialCard {
    max-width: 84rem;
  }
  .testimonialInfo {
    width: 100%;
  }

  .testimonialContent {
    margin-top: 2.5rem;
  }
  .authorName {
    font-size: 4rem;
  }
  .testimonialText {
    margin-right: 0.625rem;
    font-size: 2.8125rem;
    font-weight: 400;
    margin-top: 0.25rem;
  }
  .location {
    font-weight: 500;
    font-size: 4rem;
    align-self: center;
    margin-top: 1rem;
  }
  .authorSignature {
    aspect-ratio: 5.41;
    object-fit: contain;
    object-position: center;
    width: 20.8125rem;
  }
  .imageContainer {
    width: 100%;
  }

  .testimonialImage {
    margin-top: 2.5rem;
  }
}
