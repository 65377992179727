.roomCard {
  border-radius: 0.625rem;
  background-color: #e4dddd;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  transition: 0.2s all linear;
  align-items: flex-start;
  padding: 1.0625rem 1.2625rem 1.0625rem 0.75rem;
}
.roomCard:hover {
  transform: scale(1.06);
}
.roomTitle {
  color: #3e3e3e;
  font-size: 1.2rem;
}

.priceText {
  color: #636363;
  font-size: 1.25rem;
  margin-top: 0.25rem;
}

.selectedCard {
  background-color: #303030;
}

.selectedTitle {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-size: 1.2rem;
}

.selectedPrice {
  color: #c5c5c5;
}

.statusIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 1.0625rem;
  background-color: #d9d9d9;
  border-radius: 50%;
  height: 1.0625rem;
}

@media screen and (max-width: 469px) {
  .roomTitle {
    color: #3e3e3e;
    font-size: 2.5rem;
  }
  .priceText {
    color: #636363;
    font-size: 3.25rem;
    margin-top: 0.25rem;
  }
  .selectedTitle {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    font-size: 2.2rem;
  }
  .selectedPrice {
    color: #c5c5c5;
    font-size: 3rem;
  }
}
