.HotelSearchFilterwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 90%;
  height: 7.17781rem;
  border-radius: 12px;
  margin: 15rem auto;
  flex-shrink: 0;
  background-color: #fe6f61;
}
.input-error {
  width: 20rem;
  position: relative;
  display: flex
;
  align-items: center;
  gap: 1rem;
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  border: 2px solid #ff0000;
}

.filtercalender {
  display: flex;
  gap: 0.4rem;
  position: relative;
  top: -0.7rem;
}

.filterseachbar {
  width: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  border: none;
}
.filterseachbar {
  border: none;
}
.checkin {
  width: 8rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  top: -0.7rem;
  border-radius: 12px;
  padding: 1rem;
  color: black;
  background-color: white;
}
.GuestRooms {
  width: 19rem;
  display: flex;
  gap: 0.4rem;
  position: relative;
  top: -0.7rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 12px;
  padding: 1rem;
  color: black;
  background-color: white;
}
.searchfilterres {
  border: #fe6f61;
  color: #fe6f61;
  width: 6rem;
  display: flex;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  gap: 1rem;
  padding: 1rem;
  background-color: white;
}
.grdiv {
  position: absolute;
  /* transform: translateX(-50%); */
  padding: 20px;
  border: 1px solid #ff6b6b;
  color: black;
  border-radius: 8px;
  width: 20rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 1000;
}

.paxsubmitwrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.paxsubmitwrapper button {
  width: 6rem !important;
  margin-top: 12px;
  padding: 10px;
  background-color: #ff5a5f;
  color: #fff;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}
.decbtn,
.incbtn {
  width: 2rem;
  height: 2rem;
  margin: 0.4rem;
  text-align: center;
  border-radius: 0.3125rem;
  background: #d9d9d9;
}
.incdecwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px;
}
