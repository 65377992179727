.roomCard {
  border-radius: 0;
  display: flex;
  margin: 2rem 0;
  width: 23.5rem;
  flex-direction: column;
  font-family: Inter, sans-serif;
}

.cardContainer {
  border-radius: 0.625rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0.375rem 1.625rem 1.6875rem;
  border: 0.0625rem solid rgba(137, 137, 137, 1);
}

.roomTitle {
  color: rgba(24, 24, 24, 1);
  margin: 1rem 0;
  font: 600 1.2rem Poppins, sans-serif;
}

.featureItem {
  align-self: start;
  display: flex;
  margin-top: 0.25rem;
  gap: 0.5rem;
  font-size: 1.2rem;
  color: rgba(99, 99, 99, 1);
  font-weight: 500;
}

.featureDot {
  background-color: rgba(77, 77, 77, 1);
  border-radius: 50%;
  display: flex;
  width: 1.0625rem;
  height: 1.0625rem;
  margin: auto 0;
}

.priceContainer {
  align-self: start;
  display: flex;
  margin-top: 0.25rem;
  gap: 0.5rem;
}

.currencyIcon {
  aspect-ratio: 0.76;
  object-fit: contain;
  object-position: center;
  width: 1.375rem;
  align-self: start;
}

.mainPrice {
  color: rgba(49, 49, 49, 1);
  font-size: 1.7rem;
  font-weight: 500;
}

.priceLabel {
  color: rgba(99, 99, 99, 1);
  font-size: 1.375rem;
  font-weight: 600;
  margin: auto 0;
}

.discountContainer {
  align-self: center;
  display: flex;
  margin-top: 0.25rem;
  width: 17.9375rem;
  max-width: 100%;
  gap: 1.375rem;
}

.discountPrice {
  display: flex;
  gap: 0.1875rem;
  font-size: 2.5rem;
  color: rgba(49, 49, 49, 1);
  font-weight: 600;
  white-space: nowrap;
  flex: 1;
}

.discountIcon {
  aspect-ratio: 0.79;
  object-fit: contain;
  object-position: center;
  width: 1.6875rem;
  margin: auto 0;
}

.taxInfo {
  color: rgba(99, 99, 99, 1);
  font-size: 1.25rem;
  font-weight: 500;
  flex-grow: 1;
  width: 6.6875rem;
  margin: auto 1rem;
}

.bookButton {
  border-radius: 0.625rem;
  background-color: rgba(254, 111, 97, 1);
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  align-self: center;
  margin-top: 1.25rem;
  width: 15.375rem;
  max-width: 100%;
  font-size: 1rem;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  padding: 0.8125rem 1.2625rem;
  border: none;
  cursor: pointer;
}
