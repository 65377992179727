.query__parent_new {
  overflow: auto;
  height: 24rem;
}
.query__parent_new::-webkit-scrollbar {
  width: 2px;
}
.query__parent_new::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.query__parent_new::-webkit-scrollbar-thumb {
  background: #888;
}
.query__parent_new::-webkit-scrollbar-thumb:hover {
  background: #302f2f;
}
.query_card {
  display: flex;
  border: 1px solid #f5c7c0;
  border-radius: 8px;
  /* overflow: hidden; */
  max-width: 600px;
  margin: 20px;
  background-color: #EBEBEB;
  padding: 0.5rem;
  margin: 1.25rem auto;
  height: 11.6875rem;
}

.query_cardImage {
  width: 40%;
  object-fit: cover;
  height: 100%;
}

.query_cardContent {
  padding: 1rem;
  flex: 1;
}

.query_cardTitle {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  /* margin-bottom: 0.5rem; */
}
.query_cardTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 200px;
  /* background-color: lightblue; */
}
.query_cardInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: #888;
  /* margin-bottom: 0.5rem; */
}

.query_rating{
  display: flex;
  align-items: center;
  column-gap: 4px;
}
.query_activities {
  color: #555;
}

.query_card_cost_type {
  font-size: 1rem;
  color: #555;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.query_card_price {
  color: #000;
  font-size: 1.25rem;
  font-weight: 800;
}

.query_cardButtons {
  display: flex;
  gap: 0.5rem;
}

.query_downloadBtn,
.query_downloadVoucherBtn {
  background-color: #ff6d6d;
  color: white;
  border: 1px solid #ff6d6d;
  padding: 0.25rem 0.75rem;
  border-radius: 11px;
  cursor: pointer;
  font-size: 0.9rem;
}

.query_downloadBtn {
  background-color: #fff;
  color: #ff6d6d;
}
.query_downloadVoucherBtn_parent{
  position: relative;
}
.query_downloadVoucherBtn {
  background-color: #ff6d6d;
  position: relative;
}
.download_voucher_animation{
  background-color: #fff7f7;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 18.75rem;
  top: -1px;
  left: -1px;
  position: absolute;
  display: none;
  z-index: 100;
}
.show_voucher_animation{
  display: block;
  transform: scale(1);
  opacity: 1;
  animation: VoucherDownloadAnimationshow 0.5s ease-in-out;
}
.reverse_voucher_animation{
  display: block;
  opacity: 0;
  transform: scale(0);
  animation: VoucherDownloadAnimationhide 0.5s ease-in-out;
}

.query_downloadBtn:hover,
.query_downloadVoucherBtn:hover {
  opacity: 0.9;
}

.voucher_download_overlay{
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  background-color: #0000001f;
}
.new_my_bookings_header{
  display: flex;
  align-items: center;
  column-gap: 2rem;
  font-size: 5rem;
  color: #353434;
}
@media screen and (max-width: 481px) {
  .query__parent_new {
    overflow: unset;
    height: 100%;
  }
  .query_card{
    flex-direction: column;
    height: unset;
    width: 75%;
    padding: 4.5rem;
    margin: 5.25rem auto;
  }
  .query_cardImage {
    width: 100%;
    height: 48rem;
}
.query_cardTitle {
  font-size: 4rem;
}
.query_rating{
  font-size: 3.5rem;
}
.query_activities{
  font-size: 3.5rem;
}
.query_card_cost_type{
  font-size: 3rem;
}
.query_card_price{
  font-size: 5rem;
}
.query_cardButtons {
  justify-content: space-between;
}

.query_downloadBtn,
.query_downloadVoucherBtn {
  height: 33px;
  border-radius: 11px;
  cursor: pointer;
  padding: 1.5rem 1rem 1.5rem;
  font-size: 3.25rem;
  width: 45%;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}
  .download_voucher_animation {
    padding: 10px;
    width: 50rem;
}
}

@keyframes VoucherDownloadAnimationshow{
  0% {
    transform: scale(0);
    opacity: 0;
    top: -65px;
    left: -123px;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    top: -1px;
    left: -1px;
}
}@keyframes VoucherDownloadAnimationhide{
  0% {
    transform: scale(1);
    opacity: 1;
    top: -1px;
    left: -1px;
}
100% {
  transform: scale(0);
  opacity: 0;
  top: -65px;
  left: -123px;
}
}

