.container {
    display: flex;
    flex-direction: column;
  }
  
  .banner {
    background-color: rgb(255, 255, 90);
    color: rgb(255, 117, 19);
    padding: 9px 70px;
    font: 600 20px Inter, sans-serif;
    width: 100%;
  }
  
  .content {
    align-self: center;
    display: flex;
    /* margin-top: 16px; */
    width: 100%;
    flex-direction: column;
    padding: 9px 70px;
  }
  
  .menuSection {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
    max-width: 1218px;
  }
  
  .galleryColumn {
    width: 16%;
  }
  
  .menuColumn {
    width: 84%;
    margin-left: 20px;
    max-height: 60vh;
    overflow: auto;
    scrollbar-width: none;
  }
  
  @media (max-width: 991px) {
    .banner {
      padding: 0 20px;
    }
  
    .content {
      max-width: 100%;
    }
  
    .menuSection {
      flex-direction: column;
      margin-top: 40px;
    }
  
    .galleryColumn,
    .menuColumn {
      width: 100%;
      margin-left: 0;
    }
  }