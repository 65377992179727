.hotelCard {
  border-radius: 0;
  display: flex;
  margin: 3rem auto;  width: 80rem;
  flex-direction: column;
  width: 90rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.20));
  border-radius: 0px 0px 15px 15px;
background: #FFF;
}

.coverImage {
  aspect-ratio: 2.29;
  object-fit: contain;
  object-position: center;
  width: 100%;
  border-radius: 15px 15px 0 0;
}

.contentWrapper {
  border-radius: 0 0 15px 15px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 8px 20px 8px 4px;
}

.ratingContainer {
  align-self: start;
  display: flex;
  gap: 8px;
  font-family: Roboto, sans-serif;
}

.ratingScore {
  display: flex;
  gap: 2px;
  font-size: 15px;
  color: #fe6f61;
  font-weight: 500;
  white-space: nowrap;
}

.ratingIcon {
  aspect-ratio: 0.93;
  object-fit: contain;
  object-position: center;
  width: 13px;
  border-radius: 1px;
  align-self: start;
}

.ratingText {
  color: #1a1a1a;
  font-size: 12px;
  font-weight: 400;
}

.infoContainer {
  display: flex;
  padding: 0 2rem;
  margin-top: 8px;
}

.hotelInfo {
  display: flex;
  flex-direction: column;
  align-items: start;
  font-family: Roboto, sans-serif;
}

.hotelName {
  color: #323232;
  font-size: 4rem;
  width: 34rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  align-self: stretch;
}

.cancellationText {
  color: #31de24;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-top: 4px;
}

.discountText {
  color: #ffa600;
  font-size: 13px;
  font-weight: 600;
  margin: 1rem 0 0 5rem;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
  margin: auto 0;
}

.mainPrice {
  align-self: end;
  display: flex;
  font-size: 20px;
  color: #737373;
  font-weight: 600;
  white-space: nowrap;
}

.currencyIcon {
  aspect-ratio: 0.67;
  object-fit: contain;
  object-position: center;
  width: 10px;
  margin: auto 0;
}

.priceDetails {
  display: flex;
  margin-top: 8px;
  gap: 4px;
}

.taxesText {
  color: #757575;
  font-size: 11px;
  font-weight: 400;
  flex-grow: 1;
  margin: auto 0;
}

.plusSign {
  color: #fe6f61;
}

.bookButton {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
  background-color: #fe6f61;
  aspect-ratio: 3.5;
  width: 25rem;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  padding: 0 22px;
  border: none;
  cursor: pointer;
}

.buttonBackground {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
