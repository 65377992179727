.card {
    max-width: 350px;
    border-radius: 8px;
    background-color: #fff;
    padding: 16px;
    color: #333;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .airline {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .logo {
    height: 20px;
  }
  
  .route {
    text-align: right;
  }
  
  .details {
    margin-bottom: 16px;
  }
  
  .flightSegment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-family: Inter, sans-serif;
  }
  
  .duration {
    text-align: center;
  }
  
  .arrow {
    font-size: 24px;
    font-weight: bold;
  }