.bookingPage {
  background-color: rgba(21, 21, 21, 1);
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100vh;
  padding: 3rem;
  width: 100%;
}

.backgroundImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.bookingCard {
  position: relative;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  margin-bottom: -32px;
  width: 100%;
  flex-direction: column;
  padding: 52px 20px 88px;
}

.welcomeTitle {
  color: rgba(77, 77, 77, 1);
  align-self: start;
  font: 700 25px Open Sans, sans-serif;
}

.subtitle {
  color: rgba(83, 70, 0, 1);
  align-self: start;
  margin-top: 27px;
  font: 700 15px Roboto, sans-serif;
}

.locationLabel {
  color: rgba(68, 68, 68, 1);
  align-self: start;
  margin-top: 26px;
  font: 700 15px Roboto, sans-serif;
}

.locationInput {
  border-radius: 10px;
  display: flex;
  margin-top: 17px;
  gap: 7px;
  color: rgba(68, 68, 68, 1);
  text-align: center;
  padding: 18px 9px;
  font: 400 15px Roboto, sans-serif;
  border: 1px solid rgba(68, 68, 68, 1);
}

.locationIcon {
  aspect-ratio: 1.06;
  object-fit: contain;
  object-position: center;
  width: 18px;
}

.locationText {
  flex-grow: 1;
  width: 100%;
  height: 7rem;
  background: transparent;
  flex-basis: auto;
  font-size: 4rem;
}

.dateSection {
  display: flex;
  margin-top: 31px;
  gap: 21px;
  color: rgba(68, 68, 68, 1);
  white-space: nowrap;
  font: 700 15px Roboto, sans-serif;
}

.dateColumn {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: start;
  flex: 1;
}

.dateInput {
  border-radius: 10px;
  align-self: stretch;
  display: flex;
  margin-top: 17px;
  gap: 20px;
  font-weight: 400;
  text-align: center;
  justify-content: space-between;
  padding: 16px 14px;
  border: 1px solid rgba(68, 68, 68, 1);
}

.calendarIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 22px;
}

.guestLabel {
  margin-top: 28px;
  font-size: 3.5rem;
  color: #403e3e;
  font-weight: bold;}

.roomLabel {
  margin-top: 28px;
}
.guestwrapper{
  border: 1px solid #403e3e;
  width: 100%;
  border-radius: 12px;
  margin-top: 2rem;
  display: flex
;
  justify-content: space-around;
;
}
.guestbox{
  color: #403e3e;
  padding: 3rem;
  font-size: 5rem;
}
.imageGallery {
  display: flex;
  margin-top: 17px;
  gap: 20px;
}

.galleryImage {
  aspect-ratio: 2.81;
  object-fit: contain;
  object-position: center;
  width: 30rem;
  border-radius: 10px;
  max-width: 100%;
}

.findButton {
  border-radius: 10px;
  background-color: rgba(254, 111, 97, 1);
  color: rgba(255, 255, 255, 0.88);
  white-space: nowrap;
  margin: 0 auto;
  text-align: center;
  margin: 4rem 20rem 5rem;
  padding: 11px 70px 17px;
  font: 600 18px Roboto, sans-serif;
  border: none;
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
