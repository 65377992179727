.payment-bg {
    background: #F7F7F7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.backBtn-wraper {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 10px;
}

.heading-wrapper {
    display: block
}

.paymentSubtotal {
    color: #565656;
    font-family: Inter, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.backBtn-wraper h2 {
    color: #434343;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
}