@media screen and (max-width: 768px) {
.Day-wise-individual-day-main {
    width: 90%;
    font-size: 2.5rem;
    column-gap: 5px;
}
.Day-wise-individual-day{
    width: 11.25rem;
    height: 4.8125rem;
    border-radius: 2.5rem;
}
.Mobile_Day-wise-DayCard-parent {
    display: flex;
    justify-content: flex-start;
}

.Mobile_Day-wise-DayCard-inner{
    width: 100%;
}
.Mobile_Day-wise-DayCard {
    width: 90vw;
    height: 33rem;
    display: flex;
    align-items: center;
    border-radius: 3rem;
    background: #FFF;
    box-shadow: 0rem 0.375rem 0.8125rem 0rem rgba(0, 0, 0, 0.21);
    background-image: url('https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/daydetailbackground.png');
    opacity: 1;
}
.Mobile_Day-wise-DayCard_activity_card {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../../../Assets/SearchFilter/searchFilterBG.svg") center center / cover padding-box;
    margin-left: 2rem;
    box-shadow: 0rem 0.375rem 0.8125rem 0rem rgba(0, 0, 0, 0.21);
    width: 100%;
    border-radius: 1.25rem;
    background-repeat:no-repeat ;
}
.Mobile_Day_wise_activity_parent{
    width: 85vw;
    display: flex;
    align-items: center;
}
.Mobile_Day-wise-DayCard-img-Activity {
    border-radius: 1rem;
    width: 22.8604rem;
    height: 22.0987rem;
    margin-right: 2.7rem;
}

.Mobile_Day-wise-DayCard-img-Hotel {
    width: 14.8026rem;
    height: 23.3637rem;
    margin-left: 1rem;
}

.Mobile_Day-wise-line-and-card {
    display: flex;
    position: relative;
}

.Mobile_Day-wise-line {
    border-left: 0.187rem dashed black;
    height: 42.5rem;
    margin-right: 1rem;
    border-spacing: 10px;
    margin-left: 4rem;
}

.Mobile_Day-wise-lastCompo_img_div{
    margin-right: 5rem;
    height: 42.5rem;
}

.Mobile_Day-wise-lastCompo_img{
    width: auto;
    height: 4rem;
    position: absolute;
    left: 0;
    top: 0.9375rem;
}

.Mobile_Day-wise-counter {
    color: #000;
    padding: 5px 10px 5px 10px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 7rem;
    display: inline-block;
}

.Mobile_itineary-hotel-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: -1.3rem;
    width: 13.7617rem;
    height: 7.5161rem;
    border-radius: 1.5rem;
    background: #FFF;
    box-shadow: 0.125rem 0.1875rem 0.3125rem 0rem rgba(0, 0, 0, 0.16);
    /* ///////// */
    color: #000;
    /* font-family: Poppins; */
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Mobile_itineary-Activity-details {
  display: flex;
  column-gap: 5px;
  justify-content: center;
  margin-top: 1.2rem;
}

.Mobile_itineary-details-parent {
    /* background: url("../../../../Assets/SearchFilter/searchFilterBG.svg") center center / contain padding-box; */
    /* background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0rem 0.375rem 0.8125rem 0rem rgba(0, 0, 0, 0.21); */
}

.Mobile_ActivityNameHighLighter {
    margin-left: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: fit-content;
}
.Mobile_ActivityName{
    margin-left: 1rem;
    margin-right: 1rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Limits the text to 1 line */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;
    text-align: left;
}

.Mobile_Day-wise-review {
    width: 1rem;
    height: 1rem;
}

.Mobile_DayTitle_DayDescription {
    height: 22rem;
    width: auto;
    font-size: 10px;
    overflow-y: auto;
    margin-left: 1.8rem;
    margin-top: 1rem;
    font-size: 2.5rem;
}
.Mobile_DayTitle_description_para{
    margin-bottom: 0.5rem;
    /* font-size: 1.5rem; */
    font-family: var(--font-family-sofia);
}
.Mobile_DayTitle_DayDescription::-webkit-scrollbar {
    width: 2px;
}
.Mobile_DayTitle_DayDescription::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.Mobile_DayTitle_DayDescription::-webkit-scrollbar-thumb {
    background: #888;
}
.Mobile_DayTitle_DayDescription::-webkit-scrollbar-thumb:hover {
    background: #302f2f;
}
    .Mobile_Day-wise-centerlise {
        margin-top: 5rem;
    }
    .Mobile_itineary-details-parent {
        width: 65%;
        height: 33rem;
    }
    .Mobile_ActivityNameHighLighter{
        font-size: 3.5rem;
    }
    .Mobile_Day-wise-review{
        width: 2rem;
        height: 2rem;
    }
    .Mobile_Day-wise-individual-day-main {
        width: 90%;
        font-size: 2.5rem;
    }

    .Mobile_Day-wise-individual-day {
        width: 11.25rem;
        height: 4.8125rem;
    }
}