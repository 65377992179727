.meals-container {
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    height: 100vh;
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
}

.meals-banner {
    background-color: rgb(255, 255, 90);
    color: rgb(255, 117, 19);
    padding: 9px 70px;
    font: 600 20px Inter, sans-serif;
    width: 100%;
}

.meals-content {
    align-self: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 9px 30px;
}

.meals-mealTypes {
    display: flex;
    gap: 15px;
    width: 90%;
    font-weight: 500;
}

.meals-mealType {
    display: flex;
    gap: 8px;
    align-items: center;
}

.meals-mealType label {
    color: #515151;
    font-family: Inter, sans-serif;
    font-size: 13px;
}

.meals-indicator {
    border-radius: 6px;
    width: 18px;
    height: 18px;
    border: 1px solid rgb(115, 115, 115);
}

.meals-menuSection {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
    max-width: 1218px;
}

.meals-galleryColumn {
    width: 16%;
}

.meals-menuColumn {
    width: 84%;
    margin-left: 20px;
    max-height: 60vh;
    overflow: auto;
    scrollbar-width: none;
}

.meals-foodCardContainer {
    display: flex;
    align-items: center;
    gap: 3rem;
}

.meals-foodCardContainer img {
    width: 53px;
}

.meals-foodCarMainImg {
    width: 20%;
}

.meals-foodCard {
    margin-bottom: 30px;
    width: 80%;
}

.meals-cardContent {
    display: flex;
    flex-direction: column;
}

.meals-titlePrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.meals-menuItemTitle {
    color: #525252;
    font-family: Inter, sans-serif;
    font-size: 16px;
}

.meals-addButton {
    border-radius: 8px;
    border: 1px solid #FE6F61;
    padding: 3px 12px;
    color: #FE6F61;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.meals-priceWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    color: #525252;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.meals-quantity {
    font-size: 13px;
    font-family: Inter, sans-serif;
}

.meals-inc-dnc-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.meals-decbtn,
.meals-incbtn {
    width: 32px;
    height: 32px;
    margin: 6.4px;
    text-align: center;
    border-radius: 5px;
    background: #d9d9d9;
    font-size: 14px;
}

.meals-container .action-buttons {
    width: 90%;
}


@media (max-width: 991px) {
    .mealsAddButton {
        padding: 5px 20px;
    }
}


@media (max-width: 991px) {
    .meals-banner {
        padding: 0 20px;
    }

    .meals-content {
        max-width: 100%;
    }

    .meals-menuSection {
        flex-direction: column;
        margin-top: 40px;
    }

    .meals-galleryColumn,
    .meals-menuColumn {
        width: 100%;
        margin-left: 0;
    }
}