.container {
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }
  
  
  .mainContent {
    align-self: center;
    width: 100%;
    max-width: 106.6875rem;
    padding: .375rem 0 2.5rem 0.8rem;
  }
  
  .contentGrid {
    gap: 1rem;
    display: flex;
    justify-content: space-evenly;
  }
  
  .leftColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 62%;
    margin-left: 0;
  }
  .leftColumn h1{
    font-weight: 600;
  }
  
  .formContainer{
    border-radius: .625rem;
    display: flex;
    margin-top: .625rem;
    width: 100%;
    flex-direction: column;
    align-items: start;
    padding: 1.0625rem 1.9375rem 3.375rem;
    border: 1px solid rgba(0, 0, 0, 1);
  }
  .rightColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 30%;
  }
  .actionButtons {
    align-self: stretch;
    display: flex;
    width: 100%;
    gap: 1.25rem;
    font-family: Poppins, sans-serif;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 4.375rem 0 0 .625rem;
  }
  .addPassenger{
    color: #FE6F61;
font-family: Poppins;
font-weight: 600;
  }
  .backButton {
    border-radius: .625rem;
    background-color: rgba(240, 240, 240, 1);
    box-shadow: 0 .25rem .25rem rgba(0, 0, 0, 0.25);
    font-size: 1.25rem;
    font-weight: 700;
    white-space: nowrap;
    padding: 0.5rem 2.375rem;
  }
  .proceedButton {
    border-radius: .625rem;
    background-color: rgba(254, 111, 97, 1);
    box-shadow: 0 .25rem .25rem rgba(0, 0, 0, 0.25);
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    padding: 0.5rem 0.6875rem;
  }
  /* Media Queries */
  @media (max-width: 61.9375rem) {
    .header {
      max-width: 100%;
      padding: 0 1.25rem;
    }
    
    .mainContent {
      max-width: 100%;
    }
    
    .contentGrid {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
    }
    
    .leftColumn {
      width: 100%;
    }
    
    .formContainer {
      padding: 0 1.25rem;
    }
    
    .confirmationInput {
      padding: 0 1.25rem;
    }
    
    .countrySelect {
      padding-right: 1.25rem;
    }
    
    .actionButtons {
      max-width: 100%;
      margin-top: 2.5rem;
    }
    
    .backButton {
      white-space: initial;
      padding: 0 1.25rem;
    }
    
    .proceedButton {
      padding-left: 1.25rem;
    }
  }