.hotelCard {
  border-radius: 0;
  display: flex;
  flex-direction: column;
}

.cardContainer {
  border-radius: 0.625rem;
  display: flex;
  width: 100%;
  margin: 2rem 0;
  flex-direction: column;
  align-items: start;
  padding: 0.0625rem 0 1.625rem;
  border: 0.0625rem solid rgba(137, 137, 137, 1);
}

.headerSection {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1.1875rem;
}

.hotelName {
  color: rgba(49, 49, 49, 1);
  align-self: stretch;
  flex-grow: 1;
  font: 600 1.375rem Poppins, sans-serif;
}

.ratingContainer {
  align-self: stretch;
  display: flex;
  gap: 0.0625rem;
  margin: auto 0;
}

.ratingIcon {
  aspect-ratio: 0.98;
  object-fit: contain;
  object-position: center;
  width: 1.5625rem;
}

.divider {
  align-self: stretch;
  min-height: 0.0625rem;
  margin-top: 0.25rem;
  width: 100%;
  border: 0.0625rem solid rgba(137, 137, 137, 1);
}

.description {
  color: rgba(99, 99, 99, 1);
  margin: 1.25rem 0 0 1.4375rem;
  font: 400 1.2rem Inter, sans-serif;
}

@media (max-width: 61.9375rem) {
  .cardContainer {
    max-width: 100%;
  }
  .divider {
    max-width: 100%;
  }
  .description {
    max-width: 100%;
  }
}
