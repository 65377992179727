.contact-card {
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Transparent-Black, rgba(13, 22, 52, 0.05));
    background: var(--Other-Whitest, #fff);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    max-width: 383px;
    flex-direction: column;
    font-family: Inter, sans-serif;
    font-weight: 500;
    white-space: nowrap;
    justify-content: start;
    padding: 16px;
    margin-bottom: 20px;
}

.header-container {
    display: flex;
    width: 100%;
    max-width: 336px;
    align-items: center;
    gap: 8px;
}

.avatar {
    background-color: rgba(235, 235, 235, 1);
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.username {
    color: var(--Other-Blackest, #0d1634);
    font-size: 14px;
    flex: 1;
}

.edit-button {
    font-size: 12px;
    color: #fe6f61;
    text-align: center;
}

.contact-info {
    border-radius: 8px;
    border: 1px solid var(--Transparent-Black, rgba(13, 22, 52, 0.05));
    display: flex;
    margin-top: 16px;
    width: 100%;
    max-width: 336px;
    flex-direction: column;
    font-size: 12px;
    color: var(--Neutral-color-NC40, rgba(37, 40, 49, 0.5));
    padding: 8px;
}

.contact-row {
    display: flex;
    width: 100%;
    align-items: start;
    gap: 8px;
    margin-bottom: 8px;
}

.contact-row:last-child {
    margin-bottom: 0;
}

.contact-icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 16px;
}

.contact-text {
    flex: 1;
}