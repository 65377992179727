.facilityContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto 0;
}

.facilityIcon {
  aspect-ratio: 1.18;
  object-fit: contain;
  object-position: center;
  width: 26px;
  align-self: center;
}

.facilityName {
  margin-top: 5px;
  color: rgba(0, 0, 0, 1);
  font: 500 3rem Roboto, sans-serif;
}
