.container {
  border-radius: 0;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.wrapper {
  border-radius: 0.625rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0.625rem 0 1.9375rem;
  border: 0.0625rem solid rgba(137, 137, 137, 1);
}

.title {
  color: rgba(24, 24, 24, 1);
  align-self: start;
  margin-left: 1.3125rem;
  font: 600 1.25rem Poppins, sans-serif;
}

.divider {
  min-height: 0.0625rem;
  margin-top: 0.5rem;
  width: 100%;
  border: 0.0625rem solid rgba(137, 137, 137, 1);
}

.content {
  align-self: center;
  margin-top: 0.3125rem;
  width: 100%;
  max-width: 62.875rem;
}

.grid {
  gap: 1.25rem;
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 1.25rem;
}

.column:first-child {
  margin-left: 0;
}

@media (max-width: 61.9375rem) {
  .wrapper {
    max-width: 100%;
  }

  .title {
    margin-left: 0.625rem;
  }

  .divider {
    max-width: 100%;
  }

  .content {
    max-width: 100%;
  }

  .grid {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }

  .column {
    width: 100%;
    margin-left: 0;
  }
}
