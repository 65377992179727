.global_snacks_container{
    /* height: 50px; */
    /* width: -moz-fit-content; */
    width: fit-content;
    background-color: #38A4BA;
    color: #fff;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 20px 30px;
    position: fixed;
    bottom: 1.5rem;
    left: 1.5rem;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;

}
/* .global_snacks_container.success {
    background-color: #4caf50;
  }
  
  .global_snacks_container.error {
    background-color: #f44336;
  } */
.global_snacks{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
}
@media screen and (max-width:768px) {
  .global_snacks_container{
    padding: 12px 17px;

}
  .global_snacks img{
    width: 20px;
  }
  .global_snacks p{
    font-size: 10px;
  }
}