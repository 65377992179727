.container {
  border-radius: 0;
  display: flex;
  margin:  0 auto;
  padding: 3rem;
  max-width: 401px;
  flex-direction: column;
}

.title {
  color: rgba(0, 0, 0, 1);
  text-align: center;
  text-transform: uppercase;
  align-self: start;
  z-index: 10;
  font-weight: 900  ;
  font-size: 4rem;
}

.description {
  color: rgba(0, 0, 0, 1);
  align-self: start;
  margin-top: 10px;
  font: 300 ;
  font-size: 3rem;
}

.contentWrapper {
  display: flex;
  margin-top: 14px;
  width: 100%;
  flex-direction: column;
}

.facilitiesSection {
  border-radius: 5px;
  background-color: rgba(243, 226, 224, 1);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 7px 22px;
}

.facilitiesTitle {
  color: rgba(0, 0, 0, 1);
  text-align: center;
  text-transform: uppercase;
  align-self: center;
  font: 400 14px Roboto, sans-serif;
}

.facilitiesGrid {
  display: flex;
  margin-top: 7px;
  gap: 20px;
  justify-content: space-between;
}

.searchInfo {
  border-radius: 5px;
  background-color: rgba(243, 226, 224, 1);
  display: flex
;
  margin-top: 8px;
  align-items: end;
  gap: 4px;
  color: rgba(0, 0, 0, 1);
  padding: 14px 10px;
  font: 400 14px Roboto, sans-serif;
}

.checkInOut {
  display: flex;
  gap: 14px;
  align-items: center;
}

.timeIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
}

.roomCard {
  border-radius: 15px;
  background-color: rgba(238, 238, 238, 1);
  display: flex;
  margin-top: 7px;
  padding-bottom: 18px;
  align-items: start;
  gap: 18px;
}

.roomImage {
  aspect-ratio: 1.15;
  object-fit: contain;
  object-position: center;
  width: 30rem;
  border-radius: 15px 15px 0 0;
  max-width: 100%;
}

.roomDetails {
  display: flex;
  margin-top: 9px;
  flex-direction: column;
}

.roomTitle {
  color: rgba(62, 62, 62, 1);
  font: 500 13px Inter, sans-serif;
}

.roomInfo {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 0 3rem 0 9px;
}

.infoText {
  color: rgba(104, 104, 104, 1);
  text-align: center;
  margin-left: 11px;
  font: 400 12px Roboto, sans-serif;
}

.priceSection {
  align-self: stretch;
  display: flex;
  margin-top: 26px;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
}

.priceWrapper {
  display: flex;
  align-items: center;
  gap: 1px;
}

.currencySymbol {
  aspect-ratio: 0.77;
  object-fit: contain;
  object-position: center;
  width: 10px;
}

.price {
  color: rgba(49, 49, 49, 1);
  font: 600 16px Poppins, sans-serif;
}

.perNight {
  color: rgba(104, 104, 104, 1);
  text-align: center;
  font: 400 8px Roboto, sans-serif;
}

.bookButton {
  border-radius: 7px;
  background-color: rgba(254, 111, 97, 1);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  padding: 6px 23px;
  font: 600 3.5rem Inter, sans-serif;
  border: none;
  cursor: pointer;
}
