.priceCard {
  border-radius: 0.625rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 0.0625rem solid rgba(153, 153, 153, 1);
}

.title {
  color: rgba(49, 49, 49, 1);
  font: 600 1.5rem Poppins, sans-serif;
  margin: 1.0625rem 0 0 0.875rem;
}

.divider {
  width: 100%;
  height: 0.0625rem;
  border: 0.0625rem solid rgba(159, 159, 159, 1);
  margin: 0.5625rem 0;
}

.priceRow {
  display: flex;
  justify-content: space-between;
  color: rgba(99, 99, 99, 1);
  font: 600 1rem Inter, sans-serif;
  margin: 0.6rem 0.875rem;
}

.priceLabel {
  color: rgba(99, 99, 99, 1);
}

.priceValue {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
}

.currencyIcon {
  width: 0.8125rem;
  aspect-ratio: 0.76;
  object-fit: contain;
}

.totalRow {
  display: flex;
  justify-content: space-between;
  color: rgba(49, 49, 49, 1);
  font: 600 1.5rem Poppins, sans-serif;
  margin: 0.1rem 0.875rem;
}

.paymentOptions {
  display: flex;
  justify-content: space-between;
  color: rgba(99, 99, 99, 1);
  font: 600 1.5rem Inter, sans-serif;
  margin: 0rem 0.875rem;
}
.totalPayRow {
  display: flex;
  width: 22rem;
  font-size: 1rem;
  margin: 1rem 0;
  justify-content: space-between;
}
