html {
  font-size: 16px;
}

@media only screen and (min-width: 300px) and (max-width: 480px) {
  html {
      font-size: 25%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  html {
      font-size: 35%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  html {
      font-size: 65%; 
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  html {
      font-size: 85%;
  }
}

@media only screen and (min-width: 1201px) and (max-width:1400px) {
  html {
      font-size: 95%;
  }
}
@media only screen and (min-width: 1401px) {
  html {
      font-size: 100%;
  }
}

.testResponsive {
  width: 10rem;
  justify-content: center;
  align-items: center;
}
.deskVisible {
  display: none;
}
.mobVisible{
  display: block;
}
@media screen and (max-width:640px) {
  .deskVisible {
    display: block;
  }
  .mobVisible{
    display: none;
  }
}
@media screen and (max-width: 768px){
  .twobytwo_grid_wrapper_mobile{
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    width: 95%;
    height: auto;
    /* padding: 0 12px; */
  }
}


