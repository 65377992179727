.container {
  font-family: Inter, sans-serif;
  margin-top: 30px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tabs {
  display: flex;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
}

.tab {
  flex: 1 1;
  padding: 10px 16px;
  text-align: center;
  cursor: pointer;
  background-color: #f7f7f7;
  border: none;
  font-size: 13px;
  font-weight: bold;
  color: #333;
  transition: background-color 0.3s ease;
  text-wrap: nowrap;
}

.tab:hover {
  background-color: #f7e5e5;
}

.activeTab {
  background-color: #fff;
  border-bottom: 3px solid #FE6F61;
  color: #FE6F61;
}

.content {
  padding: 20px;
  font-size: 14px;
  line-height: 1.6;
  background-color: #fff;
}

.fareDetails table {
  color: #494949;
  width: 100%;
}

.fareDetails table tr {
  border: 1px solid #000;
}

.tableHeader {
  background: rgba(254, 111, 97, 0.40);
}

.totalPrice {
  color: #FE1700;
  font-size: 24px;
  font-weight: 600;
}
.baggageinfo{
  color: #484848;
}
.baggageinfo ul li{
  margin-left: 20px;
  position: relative;
  color: #545454;
}
.baggageinfo ul li::before{
  content: '';
  width: 12px;
  height: 2px;
  display: inline-block;
  position: absolute;
  left: -20px;
  background: #000;
  top: 10px;
}
.cancellation h3{
  color: #484848;
}
.cancellation p{
  color: #555;
}
.cancellation p b{
  padding-right: 10px;
  color: #000;
}