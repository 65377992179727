.reviewContainer {
  border-radius: 0;
  display: flex;
  max-width: 29.5625rem;
  flex-direction: column;
}
.landmarkItem{
  display: flex;
  width: 100%;
  font-size: 1rem;
  justify-content: space-between;
  align-items: center;
}
.ratingSection {
  border-radius: 0.625rem;
  display: flex;
  font-family: Inter, sans-serif;
  padding: 0.6875rem;
  border: 0.0625rem solid rgba(137, 137, 137, 1);
}

.ratingWrapper {
  display: flex;
  align-items: center;
  gap: 0.6875rem;
  font-size: 1.5rem;
  font-weight: 700;
  flex-grow: 1;
  flex-basis: auto;
}

.ratingScore {
  border-radius: 0.3125rem;
  background-color: rgba(187, 187, 187, 1);
  color: rgba(33, 33, 33, 1);
  padding: 0.5rem 0.4375rem;
  font: 600 1rem Inter, sans-serif;
}

.ratingText {
  color: rgba(254, 111, 97, 1);
  align-self: stretch;
  text-wrap: nowrap;
  flex-basis: auto;
  margin: auto 0;
  font-size: 1rem;
}

.ratingCount {
  color: rgba(79, 79, 79, 1);
  font-size: 1rem;
  font-weight: 500;
  align-self: stretch;
  flex-basis: auto;
  margin: auto 0;
}

.reviewLink {
  color: rgba(254, 111, 97, 1);
  font-size: 1rem;
  text-wrap: nowrap;
  font-weight: 500;
  margin: auto 0;
}

.landmarksContainer {
  border-radius: 0.625rem;
  margin-top: 0.75rem;
  padding: 0.625rem 0.75rem 1.5rem;
  border: 0.0625rem solid rgba(137, 137, 137, 1);
}

.landmarksContent {
  gap: 1.25rem;
  display: flex;
}

@media (max-width: 61.9375rem) {
  .landmarksContent {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
}

.landmarksInfo {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  margin-left: 0;
}

@media (max-width: 61.9375rem) {
  .landmarksInfo {
    width: 100%;
  }
}

.landmarksList {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  flex-direction: column;
  align-items: start;
  color: rgba(40, 40, 40, 1);
  font: 500 1.2rem Inter, sans-serif;
}

.landmarksTitle {
  color: rgba(24, 24, 24, 1);
  font: 600 1.2rem Poppins, sans-serif;
}

.parkingSection {
  display: flex
  ;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 0.375rem;
      color: rgba(24, 24, 24, 1);
      font: 600 1.2rem Poppins, sans-serif;
}

.parkingIcon {
  aspect-ratio: 0.94;
  object-fit: contain;
  object-position: center;
  width: 1.875rem;
  margin: auto 0;
}

.distanceColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 16%;
  margin-left: 1.25rem;
}

@media (max-width: 61.9375rem) {
  .distanceColumn {
    width: 100%;
  }
}

.distanceList {
  display: flex
  ;
      margin-top: 2.875rem;
      flex-grow: 1;
      gap: 0.8rem;
      flex-direction: column;
      color: rgba(40, 40, 40, 1);
      white-space: nowrap;
      font: 500 1.1rem Inter, sans-serif;
}

.parkingStatus {
  color: rgba(51, 169, 36, 1);
  align-self: start;
  font: 600 1.2rem Poppins, sans-serif;
}
