.flightCard {
    display: flex;
    flex-direction: column;
  }
  
  .container {
    margin-left: 1.25rem;
    width: 100%;
    max-width: 68.375rem;
  }
  
  .contentWrapper {
    gap: 1.25rem;
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
  }
  
  .flightDetails {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 57%;
    margin-left: 0;
  }
  
  .airlineInfo {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-family: Inter, sans-serif;
  }
  
  .flightHeader {
    display: flex;
    width: 100%;
    gap: 2.5rem 4.0625rem;
    font-weight: 600;
    flex-wrap: wrap;
  }
  
  .airlineLogo {
    display: flex;
    align-items: start;
    gap: .3125rem;

  }
  
  .logo {
    aspect-ratio: 1.78;
    object-fit: contain;
    object-position: center;
    width: 3.5625rem;
  }
  
  .airlineText {
    display: flex;
    margin-top: .25rem;
    flex-direction: column;
  }
  
  .airlineName {
    color: rgb(82, 82, 82);
    font-size: 1rem;
    align-self: start;
  }
  
  .flightNumber {
    color: rgb(140, 140, 140);
    font-size: .875rem;
  }
  
  .routeInfo {
    align-self: end;
    display: flex;
    margin-top: 1.4375rem;
    gap: .5rem;
    flex-grow: 1;
    flex-basis: auto;
  }
  
  .locationWrapper {
    display: flex;
    gap: .125rem;
    font-size: 18px;
    color: rgb(82, 82, 82);
    white-space: nowrap;
  }
  
  .location {
    flex-grow: 1;
  }
  
  .routeIcon {
    aspect-ratio: 2.72;
    object-fit: contain;
    object-position: center;
    width: 2.375rem;
    margin: auto 0;
  }
  
  .destinationInfo {
    display: flex;
    gap: .5rem;
    flex-grow: 1;
    flex-basis: auto;
  }
  
  .destination {
    color: rgb(82, 82, 82);
    font-size: 18px;
  }
  
  .date {
    color: rgb(129, 129, 129);
    font-size: 16px;
    flex-basis: auto;
    margin: auto 0;
  }
  
  .departureInfo {
    align-self: end;
    display: flex;
    margin-top: 1.1875rem;
    width: 11.1875rem;
    max-width: 100%;
    flex-direction: column;
    font-size: 1rem;
    color: rgb(132, 132, 132);
    font-weight: 500;
  }
  
  .dateTime {
    display: flex;
    font-size: 1.125rem;
    color: rgb(82, 82, 82);
    font-weight: 600;
  }
  
  .dateText {
    padding-right: 10px;
  }
  
  .airport {
    align-self: start;
    margin-top: .4375rem;
  }
  
  .terminal {
    align-self: start;
  }
  
  .durationColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 23%;
    margin-left: 1.25rem;
  }
  
  .durationWrapper {
    display: flex;
    align-items: start;
    gap: .125rem;
    align-self: stretch;
    color: rgb(82, 82, 82);
    margin-top: 1.5rem;
    font: 600 1rem Inter, sans-serif;
  }
  
  .durationLine {
    display: flex;
    margin-top: .625rem;
    flex-direction: column;
  }
  
  .line {
    height: .125rem;
    border: .125rem solid rgb(139, 139, 139);
  }
  
  .duration {
    align-self: center;
    margin-left: .8125rem;
  }
  
  .durationIcon {
    aspect-ratio: 1.04;
    object-fit: contain;
    object-position: center;
    width: 1.4375rem;
  }
  
  .arrivalColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 20%;
    margin-left: 1.25rem;
  }
  
  .arrivalInfo {
    display: flex;
    margin-top: 1.5rem;
    width: 100%;
    flex-direction: column;
    align-items: start;
    color: rgb(132, 132, 132);
    font: 500 1rem Inter, sans-serif;
  }
  
  .arrivalDateTime {
    align-self: stretch;
    display: flex;
    gap: .125rem;
    font-size: 18px;
    color: rgb(82, 82, 82);
    font-weight: 600;
  }
  
  .arrivalDate {
    flex-grow: 1;
  }
  
  .divider {
    min-height: .0625rem;
    margin-top: 2.0625rem;
    width: 100%;
    /* border: .0625rem solid rgb(167, 167, 167); */
  }
  
  @media (max-width: 61.9375rem) {
    .container {
      max-width: 100%;
    }
    
    .contentWrapper {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
    }
    
    .flightDetails {
      width: 100%;
    }
    
    .airlineInfo {
      max-width: 100%;
      margin-top: 1.1875rem;
    }
    
    .flightHeader {
      max-width: 100%;
    }
    
    .locationWrapper {
      white-space: initial;
    }
    
    .departureInfo {
      margin-right: .625rem;
    }
    
    .durationColumn {
      width: 100%;
    }
    
    .durationWrapper {
      margin-top: 2.5rem;
    }
    
    .arrivalColumn {
      width: 100%;
    }
    
    .arrivalInfo {
      margin-top: 2.5rem;
    }
    
    .divider {
      max-width: 100%;
    }
  }