.cus-center {
  margin-top: 4rem;
}
.cus-main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.cus-policy {
  color: #000;
  /* font-family: Poppins; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cus-customise-div {
  width: 100%;
  height: 21.082rem;
  border-radius: 1.8125rem;
  border: 1.2px solid #fe6f61;
  background: #fff;
  box-shadow: 0.75rem 0.75rem 0.5rem 0rem rgba(0, 0, 0, 0.16);
  position: relative;
}

.cus-jr-logo {
  margin: 1rem;
  width: 8rem;
  height: auto;
}

.cus-jr-header {
  margin-left: 1rem;
  color: #000;
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cus-jr-heade2 {
  width: 36rem;
  font-size: 1.4rem;
  margin-left: 1rem;
}

.cus-logo-parent-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.cus-logo-div-button {
    border: none;
    color: #fff;
    font-size: 1.4rem;
    width: 13.3428rem;
    height: 3.8827rem;
    border-radius: 0.75rem;
    background: #fe6f61;
    box-shadow: 0rem 0.1875rem 0.1875rem 0rem rgba(0, 0, 0, 0.16);
    margin-top: 3rem;
    margin-left: 1rem;
}
.cus-logo-div-button:hover {
  cursor: pointer;
}
.cus-logo-div-button:active {
  cursor: pointer;
  background: #fff;
  color: #000;
}
.cus-logo-img {
  margin-top: -3.5rem;
  width: 13.7921rem;
  height: 11.9159rem;
  border-radius: 0rem 0rem 1.8125rem 1.8125rem;
}
@media screen and (max-width: 600px) {
  .cus-main {
    flex-direction: column;
    row-gap: 5rem;
  }
  .cus-policy h3 {
    font-size: 12px;
  }
  .cus-policy span {
    font-size: 8px;
  }
  .cus-customise-div {
    width: 70vw;
    height: 25.082rem;
  }
  .cus-jr-logo {
    margin: 1rem;
    width: 24rem;
    height: auto;
  }
  .cus-jr-header {
    font-size: 2.625rem;
  }
  .cus-jr-heade2 {
    font-size: 1.81rem;
  }
  .cus-logo-div-button {
    width: 14.3428rem;
    height: 3.8827rem;
    font-size: 2rem;
  }
  .cus-logo-img {
    margin-top: -7.3rem;
    width: 16.7921rem;
    height: 15.9159rem;
  }
}
