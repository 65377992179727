.carrerGradient {
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 38rem;
  background: rgba(25, 25, 25, 0.7);
}
.carrpara {
  z-index: 9;
  display: flex;
  text-align: left;
  font-family: var(--font-family-poppins);
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  height: 34rem;
  width: 50%;
}
.carrpara h1 {
  color: #fff;
  font-size: 5rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: left;
}
.carrpara p {
  color: #fff;
  font-size: 1.6rem;
  width: 40rem;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sectioncontent {
  padding: 2rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.sectioncontent h1 {
  font-weight: bold;
  font-size: 1.4rem;
}

.sectionbtn {
  border: 2px solid #fe6f61;
  color: #fe6f61;
  padding: 1rem;
  margin-top: 2rem;
  width: 42%;
  text-align: center;
}
.winningSectionContent {
  padding: 4rem 2rem;
  background-color: #ecf0f3;
}
.winningSectionContentheade {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 2rem;
}
.winingsubpara p {
  color: #5d5d5d;
  font-family: var(--font-family-poppins);
  font-size: 1rem;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: normal;
}
.winingsubpara {
  justify-content: center;
  width: 19rem;
}

.openinghead {
  color: #232323;
  font-family: var(--font-family-poppins);
  font-size: 3.5rem;
  margin-top: 2rem;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.openingDiv {
  display: flex;
  padding: 1rem 2rem;
  border: 1px solid #9f9f9f;
  align-items: center;
  gap: 3rem;
  width: 26rem;
}

.openingwrp {
  justify-content: center;
  padding: 4rem 10rem;
}
.openingdivc h1 {
  color: #000;
  font-family: var(--font-family-poppins);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.openingdivc p {
  font-weight: 700;
}
/* .winningbg {
  width: 100%;
  height: 34rem;
} */
.winningbg {
    position: relative;
    width: 100%;
    height: 34rem; /* Height set to 34rem */
    overflow: hidden; /* Ensure no overflow */
  }
  
  .winningbg img {
    height: 100%; /* Image will fill the height of the container */
    width: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure the image covers the container neatly */
  }
.carrearmain {
  height: 38rem;
}

@media screen and (max-width: 469px) {
  .carrpara h1 {
    font-size: 8rem;
  }
  .carrpara p {
    color: #fff;
    font-size: 3.6rem;
    width: 66rem;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .sectionImg {
    display: none;
  }
  .sectioncontent {
    padding: 4rem;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .sectioncontent h1 {
    font-weight: bold;
    font-size: 4.4rem;
    text-align: center;
  }
  .setcionp {
    font-size: 3.5rem;
    text-align: justify;
  }
  .sectionbtn {
    border: 2px solid #fe6f61;
    color: #fe6f61;
    padding: 3rem;
    font-size: 4rem;
    margin-top: 7rem;
    width: 64%;
    text-align: center;
  }
  .openinghead {
    color: #232323;
    font-family: var(--font-family-poppins);
    font-size: 6.5rem;
    margin-top: 7rem;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.openingwrp {
    justify-content: center;
    margin-top: 5rem;
    padding: 4rem 0rem;
}
.openingDiv {
    display: flex;
    padding: 1rem 2rem;
    justify-content: space-between;
    border: 1px solid #9f9f9f;
    align-items: center;
    gap: 3rem;
    width: 89%;
}
.openingdivc h1 {
    color: #000;
    font-family: var(--font-family-poppins);
    font-size: 4.4rem;
    margin: 2rem 0px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.openingdivc p {
    font-size: 3.6rem;
    margin: 2rem 0;
    font-weight: 700;
}
.winningSectionContentheade {
    font-size: 5.2rem;
    margin-bottom: 5rem;
}
.winingsubpara {
    justify-content: center;
    width: 41rem;
}
.winingsubpara p {
    color: #5d5d5d;
    font-family: var(--font-family-poppins);
    font-size: 3rem;
    margin: 2rem 2rem;
    font-style: normal;
    text-align: start;
    font-weight: 400;
    line-height: normal;
}
.winingsubpara h1{
    font-size: 3.4rem;
    font-weight: bold;
    text-align: center;
}
.winningbg {
    position: relative;
    width: 100%;
    height: 54rem;
    overflow: hidden; 
  }
}
