.about_page_centralise {
    display: flex;
    justify-content: space-around;
    margin-top: 1.3931rem;
    flex-direction: column;
    align-items: center;
}

.about_img_container {
    position: relative;
    width: 100%;
    height: 35rem;
    overflow: hidden;
}

.about_header_bg_img {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.Heading_wraper {
    display: flex;
    justify-content: center;
    position: relative;
    top: 1.2rem;
    flex-direction: column;
    align-items: center;
}

.about_heading {
    font-family: Amsterdam;
    font-size: 3.3rem;
    font-weight: 400;
    line-height: 13rem;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
}

.about-us-container {
    width: 79rem;
}

.about_header_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 65rem;
    height: 80%;
    margin: auto;
}

.about_header_content h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 10px;
}

.about_header_content p {
    color: #FFF;
    font-size: 1.125rem;
    text-align: center;
    margin-bottom: 10px;
}

.about-us-buttons button {
    margin-right: 10px;
    padding: 10px 20px;
    background-color: #fe6f61;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
}

.about-us-buttons .contact-button {
    background: #FFFFFB;
    color: #6E6E6E;
    font-weight: 600;
    line-height: normal;
}

.who-we-are-section {
    text-align: center;
    padding: 40px;
    background-image: url("../../Assets/Aboutimages/WhoWeAreBg.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

/* .who-we-are-section::before, .who-we-are-section::after{
    content: '';
    width: 50px;
    height: 50px;
    position: absolute;
    background-color: #008cba;
}
.who-we-are-section::before{
    left: -3%;
    background-image: url("../../Assets/Aboutimages/PolygonLeft.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.who-we-are-section::after{
    right: -2.6%;
    background-image: url("../../Assets/Aboutimages/PolygonRight.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
} */

.who-we-are-section h3 {
    color: #0D0D0D;
    font-family: Poppins;
    font-size: 2.25rem;
    font-weight: 600;
    padding-bottom: 5px;
    margin-bottom: 1.5rem;
    position: relative;
}

.who-we-are-section h3::before {
    content: '';
    height: 3px;
    width: 35px;
    display: inline-block;
    background: linear-gradient(90deg, #fe6f61 0%, #e98888 39%);
    position: absolute;
    bottom: 4px;
    border-radius: 4px;
}

.who-we-are-content {
    display: flex;
    justify-content: space-around;
    text-align: left;
}

.who-we-are-content .paragraph-left,
.who-we-are-content .paragraph-right {
    width: 45%;
}

.who-we-are-content p {
    font-size: 16px;
    color: #555;
    margin-bottom: 3.125rem;
}

.know-more-button {
    margin-top: 20px;
}

.know-more-button button {
    padding: 10px 20px;
    background-color: #fe6f61;
    border: none;
    color: white;
    cursor: pointer;
}

/* Founder's Message Section */
.founder-section-Bg {
    background-image: url("../../Assets/Aboutimages/foundersMsgBG.png")
}

.founder-section {
    margin: 40px 0;
    background: #F4F9FC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 79rem;
}

.founder-content {
    max-width: 45%;
    background-image: url("../../Assets/Aboutimages/foundersBg.png");
    padding: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    /* color: #fff; */
}

.founder-content h2 {
    font-size: 2.25rem;
    color: #08365A;
    margin-bottom: 20px;
    font-weight: 700;
    position: relative;
}

.founder-content h2::before {
    content: '';
    height: 3px;
    width: 35px;
    display: inline-block;
    background: linear-gradient(90deg, #fe6f61 0%, #e98888 39%);
    position: absolute;
    bottom: 4px;
    border-radius: 4px;
}

.founder-content p {
    font-size: 1rem;
    color: #ffffff;
}

.founder-name {
    font-style: italic;
    margin-top: 20px;
}

.vision-mission-container {
    display: flex;
    gap: 20px;
    width: 55%;
    /* margin-left: -47px; */
    position: absolute;
    right: 15px;
}

.vision-box,
.mission-box {
    background-color: white;
    padding: 20px;
    text-align: center;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
}

.vision-box img,
.mission-box img {
    width: 3rem;
    height: auto;
}

.vision-box h3,
.mission-box h3 {
    color: #436087;
    margin-bottom: 10px;
    font-weight: 600;
}

.vision-box p,
.mission-box p {
    font-size: 14px;
    color: #436087;
}

/* Core Values Section */
.core-values-section {
    padding: 40px 0;
    background-color: white;
    text-align: center;
}

.core-values-section h2 {
    font-size: 2.25rem;
    color: #000;
    margin-bottom: 20px;
    position: relative;
}

.core-values-section h2::before {
    content: '';
    height: 3px;
    width: 35px;
    display: inline-block;
    background: linear-gradient(90deg, #fe6f61 0%, #e98888 39%);
    position: absolute;
    bottom: 4px;
    border-radius: 4px;
}

.values-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    background-image: url("../../Assets/Aboutimages/corevaluePCbg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px;
}

.value-box {
    background-color: transparent;
    padding: 30px;
    width: 150px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    border: 2px solid #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Why choose us css */
.why-choose-us {
    padding: 40px 0;
    text-align: center;
}

.why-choose-us h2 {
    font-size: 2.25rem;
    color: #08365A;
    margin-bottom: 20px;
    position: relative;
}

.why-choose-us h2::before {
    content: '';
    height: 3px;
    width: 35px;
    display: inline-block;
    background: linear-gradient(90deg, #fe6f61 0%, #e98888 39%);
    position: absolute;
    bottom: 4px;
    border-radius: 4px;
}

.benefits-container {
    background: linear-gradient(90deg, rgba(113, 138, 231, 0.70) 4.5%, rgba(111, 191, 213, 0.70) 37.5%);
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
    padding: 45px;
}

.benefit-box {
    /* background: linear-gradient(180deg, #e0f7fa 0%, #b3e5fc 100%); */
    border: 2px solid #fcfcfc;
    border-radius: 8px;
    /* padding: 20px; */
    width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 16rem;
    height: 15rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
}

.benefit-box img {
    width: 50px;
    margin-bottom: 15px;
}

.benefit-box h3 {
    font-size: 18px;
    color: #00254d;
    margin-bottom: 10px;
}

.benefit-box p {
    font-size: 14px;
    color: #5d5d5d;
    padding: 10px 23px;
}

.our-clients-section {
    padding: 40px 0;
    text-align: center;
}

.our-clients-section h2 {
    font-size: 2.25rem;
    color: #08365A;
    margin-bottom: 20px;
    font-weight: 700;
    position: relative;
}

.our-clients-section h2::before {
    content: '';
    height: 3px;
    width: 35px;
    display: inline-block;
    background: linear-gradient(90deg, #fe6f61 0%, #e98888 39%);
    position: absolute;
    bottom: 4px;
    border-radius: 4px;
}

.our-clients-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.our-clients-image-wrapper img {
    height: 7rem;
    width: auto;
}
@media screen and (max-width:640px){

    .about_img_container {
        height: 60rem;
    }
    .about_header_bg_img {
        top: 0;
    }
    .about_header_content{
        height: 50%;
    }
    .about_header_content h1 {
        font-size: 24px;
    }
    .about_header_content p {
        font-size: 10px;
    }
    .about-us-buttons button {
        font-size: 9px;
    }
    .about-us-container {
        width: 100%;
    }
    .paragraph-right {
        display: none;
    }
    .who-we-are-section{
        padding: 30px;
    }
    .who-we-are-content .paragraph-left, .who-we-are-content .paragraph-right{
        width: 100%;
    }
    .who-we-are-section h3, .main-heading-every-section {
        font-size: 20px !important;
        text-align: center;
        margin-bottom: 10px !important;
    }
    .founder-section{
        flex-direction: column;
        width: 100%;
        row-gap: 50px;
        background-color: transparent;
    }
    .vision-mission-container{
        position: static
    }
    .founder-content{
        width: 100%;
        max-width: 100%;
        padding: 35px;
    }
    .vision-mission-container{
        width: 100%;
        justify-content: center;
    }
    .founder-content p {
        font-size: 12px;
    }
    .founder-name{
        text-align: right;
    }
    .vision-box, .mission-box{
        width: 180px;
    }
    .vision-box img, .mission-box img{
        width: 50px;
    }
    .vision-box h3, .mission-box h3{
        font-size: 14px;
    }
    .vision-box p, .mission-box p{
        font-size: 10px;
    }
    .values-container{
        flex-wrap: wrap;
        padding: 30px;
    }
    .value-box{
        flex-direction: column;
        width: 46%;
        padding: 10px;
        font-size: 12px;
        font-weight: 400;
        gap: 10px;
    }
    .benefits-container{
        flex-wrap: wrap;
        padding: 15px;
    }
    .benefit-box{
        width: 45%;
        height: 150px;
    }
    .benefit-box img{
        width: 35px;
        margin-bottom: 5px;
    }
    .benefit-box h3{
        font-size: 12px;
        margin-bottom: 5px;
    }
    .benefit-box p {
        font-size: 10px;
        padding: 10px 16px;
    }
    .our-clients-section {
        padding: 20px 0;
        text-align: center;
    }
    .why-choose-us{
        padding: 20px 0;
    }
    .know-more-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }
    .our-clients-marque{
        margin-top: 20px;
    }
    .who-we-are-content p {
        font-size: 12px;
    }
}