.flightCard {
    border-radius: 1.6875rem;
    background: #fff;
    width: 100%;
    margin-bottom: 1rem;
    /* padding: 1.5625rem 3.9375rem 1.5625rem 1.4375rem; */
  }
  
  .cardContent {
    gap: 1.25rem;
    display: flex;
    padding: 1.5625rem 3.9375rem 0 1.4375rem;
  }
  
  .airlineInfo {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 21%;
  }
  
  .airlineLogo {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-weight: 700;
    height: 100%;
    justify-content: space-between;
  }
  
  .logoWrapper {
    align-self: start;
    display: flex;
    color: #525252;
    font: 1rem Inter, sans-serif;
    align-items: center;
  }
  
  .logo {
    aspect-ratio: 1.78;
    object-fit: contain;
    object-position: center;
    width: 3.5625rem;
  }
  
  
  .flightTiming {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 34%;
    margin: auto;
  }
  
  .timeInfo {
    display: flex;
    gap: .5rem;
    font-family: Inter, sans-serif;
  }
  
  .locationTime {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    font-size: 0.8rem;
    color: #757575;
    font-weight: 500;
    row-gap: 5px;
  }
  .locationTimeArriVal {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    font-size: 0.8rem;
    color: #757575;
    font-weight: 500;
    row-gap: 5px;
  }
  
  .time {
    color: #525252;
    font-size: 0.9rem;
    font-weight: 600;
  }
  
  .date {
    align-self: stretch;
  }
  
  .stopInfo {
    align-self: start;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    color: #525252;
    font-weight: 600;
    width: 40%;
    text-align: center;
    row-gap: 5px;
  }
  
  .durationIndicator {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .line {
    height: .125rem;
    border: .125rem solid #8B8B8B;
  }
  
  .duration {
    align-self: center;
  }
  
  .planeIcon {
    aspect-ratio: 1.04;
    object-fit: contain;
    object-position: center;
    width: 1.4375rem;
    position: absolute;
    right: -24px;
    top: -17px;
  }
  
  .priceSection {
    margin-top: 1.25rem;
    flex-grow: 1;
  }
  .detailsButton {
    border-radius: .5rem;
    color: #FE6F61;
    padding: .375rem 1.1875rem;
    font: .8125rem Poppins, sans-serif;
    border: .0625rem solid #FE6F61;
  }
  .buyNowButton{
    border-radius: .5rem;
    background-color: #FE6F61;
    color: #fff;
    padding: .375rem 1.1875rem;
    font: .8125rem Poppins, sans-serif;
    border: .0625rem solid #FE6F61;
  }
  
  .priceOptions {
    gap: .3125rem;
    display: flex;
    flex-direction: column
  }
  .buttonsWrapper{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 3.9375rem 1.5625rem 1.4375rem;
  }
  @media (max-width: 61.9375rem) {
    .flightCard {
      max-width: 100%;
      padding: 0 1.25rem;
    }
  
    .cardContent {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
    }
  
    .airlineInfo {
      width: 100%;
    }
  
    .airlineLogo {
      margin-top: 2.5rem;
    }
  
    .detailsButton {
      margin-top: 2.5rem;
      padding: 0 1.25rem;
    }
  
    .flightTiming {
      width: 100%;
    }
  
    .timeInfo {
      margin-top: 2.5rem;
    }
  
    .priceSection {
      max-width: 100%;
      margin-top: 2.5rem;
    }
  
    .priceOptions {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
    }
  }