.calendar-popup {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
  width: 100%;
  color: #000;
}

.date-input {
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  text-align: left;
}

.popup {
  position: absolute;
  top: 0;
  right: -50%;
  border: 1px solid #f57c6c;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.popup::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: white;
  z-index: -1;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* border-bottom: 1px solid #f57c6c; */
  font-weight: bold;
  width: 70%;
  margin: 0 auto;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  padding: 10px;
  gap: 10px;
  border-bottom: 1px solid #C7C7C7;
  border-top: 1px solid #C7C7C7;
}

.calendar-day {
  font-weight: bold;
  color: #666;
}

.calendar-date {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.calendar-date:hover {
  background-color: #ffe0dd;
  border-radius: 5px;
}

.calendar-date.selected {
  color: #fff;
  border-radius: 5px;
  background: #FE6F61;
}

.popup-actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  column-gap: 15px;
}

.cancel-btn,
.done-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #fff;
  color: #f57c6c;
  border: 1px solid #f57c6c;
}

.cancel-btn:hover {
  background-color: #ffe0dd;
}

.done-btn {
  background-color: #f57c6c;
  color: #fff;
}

.done-btn:hover {
  background-color: #e65c50;
}

.calendar-date.disabled {
  color: #ccc;
  pointer-events: none;
}




/* Parent container */
.flight-search-dropdown {
  position: relative;
  width: 350px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

/* Input field styling */
.search-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
}

.search-input:focus {
  border-color: #FE6F61;
}

/* Dropdown container */
.dropdown {
  position: absolute;
  top: 50px;
  left: 0;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-items {
  overflow-y: auto;
  max-height: 350px;
}

/* Dropdown section */
.dropdown-section {
  padding: 10px 0;
}

.section-title {
  margin: 0;
  padding: 0 15px;
  font-size: 14px;
  color: #666;
  font-weight: bold;
  text-transform: uppercase;
}

/* Dropdown list */
.dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Dropdown item */
.dropdown-item {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #f1f1f1;
  transition: background 0.3s;
}

.dropdown-item:hover {
  background: #f9f9f9;
}

/* Icon */
.icon-flight {
  color: #FE6F61;
  margin-right: 10px;
}

/* Flight info */
.flight-info {
  flex: 1;
}

.city {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.airport-name {
  font-size: 12px;
  color: #666;
}

/* Airport code */
.airport-code {
  font-size: 14px;
  font-weight: bold;
  color: #FE6F61;
}