.centralise_cart{
    display: flex;
    justify-content: space-around;
    margin-top: 1.3931rem;
    /* flex-direction: column; */
}
.cart_prod_list{
    width: 79rem;
    margin-bottom: 5rem;
}
.cart_prod_list h2.cart_product_heading{
    color: #000;
    font-family: Sofia;
    font-size: 3.5rem;
}
.cart-product-card {
    display: flex;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    align-items: center;
    width: 65%;
    margin-bottom: 3.625rem;
    box-shadow: 4px 14px 15px 0px rgba(0, 0, 0, 0.16);
}
.cart_Product_card_inner_sec{
    display: flex;
    align-items: center;
}
.cart-product-image {
    height: 15.625rem;
    width: 17.5rem;
}
.cart-product-image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.cart-product-info {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    flex-grow: 1;
    width: 60%;
}

.cart-product-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 0.5rem;
}

.cart-package-duration {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #FE6F61;
    padding: 0.25rem 0.5625rem;
    box-shadow: 12px 12px 8px 0px rgba(0, 0, 0, 0.16);
}

.cart-customizable {
    font-size: 14px;
}
.product_heading_price{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-package-name {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0.4375rem;
}

.cart-package-features {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
    color: #777;
}

.cart-package-features li {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    list-style: inside;
}

.cart-product-price {
    display: flex;
    flex-direction: column;
    align-items: end;
}
.cart-package-price-text {
    color: #000;
    font-family: Poppins;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.1875rem; /* 52.632% */
}

.cart-per-person {
    font-size: 14px;
    color: #000000;
}

.cart-product-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.cart-details-button, .cart-book-button, .cart-delete-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.cart-details-button {
    background-color: white;
    color: #FF6B6B;
    border: 1px solid #FF6B6B;
}

.cart-book-button {
    background-color: #FF6B6B;
    color: white;
    width: 8rem;
}

.cart-delete-button {
    background-color: white;
    color: #FF6B6B;
    border: 1px solid #FF6B6B;
}

.cart-details-button:hover {
    background-color: #FFECEC;
}

.cart-book-button:hover {
    background-color: #FF4D4D;
}

.cart-delete-button:hover {
    background-color: #FFECEC;
}

  .no_Product_Cart{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .no_Product_Cart img{
    width: 27rem;
    margin-top: 9rem;
    margin-bottom: 0.5rem;
  }
  .text_no_product h2{
    color: #000;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  }
  .text_no_product p{
    color: #787777;
    font-size: 1.75rem;
    text-align: center;
  }
  .recommendation-Package-outer-div{
    width: 79rem;
}
  .recommendation_packages h2{
    color: #000;
    font-family: Sofia;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  }
  .recommendation-Package-holder{
    padding: 50px 0px;
    gap: 2rem;
    justify-items: center;
    width: 100vw;
    overflow: auto;
    margin-top: 0;
    position: relative;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    scroll-behavior: auto;
    overflow: visible;
    overflow-y: hidden;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
}
/* mobile css width mobile largest break point */
@media screen and (max-width:768px) {
    .cart_Product_card_inner_sec{
        display: flex;
        align-items: center;
    }
    .centralise_cart{
        margin-top: 3.3931rem;
    }
    .cart_prod_list{
        width: 100%;
        /* margin: 0 10rem; */
    }
    .cart_prod_list h2.cart_product_heading{
        font-size: 12rem;
    }
    .cart-product-card {
        display: block;
        padding: 8px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: white;
        align-items: center;
        width: 95dvw;
        margin-bottom: 3.625rem;
        box-shadow: 4px 14px 15px 0px rgba(0, 0, 0, 0.16);
    }
    
    .cart-product-image {
        height: 28rem;
        width: 30%;
    }
    .cart-product-image img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
    }
    
    .cart-product-info {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        flex-grow: 1;
        width: 60%;
    }
    
    .cart-product-header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        row-gap: 0.5rem;
    }
    
    .cart-package-duration {
        font-size: 9px;
        border: 2px solid #FE6F61;
        /* padding: 1.25rem 1.5625rem; */
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: unset;
        border-radius: 3px;
        border: 1px solid #FE6F61;
        box-shadow: 12px 12px 8px 0px rgba(0, 0, 0, 0.16);
        height: 16px;
        width: 38px;
    }
    
    .cart-customizable {
        font-size: 9px;
    }
    
    .cart-package-name {
        font-size: 14px;
        font-weight: 600;
        margin-top: 0.4375rem;
    }
    
    .cart-package-features {
        list-style-type: none;
        padding: 0;
        margin: 5px 0;
    }
    
    .cart-package-features li {
        font-size: 8px;
    }
    
    .cart-package-price-text {
        font-size: 17px;
        font-weight: 600;
        line-height: normal;
    }
    
    .cart-per-person {
        font-size: 8px;
        color: #000000;
    }
    
    .cart-product-actions {
        display: flex;
        gap: 5%;
        margin-top: 7px;
        justify-content: space-around;
    }
    
    .cart-details-button, .cart-book-button, .cart-delete-button {
        font-size: 11px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 25px;
        border-radius: 4px;
        padding: 0 20px;
    }
    .cart-delete-button{
        height: 25px;
    }
    .cart-delete-button img{
        height: 3rem;
    }
    .recommendation_packages h2{
        font-size: 4rem;
    }
}