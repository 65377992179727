.book-flight-card {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 0;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  width: 95%;
  margin: auto;
}

.book-flight-card__trip-options {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.book-flight-card__trip-options label {
  display: flex;
  font-size: 14px;
  color: #fe6f61;
  font-weight: 500;
  cursor: pointer;
  align-items: center;
  column-gap: 5px;
}

.book-flight-card__trip-options label.selected span::after {
  background-color: #FE6F61;
}

.book-flight-card__trip-options label span {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  border: solid 2px #FE6F61;
  position: relative;
}

.book-flight-card__trip-options label span::after {
  content: '';
  display: block;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  background: transparent;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.book-flight-card__form-fields {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.book-flight-card__form-group {
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
  background-color: #FE6F61;
  position: relative;
  padding: 10px;
  cursor: pointer;
  height: 85px;
}

.book-flight-card__form-group .flight_labels {
  font-size: 14px;
  text-wrap: nowrap;
  font-weight: bold;
  color: #fff;
}

.daterange-pop-up {
  position: absolute;
  top: 0;
  left: 0;

}

.book-flight-card__form-group:first-child {
  border-radius: 10px 0 0 10px;
  padding-right: 20px;
}

.book-flight-card__form-group:nth-child(2) {
  padding-left: 20px;
}

.book-flight-card__form-group:nth-last-child(2) {
  border-radius: 0 10px 10px 0;
}

.book-flight-card__form-group:hover:not(.book-flight-card__flightsInterchange) {
  background: #fe6e61c0;
}

.book-flight-card__form-group label {
  font-size: 12px;
  color: #fff;
  margin-bottom: 5px;
}

.book-flight-card__input-field,
.book-flight-card__select-field {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  background: none;
  padding: 5px;
  font-size: 14px;
  color: #fff;
}

.book-flight-card__input-field,
.book-flight-card__select-field:focus-visible {
  outline: none;
  border: none;
}

img.book-flight-card__flightsInterchange {
  position: absolute;
  right: -10%;
  top: 33%;
  z-index: 10;
  width: 35px;
}

.book-flight-card__input-field::placeholder {
  color: #fe6f61;
  font-style: italic;
}

.book-flight-card__checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  justify-content: flex-start;
  font-size: 12px;
  color: #fe6f61;
}

.book-flight-card__search-button {
  background-color: #fe6f61;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.book-flight-card__search-button:hover {
  background-color: #e65d55;
}

.flight-grdiv {
  position: absolute;
  padding: 20px;
  border: 1px solid #ff6b6b;
  color: black;
  border-radius: 8px;
  width: 20rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 10;
  right: -50%;
  top: 50%;
}

.flight-grdiv::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: white;
  z-index: -1;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.paxsubmitwrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.paxsubmitwrapper button {
  width: 6rem !important;
  margin-top: 12px;
  padding: 10px;
  background-color: #ff5a5f;
  color: #fff;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.decbtn,
.incbtn {
  width: 2rem;
  height: 2rem;
  margin: 0.4rem;
  text-align: center;
  border-radius: 0.3125rem;
  background: #d9d9d9;
}

.incdecwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px;
}

.error-text {
  position: absolute;
  color: red;
  top: -17px;
  font-size: 10px;
}


@media screen and (max-width:478px) {
  .book-flight-card__trip-options label {
    display: flex;
    font-size: 4rem;
    color: #fe6f61;
    font-weight: 500;
    cursor: pointer;
    align-items: center;
    column-gap: 5px;
  }

  .book-flight-card__trip-options {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: flex-start;
    margin: 10px;
  }
}