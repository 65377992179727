.container {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    padding: 4.17rem 7.07rem;
}

.title {
    color: rgba(82, 82, 82, 1);
    align-self: start;
    font: 600 24px Inter, sans-serif;
}

.subtitle {
    color: rgba(82, 82, 82, 1);
    align-self: start;
    margin-top: 5px;
    font: 500 20px Inter, sans-serif;
}

.optionsContainer {
    display: flex;
    margin-top: 67px;
    width: 100%;
    padding-left: 19px;
    flex-direction: column;
    font: 500 20px Inter, sans-serif;
}

.optionsWrapper {
    display: flex;
    width: 100%;
    gap: 30px 100px;
    flex-wrap: wrap;
    flex-direction: column;
}

.baggageOption {
    display: flex;
    gap: 11px;
    color: rgba(82, 82, 82, 1);
    flex: 1;
    margin: auto 0;
}

.baggageIcon {
    aspect-ratio: 1.03;
    object-fit: contain;
    object-position: center;
    width: 20px;
    align-self: start;
}

.baggageText {
    flex: 1;
}

.priceSection {
    display: flex;
    gap: 29px;
    white-space: nowrap;
    flex: 1;
}

.priceWrapper {
    display: flex;
    gap: 8px;
    color: rgba(82, 82, 82, 1);
    margin: auto 0;
}

.currencyIcon {
    aspect-ratio: 0.75;
    object-fit: contain;
    object-position: center;
    width: 12px;
    margin: auto 0;
}

.addButton {
    border-radius: 8px;
    background-color: rgba(254, 111, 97, 1);
    color: rgba(255, 255, 255, 1);
    padding: 5px 30px;
}


@media (max-width: 991px) {
    .priceSection {
        white-space: initial;
    }

    .priceWrapper {
        white-space: initial;
    }

    .addButton {
        white-space: initial;
        padding: 0 20px;
    }

    .subtitle {
        max-width: 100%;
    }

    .optionsContainer {
        max-width: 100%;
        margin-top: 40px;
    }

    .optionsWrapper {
        max-width: 100%;
    }
}