.sidebar-drawer {
  width: 100vw;
  height: 100% !important;
  height: fit-content;
  position: relative;
  flex-shrink: 0;
  border-radius: 0px 13px 13px 0px;
  background: #334b50;
  backdrop-filter: blur(18px);
}
.ProfileAvatarDrawer {
  position: relative;
  border: 1px solid;
}
.ProfileAvatarDrawer p {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 3.5rem;
  transform: translate(-50%, -50%);
}

.ProfileWrapper {
  padding: 5rem 0rem 2.5rem 4rem;
  display: flex;
  align-items: center;
  gap: 4.01rem;
}
.white-divider {
  background-color: #ffffff3d;
  margin: 0 auto !important;
  width: 85%;
  height: 0.25rem;
}
.ProfileBar {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sidebar-links2,
.sidebar-links {
  color: #fff;
  text-align: left;
  font-family: var(--font-family-poppins);
  font-size: 2.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3.5rem;
}
.css-wf16b5,
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  border-radius: 0px 15px 15px 0px;
  border: 2px solid #334b50;
  background-color: #334b50 !important;
}

.crossbtnWithAuthModel{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.crossbtn{
  font-size: 5rem;
    color: white;
    padding-right: 20px;
}
.sidebarlinksActive{
  font-size: 3.25rem;
  transition: all 0.5s ease-in-out;
}
.domestic-drawerDestination{
  border-bottom: 1px solid #f5f5f5;
}
.destinations_data_drawer{
  height: 50vh;
  overflow-y: auto;
}
.drawer-destinationname_title{
  color: #fff;
  text-align: center;
  font-family: var(--font-family-poppins);
  font-size: 3.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3.5rem;
  text-align: left;
  margin-top: 21px;
  padding-bottom: 8px;
  padding-left: 0;
  padding-right: 16px;
  background-color: #334b50;
  position: sticky;
  top: 0;
  padding-top: 4px;
}

.drawer-destinationname_content li{
  color: #fff;
  font-family: var(--font-family-poppins);
  font-size: 2.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3.5rem;
  padding: 8px 0;
}
.drawer-destinationname_content a{
  color: #fff;
  font-family: var(--font-family-poppins);
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3.5rem;
  padding: 8px 0;
}
.drawer-destinationname_content span{
  color: #fff;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3.5rem;
  padding: 8px 0;
}

@media screen and (min-width: 480px) {
  .sidebar-links {
    padding: 0rem 0rem 0rem 3rem;
  }
  .ProfileBar {
    width: 100%;
    height: 4rem;
  }
}
