.filterButton {
  display: flex;
  gap: 10px;
  color: #444;
  font: 700 20px "Open Sans", sans-serif;
  align-items: center;
}

.filterIcon {
  width: 10px;
  aspect-ratio: 0.63;
  object-fit: contain;
}
