.plan_your_trip_container {
  width: 40vw;
  margin: auto;
  padding: 20px;
  position: absolute;
  max-height: 37.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 1px solid #fe6f61;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 12px 12px 8px 0px rgba(0, 0, 0, 0.16);
}

.plan_your_trip_container h1 {
  font-size: 1.75rem;
  text-align: center;
  color: #000000;
  font-family: var(--font-family-sofia);
}

.plan_trip_sections {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.plan_trip_usersection {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 20px;
  opacity: 0.5;
  transition: 0.2s all;
  cursor: pointer;
}
.usersection_active {
  opacity: 1;
}

.icon {
  font-size: 30px;
  margin-right: 10px;
  background: #fe6f61;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.icon.active {
  background: #fe6f61;
}

.plan_trip_usersection h2 {
  font-size: 18px;
  color: #333;
}

.plan_trip_form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: auto;
  padding-top: 0.5rem;
}
.itineary_plan {
  height: 24.5rem;
}
.plan_trip_form_group {
  width: 48%;
  margin-bottom: 20px;
  position: relative;
}

.plan_trip_form_group_destination {
  width: 100%;
  position: relative;
}
.plan_your_trip_container label {
  display: block;
  margin-bottom: 5px;
  color: #fe6f61 !important;
  top: 50%;
  position: absolute;
  left: 12px;
  transform: translate(0, -50%);
  background: #fff;
  transition: 0.2s all ease-in-out;
  cursor: text;
}

.plan_your_trip_container input,
.plan_your_trip_container select {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background: none;
  appearance: none; /* For modern browsers to remove default arrow */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
}

.trip_plan_error {
  border-color: red !important;
}

.plan_your_trip_container input::placeholder {
  color: #888;
}

.plan_your_trip_container input:disabled {
  background-color: #f7f7f7;
}
.plan_trip_form_group input:focus,
.plan_trip_form_group select:focus,
#destinations:focus {
  outline: none;
  border-color: #fe6f61;
}
.plan_trip_form_group input:focus + label,
.plan_trip_form_group input:not(:placeholder-shown) + label,
.plan_trip_form_group select:focus + label,
.plan_trip_form_group select:valid + label,
#destinations:focus + label,
#destinations:valid + label {
  top: 0;
  font-size: 12px;
}

.itineary_details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.itineary_days_plan {
  border: 1px solid #fe6f61;
  border-radius: 15px;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 1rem;
}

.itineary_days_plan:last-child {
  margin-bottom: 5rem;
}
.itineary_days_plan h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.itineary_img {
  width: 65%;
}
.itineary_img img {
  border-radius: 0.75rem;
}
.itineary_selection {
  width: 35%;
}
.itineary_details_form {
  width: 90%;
}

.plan_trip_form::-webkit-scrollbar {
  display: none;
}

.plan_trip_bottom_fix {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 4rem;
  background-color: #fff;
  border-radius: 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plan_adventure_img {
  height: 6rem;
  position: absolute;
  bottom: 0;
  right: 1rem;
}
.plan_trip_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  column-gap: 2rem;
}
.plan_trip_buttons .button_save {
  width: 27%;
  height: 2.5rem;
  background-color: #fe6f61;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 0.4rem;
  cursor: pointer;
}
.plan_trip_buttons .button_share {
  width: 7.5%;
  height: 2.5rem;
  border: 1px solid #fe6f61;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 0.4rem;
}

.queryModalSetting {
  display: flex;
  justify-content: center;
  align-items: center;
}
.queryParentDiv {
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  width: 47.125rem;
  height: 31.5625rem;
  border-radius: 1.6875rem;
  background: #334b50;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(15px);
  background-image: url("../../Assets/SuuportImg/queryModalBackground.png");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  top: 50%;
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ContactInput {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  width: 27.1696rem;
  height: 3.0076rem;
  border-radius: 0.4375rem;
  border: 0.0625rem solid #000;
  background: #fff;
  box-shadow: 0.75rem 0.75rem 0.5rem 0rem rgba(0, 0, 0, 0.16);
}

.InputArea {
  margin-top: 2rem;
  border-radius: 0.4375rem;
  border: 0.0625rem solid #000;
  background-color: white;
  width: 28.1696rem;
  height: 2.89rem;
  font-size: 1.3rem;
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: none;
}
.queryParentDiv span {
  width: 84%;
}

.InputAreaerror {
  border-radius: 0.4375rem;
  border: 0.0625rem solid #000;
  width: 25.1696rem;
  height: 2.89rem;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 0.25rem solid red;
}

.query_submit_button {
  margin-top: 2rem;
  width: 14.9834rem;
  height: 3.4577rem;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0.75rem 0.75rem 0.5rem 0rem rgba(0, 0, 0, 0.16);
  color: #000;
  /* font-family: Poppins; */
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.query_submit_button:hover {
  cursor: pointer;
  background-color: #fe6f61;
  color: #020202;
}

@media screen and (max-width: 1024px) {
  .custom_datata {
    font-size: 100% !important;
  }
  .plan_your_trip_container {
    width: 90%;
    height: auto;
    max-height: 160rem;
    outline-width: 3px;
    padding: 0;
    padding-top: 20px;
  }
  .itineary_plan {
    height: 60rem;
  }
  .plan_your_trip_container > h1 {
    font-size: 4rem;
    margin-bottom: 5rem;
  }
  .icon {
    width: 9rem;
    height: 9rem;
  }
  .icon > img {
    width: 4.5rem;
  }
  .plan_trip_usersection h2 {
    font-size: 3.25rem;
  }
  .plan_trip_form_group {
    width: 48%;
  }
  .plan_trip_form_group.itineary_details_form {
    width: 100%;
  }
  .custom_data {
    width: 48%;
  }
  .plan_your_trip_container  label {
    font-size: 2.5rem !important;
  }
  .plan_your_trip_container input,
  .plan_your_trip_container select {
    font-size: 2.5rem !important;
  }
  .plan_trip_form_group input:focus + label,
  .plan_trip_form_group input:not(:placeholder-shown) + label,
  .plan_trip_form_group select:focus + label,
  .plan_trip_form_group select:valid + label,
  #destinations:focus + label,
  #destinations:valid + label {
    top: 0;
    font-size: 14px;
  }
  .itineary_days_plan {
    border-radius: 0.75rem;
    border: 0.5rem solid #fe6f61;
    background: #fff;
    box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.06);
    padding: 2rem;
  }
  .itineary_days_plan h3 {
    font-size: 3.5rem;
  }
  .plan_trip_form {
    padding-top: 3rem !important;
    padding: 20px;
  }
  .plan_your_trip_bottom_mobile {
    margin-top: 2rem;
    width: 100%;
  }
  .plan_your_trip_bottom_mobile img {
    width: 100%;
  }
  .plan_trip_buttons .button_save {
    width: fit-content;
    padding: 0 8px;
    font-size: 3rem;
    height: 4rem;
  }
  .query_submit_button {
    margin-top: 3rem;
    width: 15.9834rem;
    height: 4.4577rem;
    border-radius: 0.75rem;
    background: #fff;
    box-shadow: 0.75rem 0.75rem 0.5rem 0rem rgba(0, 0, 0, 0.16);
    color: #000;
    font-family: var(--font-family-poppins);
    font-size: 1.6rem;
  }
  .queryParentDiv {
    width: 68.27rem;
    height: 45.75rem;
  }
  .InputArea {
    width: 44.1696rem;
    height: 2.89rem;
    color: black;
    font-size: 1.9rem;
  }
  .queryParentDiv h3 {
    font-size: 3.2rem;
    /* padding-top: 2rem; */
  }
  .queryParentDiv p,
  .queryParentDiv span {
    font-size: 1.8rem;
    padding: 0.8rem;
  }
  .plan_trip_bottom_fix {
    height: 6rem;
  }
  .plan_trip_buttons .button_save{
    height: 5rem;
  }
  .plan_adventure_img{
    height: 9rem;
  }
}

@media screen and (max-width: 768px) {
  .custom_datata {
    font-size: 100% !important;
  }
  .plan_your_trip_container {
    width: 90%;
    height: auto;
    max-height: 160rem;
    outline-width: 3px;
    padding: 0;
    padding-top: 20px;
  }
  .itineary_plan {
    height: 92.5rem;
  }
  .plan_your_trip_container > h1 {
    font-size: 4rem;
    margin-bottom: 5rem;
  }
  .icon {
    width: 9rem;
    height: 9rem;
  }
  .icon > img {
    width: 4.5rem;
  }
  .plan_trip_usersection h2 {
    font-size: 3.25rem;
  }
  .plan_trip_form_group {
    width: 100%;
  }
  .custom_data {
    width: 48%;
  }
  label {
    font-size: 2.5rem ;
  }
  input,
  select {
    font-size: 2.5rem ;
  }
  .plan_trip_form_group input:focus + label,
  .plan_trip_form_group input:not(:placeholder-shown) + label,
  .plan_trip_form_group select:focus + label,
  .plan_trip_form_group select:valid + label,
  #destinations:focus + label,
  #destinations:valid + label {
    top: 0;
    font-size: 14px;
  }
  .itineary_days_plan {
    border-radius: 0.75rem;
    border: 0.5rem solid #fe6f61;
    background: #fff;
    box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.06);
    padding: 8px;
  }
  .itineary_days_plan h3 {
    font-size: 3.5rem;
  }
  .plan_trip_form {
    padding-top: 3rem !important;
    padding: 20px;
  }
  .plan_your_trip_bottom_mobile {
    margin-top: 2rem;
    width: 100%;
  }
  .plan_your_trip_bottom_mobile img {
    width: 100%;
  }
  .plan_trip_buttons .button_save {
    width: fit-content;
    padding: 0 8px;
    font-size: 3rem;
    height: 4rem;
  }
  /* .query_submit_button {
    margin-top: 3rem;
    width: 15.9834rem;
    height: 4.4577rem;
    border-radius: 0.75rem;
    background: #fff;
    box-shadow: 0.75rem 0.75rem 0.5rem 0rem rgba(0, 0, 0, 0.16);
    color: #000;
    font-family: var(--font-family-poppins);
    font-size: 1.6rem;
  } */
  .query_submit_button {
    margin-top: 3rem;
    width: 30.9834rem;
    height: 9.4577rem;
    border-radius: 0.75rem;
    background: #fff;
    box-shadow: 0.75rem 0.75rem 0.5rem 0rem rgba(0, 0, 0, 0.16);
    color: #000;
    font-family: var(--font-family-poppins);
    font-size: 3.6rem;
}

  /* .queryParentDiv {
    width: 68.27rem;
    height: 45.75rem;
  } */

  .queryParentDiv {
    width: 90%;
    height: 80%;
    display: flex;
    justify-content: center;
}
  /* .InputArea {
    width: 44.1696rem;
    height: 2.89rem;
    color: black;
    font-size: 1.9rem;
  } */
  .InputArea {
    width: 89%;
    padding: 4rem;
    margin-bottom: 2rem;
    height: 11.89rem;
    color: black;
    font-size: 3.9rem;
}
.dest-depart{
  width: 89%;
}

  .queryParentDiv h3 {
    font-size: 4.2rem;
    /* padding-top: 2rem; */
  }

  .queryParentDiv p,
  .queryParentDiv span {
    font-size: 2.8rem;
    text-align: center;
    padding: 0.8rem;
  }
  .testtext{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    position: relative;
    /* margin-bottom: 5rem; */
    top: -16rem;
  }
}

@media screen and (max-width:469px) {
  .testtext {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    position: relative;
    /* margin-bottom: 5rem; */
    top: 0;
}
}