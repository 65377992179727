.passenger-review-container {
    border-radius: 0px 0px 0px 0px;
    display: flex;
    max-width: 427px;
    flex-direction: column;
    font-family: Inter, sans-serif;
}

.passenger-card-header {
    border-bottom: 1px;
    border-style: solid;
    border-color: #C8C8C8;
    color: #A4A7AC;
    font-family: Inter, sans-serif;
    font-size: 11px;
}

.passenger-card-header th {
    padding: 0 10px;
    text-align: left;
}

.passenger-card-body {
    border-bottom: 1px;
    border-style: solid;
    border-color: #C8C8C8;
    color: #22313F;
    font-family: Inter, sans-serif;
    font-size: 11px;
}

.passenger-card-body td {
    padding: 5px;
}

.passenger-header {
    align-self: start;
    display: flex;
    margin-left: 11px;
    align-items: start;
    gap: 40px 43px;
    font-size: 11px;
}

.passenger-info {
    display: flex;
    gap: 12px;
    color: rgba(145, 145, 148, 1);
    font-weight: 600;
}

.passenger-details {
    display: flex;
    gap: 36px;
    color: rgba(164, 167, 172, 1);
    font-weight: 500;
}

.divider {
    min-height: 1px;
    margin-top: 6px;
    width: 100%;
    border: 1px solid rgba(200, 200, 200, 1);
}

.contact-section {
    display: flex;
    margin-top: 18px;
    width: 100%;
    flex-direction: column;
    font-weight: 500;
    padding: 10px;
}

.email-row {
    display: flex;
    width: 100%;
    gap: 5px;
    justify-content: space-between;
}

.email-inner-wrapper-review {
    width: 35%;
}

.email-label {
    color: rgba(63, 63, 63, 1);
    font-size: 12px;
}

.review-baggage-info {
    align-self: start;
    display: flex;
    gap: 15px;
    color: #919194;
    font-family: Inter, sans-serif;
    font-size: 11px;
    text-wrap: nowrap;
    width: 65%;
}

.cabin-baggage {
    display: flex;
    gap: 5px;
    align-items: center;
}

.checkin-baggage {
    display: flex;
    gap: 4px;
    align-items: center;
}


.form-row-review {
    display: flex;
    margin-top: 6px;
    align-items: start;
    gap: 4px;
}

.form-group-review {
    align-self: stretch;
    display: flex;
    gap: 20px;
    /* flex-direction: column; */
}

.confirmation-input {
    border-radius: 3px;
    font-size: 10px;
    color: rgba(99, 99, 99, 1);
    padding: 7px 5px;
    width: 100%;
    height: 25px;
    border: 1px solid rgba(220, 220, 220, 1);
}

.phone-label {
    color: rgba(63, 63, 63, 1);
    font-size: 12px;
    align-self: start;
    margin-top: 14px;
    width: 100%;
}

.baggage-weight {
    border-radius: 3px;
    font-size: 11px;
    color: rgba(34, 49, 63, 1);
    padding: 6px 20px;
    border: 1px solid rgba(220, 220, 220, 1);
}

.phone-section {
    align-self: start;
    display: flex;
    margin-top: 6px;
    gap: 9px;
    font-size: 12px;
    color: rgba(63, 63, 63, 1);
}

.country-code {
    border-radius: 3px;
    background-color: rgba(234, 234, 234, 1);
    display: flex;
    gap: 18px;
    padding: 5px 8px;
    border: 1px solid rgba(220, 220, 220, 1);
    align-items: center;
    height: 25px;
}

.country-code-text {
    text-wrap: nowrap;
}

.dropdown-icon {
    aspect-ratio: 1.72;
    object-fit: contain;
    object-position: center;
    width: 12px;
    margin: auto 0;
}

.phone-input {
    border-radius: 3px;
    display: flex;
    width: 100%;
    height: 25px;
    border: 1px solid rgba(220, 220, 220, 1);
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}