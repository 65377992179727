.Cart_img_container {
  position: relative;
  width: 100%;
  height: 35rem;
  overflow: hidden;
}
.cart_header_bg_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.Heading_wraper {
  display: flex;
  justify-content: center;
  position: relative;
  top: 1.2rem;
  flex-direction: column;
  align-items: center;
}
.Cart_heading {
  font-family: Amsterdam;
  font-size: 3.3rem;
  font-weight: 400;
  line-height: 13rem;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
@media screen and (max-width: 1000px) {
  .Cart_heading {
    font-size: 4.125rem;
  }
}
@media screen and (max-width: 768px) {
  .Cart_heading {
    font-size: 7.125rem;
  }
  .Cart_img_container {
    height: 61rem;
  }
}
@media only screen and (min-width: 468px) and (max-width: 637px) {
  .Heading_wraper {
    height: 18rem;
  }
  .Cart_heading {
    font-size: 5.4rem;
    padding: 12px;
  }
}
