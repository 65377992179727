.Travel-packages-center {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Travel-packages-center .slick-arrow.slick-prev,
.Travel-packages-center .slick-arrow.slick-next {
  display: none !important;
}

.Pacakge-container-div {
  width: 79rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.625rem;
}

.packageunicomponent-parent-div {
  position: relative;
  display: flex;
  width: 19rem;
  height: 20rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.packageunicomponent-backgroundImg {
  width: 19rem;
  height: 19rem;
  flex-shrink: 0;
  border-radius: 3.0625rem;
}

.packageunicomponent-details {
  /* fixing card layout */
  bottom: 0;
  position: absolute;
  width: 19rem;
  height: 4.9688rem;
  flex-shrink: 0;
  border-radius: 0rem 0rem 3.0625rem 3.0625rem;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(0.1563rem);
}

.packageunicomponent-details-fonts {
  margin-top: 1rem;
  margin-left: 1.5rem;
  color: #fff;
  /* font-family: Poppins; */
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width:767px) {
  .Travel-packages-center {
    display: block;
  }
}

/* Responsive Design for Travel Bundles */
@media screen and (max-width: 500px) {
  .packageunicomponent-parent-div {
    width: 51rem;
    height: 52rem;
    position: relative;
  }

  .Link {
    width: 100%;
    height: 190px;
  }

  .Suggestion-Pacakge-container-div,
  .Pacakge-container-div {
    width: 95%;
    display: grid;
    grid-auto-flow: column;
    scroll-behavior: auto;
    overflow: visible;
    overflow-y: hidden;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr)
  }

  .Pacakge-container-div a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .packageunicomponent-backgroundImg {
    scroll-snap-align: start;
    object-fit: cover;
    width: 190px;
    display: flex;
    height: 190px;
  }

  .packageunicomponent-details {
    width: 190px;
    height: fit-content;
    bottom: 0;
    position: absolute;
  }

  .packageunicomponent-details-fonts {
    margin-top: 1rem;
    margin-left: 1.5rem;
    color: #fff;
    font-size: var(--para-font-size);
    font-style: normal;
    font-family: var(--font-family-poppins);
    font-weight: 700;
  }
}