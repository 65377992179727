.priceRow {
  display: flex;
  width: 100%;
  max-width: 226px;
  gap: 20px;
  justify-content: space-between;
  margin: 7px 0 0 51px;
  font: 600 12px Inter, sans-serif;
  color: rgba(99, 99, 99, 1);
}

.amount {
  display: flex;
  gap: 2px;
  white-space: nowrap;
}

.currencyIcon {
  aspect-ratio: 0.78;
  object-fit: contain;
  object-position: center;
  width: 7px;
  margin: auto 0;
}
