.payment-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.divider-upi {
    width: 1px;
    background: #000;
    height: 200px;
}

.scan-pay {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.enter-upi {
    padding-left: 30px;
    color: #565656;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.scan-pay,
.enter-upi {
    flex: 1;
}

.qr-code {
    width: 150px;
    height: 150px;
    margin: 10px 0;
}

.upi-input {
    width: 80%;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #C2C2C2;
    font-size: 16px;
    font-weight: 600;
}

.footer-payment-upi-desk {
    background: #5D5D5D;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    color: #fff;
    align-items: center;
    padding-right: 20px;
}

.upi-pay-button {
    border-radius: 25px;
    padding: 5px 10px;
    background: linear-gradient(90deg, #80B8F0 0%, #1B75FB 100%);
    color: #FFF;
    margin: 5px 0;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}