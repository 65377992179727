.bookingContainer {
  border-radius: 0;
  display: flex;
  width: 24rem;

  flex-direction: column;
  margin: 0 auto;
}

.bookingCard {
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
  padding-bottom: 1rem;
  flex-direction: column;
  border: 0.0625rem solid rgba(153, 153, 153, 1);
}

.hotelImage {
  aspect-ratio: 1.96;
  object-fit: contain;
  object-position: center;
  width: 100%;
  border-radius: 0.625rem 0.625rem 0 0;
}

.hotelName {
  color: rgba(49, 49, 49, 1);
  align-self: start;
  margin: 0.5625rem 0 0 0.875rem;
  font: 600 1.5rem Poppins, sans-serif;
}

.divider {
  width: 100%;
  height: 0.0625rem;
  border: 0.0625rem solid rgba(159, 159, 159, 1);
  margin: 0.5625rem 0;
}

.contentWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 0 0.875rem;
}

.ratingContainer {
  display: flex;
  width: 100%;
  gap: 1.25rem;
  justify-content: space-between;
}

.ratingWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.reviewDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ratingScore {
  border-radius: 0.3125rem;
  background-color: rgba(187, 187, 187, 1);
  color: rgba(33, 33, 33, 1);
  padding: 0.5rem 0.4375rem;
  font: 600 1rem Inter, sans-serif;
}

.ratingText {
  color: rgba(49, 49, 49, 1);
  font: 600 1rem Poppins, sans-serif;
}

.reviewCount {
  color: rgba(99, 99, 99, 1);
  font: 500 1rem Inter, sans-serif;
}

.reviewLink {
  color: rgba(254, 111, 97, 1);
  font: 500 1rem Inter, sans-serif;
}

.roomInfo {
  display: flex;
  margin-top: 1.1rem;
  gap: 0.75rem;
  font: 1rem Inter, sans-serif;
}

.roomLabel {
  color: rgba(28, 28, 28, 1);
  font-weight: 600;
}

.roomType {
  color: rgba(99, 99, 99, 1);
  font-weight: 500;
}

.bookingStatus {
  color: rgba(99, 99, 99, 1);
  margin-top: 0.25rem;
  font: 500 1rem Inter, sans-serif;
}

.dateInfo {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1.375rem 0 0 0.875rem;
  font: 1.375rem Inter, sans-serif;
}

.dateRow {
  display: flex;
  font-size: 1rem;
  gap: 0.6875rem;
}

.dateLabel {
  color: rgba(28, 28, 28, 1);
  font-weight: 600;
}

.dateValue {
  color: rgba(99, 99, 99, 1);
  font-weight: 500;
}

.stayDuration {
  color: rgba(99, 99, 99, 1);
  font-weight: 500;
  font-size: 1rem;
}