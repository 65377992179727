.flight_container {
    width: 100%;
    background: #E0E0E0;
    padding-top: 50px;
    padding-bottom: 50px;
}

.flight_inner_container {
    width: 95%;
    display: flex;
    margin: 0 auto;
    column-gap: 10px;
}

.flight_listings {
    width: 80%;
}

.fullpage_loader {
    height: 80vh;
    width: 98vw;
    display: flex;
    justify-content: center;
    align-items: center;
}