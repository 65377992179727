.container {
  display: flex;
  padding-left: 80px;
  flex-direction: column;
}

.bookingPage {
  background-color: #fff;
  overflow-x: auto;
  align-self: end;
  display: flex;
  width: 440px;
  max-width: 100%;
  padding-top: 34px;
  flex-direction: column;
  overflow: hidden;
  align-items: start;
}

.header {
  background-color: #f7f7f7;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: start;
  font-weight: 500;
  padding: 6px 44px 17px 18px;
}

.title {
  color: #484848;
  font: 16px Inter, sans-serif;
  margin: 0;
}

.roomType {
  color: #555;
  margin: 8px 0 0;
  font: 14px Roboto, sans-serif;
}

.divider {
  align-self: stretch;
  margin: 16px 0;
  border: 1px solid #b5b5b5;
}

.cancellationDetails {
  align-self: stretch;
  padding: 0 44px 0 18px;
  font: 600 12px Roboto, sans-serif;
}

.refundStatus {
  color: #515151;
  line-height: 17px;
  margin: 0 0 0 20px;
}

.cancellationPolicy {
  color: #606060;
  font-weight: 400;
  line-height: 17px;
  margin: 35px 0 0 22px;
}

.bookButton {
  border: none;
  border-radius: 7px;
  background-color: #fe6f61;
  align-self: end;
  margin-top: 40px;
  width: 326px;
  max-width: 100%;
  color: #fff;
  text-align: center;
  padding: 14px;
  font: 20px Inter, sans-serif;
  cursor: pointer;
}

.bookButton:hover {
  background-color: #e65d4f;
}

.bookButton:focus {
  outline: 2px solid #000;
  outline-offset: 2px;
}

.footerImages {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footerImage,
.footerImageSecond {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 51px;
}

@media (max-width: 991px) {
  .container {
    padding-left: 20px;
  }

  .header {
    max-width: 100%;
    padding-right: 20px;
  }

  .cancellationDetails {
    max-width: 100%;
    padding-right: 20px;
  }

  .refundStatus {
    margin-left: 10px;
  }

  .cancellationPolicy {
    margin-left: 10px;
  }

  .bookButton {
    padding: 0 20px 10px;
  }
}
