.floating-button {
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #4caf50;
  border-radius: 50%;
  color: white;
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.floating-button:hover {
  background-color: #45a049;
}

/* Form Container Styles */
.form-container {
  position: fixed;
  z-index: 999999;
  bottom: -100%;
  right: 20px;
  width: 400px;
  padding: 20px;
  background-color: #f4f4f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.form-container.visible {
  bottom: 80px;
  opacity: 1;
}

/* Form Styles */
.bughunter-form {
  font-family: Arial, sans-serif;
}

.floating-button h2, .bughunter-form h2 {
  text-align: center;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #555;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.form-group textarea {
  height: 100px;
  resize: none;
}

.image-preview {
  margin-top: 10px;
  text-align: center;
}

.image-preview img {
  width: 200px;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.remove-button {
  display: block;
  margin: 10px auto;
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.remove-button:hover {
  background-color: #d32f2f;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

.bughunter-form h2 {
  font-size: 1.5rem;
}

@media screen and (max-width: 468px) {
  .form-container {
    width: 85rem;
  }
  .bughunter-form h2 {
    font-size: 5.5rem;
}
}
