.container {
  border-radius: 0;
  display: flex;
  flex-direction: column;
}


.mainContent {
  align-self: center;
  width: 100%;
  max-width: 106.6875rem;
  padding: .375rem 0 0 0.8rem;
}

.contentGrid {
  gap: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 62%;
  margin-left: 0;
}

.nameWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.leftColumn h1 {
  font-weight: 600;
}

.formContainer {
  border-radius: .625rem;
  display: flex;
  margin-top: .625rem;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 1.0625rem 1.9375rem 3.375rem;
  border: 1px solid rgba(0, 0, 0, 1);
}

.emailwrapper {
  display: flex;
  margin-top: 1.9375rem;
  width: 58rem;
  max-width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
  font: 600 1.125rem Inter, sans-serif;
}

.passengerName {
  color: #181818;
  font-family: Inter;
  font-size: 1.33333rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
}

.formGrid {
  width: 54.375rem;
  max-width: 100%;
}

.formRow {
  gap: 1.25rem;
  display: flex;
}

.formColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 31.5%;
  margin-left: 0;
}

.inputGroup {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: rgba(63, 63, 63, 1);
  font: 600 1.125rem Inter, sans-serif;
}

.inputField {
  border-radius: .3125rem;
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.6);
  display: flex;
  margin-top: .5625rem;
  height: 2.5rem;
  padding: 0px .9375rem;
  border: .0625rem solid rgba(162, 162, 162, 1);
  align-items: center;
}

.haserror {
  border: 1.5px solid red !important;
  animation: shake 0.2s ease-in-out 0s 5;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}

.confirmationInput {
  border-radius: .3125rem;
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.6);
  margin-top: .625rem;
  width: 100%;
  max-width: 100%;
  color: rgba(99, 99, 99, 1);
  padding: .875rem 2.25rem;
  font: 500 1.125rem Inter, sans-serif;
  border: .0625rem solid rgba(162, 162, 162, 1);
}

.passportSection {
  color: rgba(24, 24, 24, 1);
  margin-top: 2.125rem;
  font: 700 1.125rem Inter, sans-serif;
}

.passportGrid {
  display: flex;
  margin-top: 1.9375rem;
  width: 58rem;
  max-width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
  font: 600 1.125rem Inter, sans-serif;
}

.contactGrid {
  display: flex;
  margin-top: 2.125rem;
  width: 36.9375rem;
  max-width: 100%;
  gap: 1.25rem;
  color: rgba(63, 63, 63, 1);
  flex-wrap: wrap;
  justify-content: space-between;
  font: 600 1.125rem Inter, sans-serif;
}

.mobileNoDetails {
  display: flex;
  flex-direction: column;
}

.countrySelect {
  border-radius: .3125rem;
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.6);
  display: flex;
  gap: 2.5rem 6.25rem;
  color: rgba(90, 90, 90, 1);
  flex-grow: 1;
  flex-basis: auto;
  padding: .9375rem 1.75rem;
  border: .0625rem solid rgba(162, 162, 162, 1);
}

.saveCheckbox {
  display: flex;
  margin-top: 2.4375rem;
  gap: 1rem;
  color: rgba(63, 63, 63, 1);
  font: 600 1.125rem Inter, sans-serif;
  align-items: center;
}

.actionButtons {
  align-self: stretch;
  display: flex;
  width: 100%;
  gap: 1.25rem;
  font-family: Poppins, sans-serif;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2.375rem 0 2.375rem .625rem;
}

.addPassenger {
  color: #FE6F61;
  font-family: Poppins;
  font-weight: 600;
  cursor: pointer;
}

.disableAddPassenger {
  cursor: not-allowed;
  color: #fe6e61a1 !important;
}

.backButton {
  border-radius: .625rem;
  background-color: rgba(240, 240, 240, 1);
  box-shadow: 0 .25rem .25rem rgba(0, 0, 0, 0.25);
  font-size: 1.25rem;
  font-weight: 700;
  white-space: nowrap;
  padding: 0.5rem 2.375rem;
}

.addMealSeat {
  color: #FE6F61;
  font-family: Poppins;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.55556rem;
  border: 1px solid #FE6F61;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 0.8rem;
}

.proceedButton {
  border-radius: .625rem;
  background-color: rgba(254, 111, 97, 1);
  box-shadow: 0 .25rem .25rem rgba(0, 0, 0, 0.25);
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  padding: 0.5rem 0.6875rem;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 30%;
}

.flightDetails {
  display: flex;
  margin-top: 2.75rem;
  width: 100%;
  flex-direction: column;
}

.airlineInfo {
  display: flex;
  font-family: Inter, sans-serif;
  font-weight: 600;
  flex-wrap: wrap;
}

.airlineLogo {
  aspect-ratio: 1.78;
  object-fit: contain;
  object-position: center;
  width: 3.5625rem;
  align-self: start;
}

.routeInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
}

.fareBox {
  border-radius: .625rem;
  align-self: center;
  display: flex;
  /* margin-top: 2.9375rem; */
  width: 29.5625rem;
  max-width: 100%;
  flex-direction: column;
  padding: 1.875rem 1.3125rem 1.25rem;
  font: 600 1.125rem Inter, sans-serif;
  border: .0625rem solid rgba(0, 0, 0, 1);
  background-color: #fff;
  overflow: hidden;
}

.fareDivider {
  margin-top: 0.9375rem;
  height: .0625rem;
  border: .125rem solid rgba(184, 184, 184, 1);
}

.fareRow {
  align-self: center;
  display: flex;
  margin-top: .75rem;
  width: 22.5625rem;
  max-width: 100%;
  gap: 1.25rem;
  color: rgba(117, 117, 117, 1);
  justify-content: space-between;
}

.totalAmount {
  color: rgba(255, 67, 48, 1);
}

/* Media Queries */
@media (max-width: 61.9375rem) {
  .header {
    max-width: 100%;
    padding: 0 1.25rem;
  }

  .mainContent {
    max-width: 100%;
  }

  .contentGrid {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }

  .leftColumn {
    width: 100%;
  }

  .formContainer {
    padding: 0 1.25rem;
  }

  .confirmationInput {
    padding: 0 1.25rem;
  }

  .countrySelect {
    padding-right: 1.25rem;
  }

  .actionButtons {
    max-width: 100%;
    margin-top: 2.5rem;
  }

  .backButton {
    white-space: initial;
    padding: 0 1.25rem;
  }

  .proceedButton {
    padding-left: 1.25rem;
  }
}