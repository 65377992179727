.roomCard {
  border-radius: 0;
  display: flex;
  max-width: 384px;
  flex-direction: column;
  font-family: Inter, sans-serif;
}

.cardContainer {
  border-radius: 10px;
  background-color: rgba(244, 244, 244, 1);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 11px;
}

.contentWrapper {
  display: flex;
  gap: 9px;
  font-size: 13px;
  color: rgba(99, 99, 99, 1);
  font-weight: 500;
}

.roomDetails {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.roomTitle {
  color: rgba(24, 24, 24, 1);
  align-self: stretch;
  font: 15px Roboto, sans-serif;
}

.refundStatus {
  margin-top: 7px;
}

.priceInfo {
  align-self: end;
  margin-top: 29px;
  flex-grow: 1;
  width: 95px;
}

.taxText {
  color: rgba(53, 53, 53, 1);
}

.taxNote {
  font-size: 10px;
}

.actionContainer {
  align-self: end;
  display: flex;
  gap: 5px;
}

.detailsButton {
  border-radius: 5px;
  font-size: 10px;
  color: rgba(254, 111, 97, 1);
  font-weight: 500;
  padding: 6px 8px;
  border: 1px solid rgba(254, 111, 97, 1);
  cursor: pointer;
}

.priceButton {
  border-radius: 5px;
  background-color: rgba(254, 111, 97, 1);
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  white-space: nowrap;
  padding: 2px 26px;
  cursor: pointer;
}
