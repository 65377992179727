.container {
  border-radius: 10px;
  border-radius: 10px;
  max-width: 380px;
  margin: 0 auto;
}

.galleryGrid {
  display: flex;
}

.mainImageWrapper {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 54%;
}

.thumbnailWrapper {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 46%;
  margin-left: 6px;
}

.thumbnailContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.mainImage {
  aspect-ratio: 0.86;
  object-fit: contain;
  object-position: center;
  width: 214px;
  border-radius: 10px;
  max-width: 100%;
  flex-grow: 1;
}

.thumbnailTop {
  aspect-ratio: 1.47;
  object-fit: contain;
  object-position: center;
  width: 181px;
  border-radius: 10px;
}

.thumbnailBottom {
  aspect-ratio: 1.49;
  object-fit: contain;
  object-position: center;
  width: 181px;
  border-radius: 10px;
  margin-top: 4px;
}

@media (max-width: 991px) {
}
