.container {
  display: flex
  ;
      max-width: 320px;
      margin: 0 auto;
      flex-direction: column;
      font-family: Inter, sans-serif;
}

.roomImage {
  aspect-ratio: 2.29;
  object-fit: contain;
  object-position: center;
  width: 100%;
  border-radius: 15px 15px 0 0;
}

.contentWrapper {
  border-radius: 0 0 15px 15px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 5px 4px 15px;
}

.headerSection {
  align-self: stretch;
  display: flex;
  align-items: start;
  gap: 10px 0px;
  font-family: Roboto, sans-serif;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
}

.ratingContainer {
  display: flex;
  gap: 8px;
}

.ratingScore {
  display: flex;
  gap: 2px;
  font-size: 15px;
  color: #fe6f61;
  font-weight: 500;
  white-space: nowrap;
}

.ratingIcon {
  aspect-ratio: 0.93;
  object-fit: contain;
  object-position: center;
  width: 13px;
  border-radius: 1px;
  align-self: start;
}

.ratingText {
  color: #1a1a1a;
  font-size: 12px;
  font-weight: 300;
  flex-basis: auto;
}

.roomTitle {
  color: #323232;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  margin-top: 8px;
}

.amenitiesContainer {
  display: flex;
  gap: 12px;
  font: 400 12px Inter, sans-serif;
}

.includedAmenities {
  display: flex;
  flex-direction: column;
  align-items: start;
  color: #33a924;
}

.excludedAmenities {
  display: flex;
  flex-direction: column;
  align-items: start;
  color: #ca4545;
}

.discountBadge {
  color: #ffa600;
  font-size: 13px;
  font-weight: 600;
}

.footerSection {
  display: flex;
  width: 100%;
  max-width: 366px;
  align-items: start;
  gap: 20px;
  justify-content: space-between;
}

.amenityText {
  color: #33a924;
  font-size: 12px;
  font-weight: 400;
}

.taxInfo {
  display: flex;
  gap: 40px 55px;
  font-size: 10px;
  color: #757575;
}

.divider {
  aspect-ratio: 0.14;
  object-fit: contain;
  object-position: center;
  width: 1px;
  margin: auto 0;
}

.actionSection {
  display: flex;
  gap: 18px;
}

.moreServicesButton {
  border-radius: 8px;
  font-size: 12px;
  color: #fe6f61;
  font-weight: 700;
  padding: 5px 6px;
  border: 1px solid #fe6f61;
  cursor: pointer;
}

.priceTag {
  border-radius: 5px;
  background-color: #fe6f61;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  padding: 0 14px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
