.checkboxContainer {
  display: flex;
  margin-top: 1.5rem;
  gap: 0.25rem;
  font-weight: 500;
}

.checkbox {
  width: 1.125rem;
  height: 1.125rem;
  margin: auto 0;
  border: 0.0625rem solid #868686;
}

.label {
  flex: 1;
  font-size: 1rem;
  color: #3f3f3f;
}

@media (max-width: 61.9375rem) {
  .label {
    max-width: 100%;
  }
}
