.container {
  border-radius: 0.625rem;
  display: flex;
  width: 75rem;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.galleryWrapper {
  border-radius: 0.625rem;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1.0625rem 0 4.375rem;
}

.galleryTitle {
  color: rgba(24, 24, 24, 1);
  align-self: start;
  margin-left: 1.75rem;
  font: 800 1.375rem Poppins, sans-serif;
}

.divider {
  min-height: 0.0625rem;
  margin-top: 1.5625rem;
  width: 100%;
  border: 0.0625rem solid rgba(187, 187, 187, 1);
}

.galleryGrid {
  align-self: center;
  margin-top: 2.375rem;
  width: 100%;
  max-width: 103.5rem;
}

.gridContainer {
  gap: 1.25rem;
  justify-content: center;
  display: flex;
}

.gridColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 14rem;
  margin-left: 1.25rem;
}

.gridColumn:first-child {
  margin-left: 0;
}

.galleryImage {
  aspect-ratio: 1.18;
  object-fit: contain;
  object-position: center;
  width: 24.1875rem;
  border-radius: 0.625rem;
  max-width: 100%;
  flex-grow: 1;
}

@media (max-width: 61.9375rem) {
  .galleryWrapper {
    max-width: 100%;
  }
  .container {
    border-radius: 0.625rem;
    display: flex;
    width: 98%;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
  }
  .galleryTitle {
    margin-left: 0.625rem;
    font-size: 4.2rem;
  }
  .gridColumn:first-child {
    margin-left: 0;
    width: 62rem;
    height: 62rem;
  }
  .divider {
    max-width: 100%;
  }

  .galleryGrid {
    max-width: 100%;
  }

  .gridContainer {
    gap: 0;
  }

  .gridColumn {
    width: 100%;
    margin-left: 0;
  }

  .galleryImage {
    margin-top: 2.25rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
