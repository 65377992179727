.loading__parents{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  height: 100%;
}
.loading{
  font-size: 4rem;
  font-weight: 600;
}
.loading__shimmer span {
  position: relative;
  color: rgba(0, 0, 0, 0.08);
}
.loading__shimmer2 span {
  position: relative;
  color: rgba(0, 0, 0, 0.08);
}

.loading__shimmer span::after {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-text);
  color: #ff0000;
  opacity: 0;
  transform: scale(1.2);
  animation: loading__shimmer 3s infinite;
}
.loading__shimmer2 span::after {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-text);
  color: #395ce0;
  opacity: 0;
  transform: scale(1.2);
  animation: loading__shimmer 3s infinite;
}

.loading__shimmer span:nth-child(2)::after {
  animation-delay: 0.05s;
}

.loading__shimmer span:nth-child(3)::after {
  animation-delay: 0.1s;
}

.loading__shimmer span:nth-child(4)::after {
  animation-delay: 0.15s;
}

.loading__shimmer span:nth-child(5)::after {
  animation-delay: 0.2s;
}

.loading__shimmer span:nth-child(6)::after {
  animation-delay: 0.25s;
}
.loading__shimmer span:nth-child(7)::after {
  animation-delay: 0.3s;
}
.loading__shimmer span:nth-child(8)::after {
  animation-delay: 0.35s;
}
.loading__shimmer2 span:nth-child(1)::after {
  animation-delay: 0.4s;
}
.loading__shimmer2 span:nth-child(2)::after {
  animation-delay: 0.45s;
}
.loading__shimmer2 span:nth-child(3)::after {
  animation-delay: 0.5s;
}
.loading__shimmer2 span:nth-child(4)::after {
  animation-delay: 0.55s;
}
.loading__shimmer2 span:nth-child(5)::after {
  animation-delay: 0.6s;
}
.loading__shimmer2 span:nth-child(6)::after {
  animation-delay: 0.65s;
}
.loading__shimmer2 span:nth-child(7)::after {
  animation-delay: 0.7s;
}

@keyframes loading__shimmer {
  0%, 75%, 100% {
    transform: scale(1.2);
    opacity: 0;
  }
  25%, 50% {
    transform: scale(1);
    opacity: 1;
  }
}
