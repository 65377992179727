.container {
  border-radius: 0;
  display: flex;
  max-width: 413px;
  flex-direction: column;
  align-items: start;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  text-align: center;
}

.location {
  color: rgba(68, 68, 68, 1);
  font-size: 20px;
  font-weight: 700;
}
.drawerContent{
  color: #1E1E1E;
text-align: center;
font-size: 4rem;
margin: 4rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.drawerContent h2{
  display: flex;
  justify-content: space-between;

}
.result{
  color: white;
  padding: 3rem;
  border-radius: 7px;
  margin-top: 4rem;
background: #FE6F61;
}
.filterContainer {
  display: flex
  ;
  align-items: center;
      overflow: scroll;
      margin-top: 17px;
      width: 100%;
      gap: 2rem;
      scrollbar-width: none;
}
