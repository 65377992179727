.seat-map-container {
    font-family: Arial, sans-serif;
    /* max-width: 400px; */
    margin: auto;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    overflow: auto;
    scrollbar-width: none;
}

.route-info-seatmobile {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    margin-bottom: 15px;
}

.location-info {
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;
    font-weight: 500;
    justify-content: start;
}

.airport-code {
    color: #22313f;
    font-size: 20px;
}

.city-name {
    color: #808991;
    font-size: 12px;
}

.flight-duration {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 156px;
}

.trip-icon {
    display: flex;
    max-width: 100%;
    width: 156px;
    align-items: center;
    gap: 2px;
    padding: 1px 16px;
}

.dash-line {
    align-self: stretch;
    width: 45px;
    height: 1px;
    margin: auto 0;
    border: 1px dashed rgba(172, 172, 172, 1);
}

.plane-icon {
    aspect-ratio: 1.06;
    object-fit: contain;
    object-position: center;
    width: 18px;
    align-self: stretch;
}

.duration-text {
    color: #22313f;
    text-align: center;
    margin-top: 5px;
    font: 500 12px Inter, sans-serif;
}

.seat-layout {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    background-color: #FFF1F0;
    border-radius: 8px;
    /* row-gap: 30px; */
    max-height: 65vh;
    overflow: auto;
    scrollbar-width: none;
}

.seat-row {
    display: flex;
    gap: 4px;
    justify-content: center;
}

.seat {
    width: 46px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ffb3b3;
    border-radius: 25px;
    font-size: 12px;
    cursor: pointer;
    position: relative;
}

.seat.available {
    background-color: #fff;
}

.seat.not-available {
    background-color: #ffecec;
    color: #ffb3b3;
    cursor: not-allowed;
    border: none;
}

.seat.selected {
    background-color: #ff5e57;
    color: #fff;
}

.seat.not-available::after,
.seat.not-available::before {
    position: absolute;
    top: 30%;
    left: 50%;
    content: ' ';
    height: 10px;
    width: 2px;
    background-color: #333;
}

.seat.not-available::before {
    transform: rotate(45deg);
}

.seat.not-available::after {
    transform: rotate(-45deg);

}

.seat.selected::after {
    content: "";
    position: absolute;
    left: 30%;
    top: 45%;
    width: 8px;
    height: 16px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-50%, -50%);
}

.seat-tool-tip {
    position: absolute;
    top: -40px;
    padding: 10px;
    background: #FE6961;
    color: #fff;
    border-radius: 5px;
}

.seat-tool-tip::after {
    content: '';
    width: 20px;
    height: 20px;
    background-color: #FE6961;
    display: block;
    position: absolute;
    right: 28%;
    transform: rotate(45deg);
    bottom: -10px;
}

.seatEmptyLabel {
    width: 36px;
    height: 36px;
    display: inline-block;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.seatColumsNumber {
    width: 45px;
    height: 36px;
    display: inline-block;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.legend {
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
}

.legend-items {
    display: flex;
    align-items: center;
    column-gap: 6px;
    color: #0D1634;

    font-family: "General Sans";
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    /* 140% */
    letter-spacing: 0.8px;
}

.legend-dot {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.legend-dot.selected::after {
    content: "";
    position: absolute;
    left: 25%;
    top: 45%;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-50%, -50%);
}

.legend-dot.selected {
    position: relative;
    background-color: #ff5e57;
}

.legend-dot.available {
    background-color: #fff;
    border: 1px solid #ffb3b3;
}

.legend-dot.not-available::after,
.legend-dot.not-available::before {
    position: absolute;
    top: 25%;
    left: 50%;
    content: ' ';
    height: 10px;
    width: 2px;
    background-color: #333;
}

.legend-dot.not-available::before {
    transform: rotate(45deg);
}

.legend-dot.not-available::after {
    transform: rotate(-45deg);

}

.legend-dot.not-available {
    position: relative;
    background-color: #ffecec;
}

.selection-info {
    margin: 16px 0;
    text-align: center;
}

.selected-seat h2 {
    color: #0D1634;
    font-family: "General Sans";
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}

.selectedSeat-Wrapper {
    display: flex;
    column-gap: 10px;
}

.selected-seatNumber {
    border-radius: 32px;
    background: #FE6F61;
    display: flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #FFF;
    font-family: Inter, sans-serif;
    font-size: 14px;
}

.selected-seatPrice {
    border-radius: 32px;
    background: #FE6F61;
    display: flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #FFF;
    font-family: Inter, sans-serif;
    font-size: 14px;
}

.actions {
    display: flex;
    justify-content: space-between;
}

.fare-breakup,
.done {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.fare-breakup {
    background-color: #fff;
    color: #ff5e57;
    border: 1px solid #ff5e57;
}

.done {
    background-color: #ff5e57;
    color: #fff;
}