  .fare-breakup-overlay {
      background: rgba(52, 52, 52, 0.20);
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 100vh;
      z-index: 2;
  }

  .fare-breakup {
      border-radius: 0;
      display: flex;
      max-width: 440px;
      flex-direction: column;
      font-family: Inter, sans-serif;
      border-radius: 15px 15px 0px 0px;
      background: #FFF;
      width: 100%;
      z-index: 30;
      position: fixed;
      bottom: 0;
      max-height: 90vh;
      overflow: auto;
  }

  .breakup-container {
      border-radius: 15px 15px 0 0;
      background-color: rgba(255, 255, 255, 1);
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      padding: 25px 0 52px;
  }

  .fare-details {
      display: flex;
      width: 100%;
      max-width: 362px;
      gap: 20px;
      justify-content: space-between;
  }

  .labels-column {
      display: flex;
      flex-direction: column;
      align-items: start;
      font-size: 16px;
      color: rgba(34, 49, 63, 1);
      font-weight: 500;
      width: 100%;
  }

  .title {
      color: rgba(67, 67, 67, 1);
      font: 600 21px Poppins, sans-serif;
  }

  .fare-breakup-details {
      display: flex;
      justify-content: space-between;
      width: 100%;
  }

  .header-item {
      margin-top: 21px;
      font-weight: 500;
      color: rgba(34, 49, 63, 1);
  }

  .sub-item {
      color: rgba(152, 152, 152, 1);
      font-size: 15px;
      font-weight: 400;
      margin-top: 10px;
  }

  .amounts-column {
      align-self: end;
      display: flex;
      margin-top: 53px;
      flex-direction: column;
      align-items: start;
      font-size: 15px;
      color: rgba(152, 152, 152, 1);
      font-weight: 400;
  }

  .amount {
      margin-top: 12px;
  }

  .amount-header {
      color: rgba(34, 49, 63, 1);
      font-size: 16px;
      font-weight: 500;
  }

  .amount-discount {
      color: rgba(44, 157, 6, 1);
  }

  .divider {
      align-self: stretch;
      min-height: 1px;
      margin-top: 22px;
      width: 100%;
      border: 1px solid rgba(169, 169, 169, 1);
  }

  .total-row {
      display: flex;
      margin-top: 20px;
      width: 100%;
      max-width: 380px;
      gap: 20px;
      font-size: 20px;
      color: rgba(34, 49, 63, 1);
      font-weight: 700;
      justify-content: space-between;
  }

  .continue-button {
      border-radius: 8px;
      background-color: rgba(254, 111, 97, 1);
      margin-top: 38px;
      width: 100%;
      max-width: 343px;
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      text-align: center;
      padding: 15px 70px;
      border: none;
      cursor: pointer;
  }

  .continue-button:focus {
      outline: none;
  }