.flight-card {
  border-radius: 0;
  display: flex;
  max-width: 396px;
  flex-direction: column;
  margin-bottom: 13px;
}

.flight-card-content {
  border-radius: 15px;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 11px 55px 21px;
  border: 1px solid rgba(227, 227, 227, 1);
}

.flight-type {
  color: #22313f;
  text-align: center;
  align-self: center;
  font: 500 12px Inter, sans-serif;
}

.flight-info-container {
  align-self: center;
  display: flex;
  align-items: end;
  gap: 4px;
  justify-content: start;
}

.location-info {
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

.location-code {
  color: #22313f;
  font-size: 20px;
}

.location-city {
  color: #808991;
  font-size: 12px;
}

.flight-duration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 156px;
}

.flight-path {
  display: flex;
  max-width: 100%;
  width: 156px;
  align-items: center;
  gap: 2px;
  padding: 1px 16px;
}

.path-line {
  align-self: stretch;
  width: 45px;
  height: 1px;
  margin: auto 0;
  border: 1px dashed rgba(172, 172, 172, 1);
}

.flight-icon {
  aspect-ratio: 1.06;
  object-fit: contain;
  object-position: center;
  width: 18px;
  align-self: stretch;
}

.duration-text {
  color: #22313f;
  text-align: center;
  margin-top: 5px;
  font: 500 12px Inter, sans-serif;
}

.destination {
  align-items: end;
  text-align: right;
}

.time-container {
  align-self: start;
  display: flex;
  margin-top: 23px;
  align-items: start;
  gap: 40px 80px;
  font-family: Inter, sans-serif;
}

.time-block {
  display: flex;
  flex-direction: column;
}

.time {
  color: #22313f;
  font-size: 16px;
  font-weight: 500;
}

.date {
  color: #808991;
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
}

.arrival {
  align-items: end;
  text-align: right;
}

.airline-container {
  display: flex;
  margin-top: 28px;
  width: 100%;
  gap: 20px;
  font-family: Inter, sans-serif;
  justify-content: space-between;
}

.airline-details {
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 12px;
  color: #808991;
  font-weight: 400;
  margin: auto 0;
}

.airline-logo {
  aspect-ratio: 1.16;
  object-fit: contain;
  object-position: center;
  width: 29px;
  align-self: stretch;
  margin: auto 0;
}

.airline-name {
  align-self: stretch;
  margin: auto 0;
}

.aeroplane-price {
  border-radius: 10px;
  background-color: rgba(254, 111, 97, 1);
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  white-space: nowrap;
  text-align: right;
  padding: 10px 12px;
  display: flex;
  column-gap: 5px;
}