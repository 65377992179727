.container {
  border-radius: 0;
  width: 76rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.card {
  border-radius: 0.625rem;
  background-color: #fff;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0.5rem 0 0.9375rem;
}

.title {
  color: #181818;
  align-self: start;
  margin-left: 1.75rem;
  font: 600 1.375rem Poppins, sans-serif;
}

.divider {
  min-height: 0.0625rem;
  margin: 0.625rem 0;
  width: 100%;
  border: 0.0625rem solid #bbb;
}

.facilitiesGrid {
  align-self: end;
  padding: 1rem;
  width: 100%;
  max-width: 108.8125rem;
}

.facilitiesRow {
  gap: 1.25rem;
  display: flex;
}

.facilitySection {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  line-height: normal;
  width: 26%;
  margin-left: 0;
}

.sectionTitle {
  color: #181818;
  align-self: stretch;
  font: 600 1rem Poppins, sans-serif;
}
.hrfasscilty {
  background: #bbb;
  width: 90%;
  margin: 1rem auto;

  height: 2px;
}
.facilityItem {
  display: flex;
  margin-top: 1.875rem;
  gap: 0.125rem;
  align-items: center;
  color: #636363;
  font: 500 1rem Inter, sans-serif;
}

.facilityIcon {
  aspect-ratio: 1.32;
  object-fit: contain;
  object-position: center;
  width: 1rem;
}

.actionButtons {
  align-self: end;
  display: flex;
  margin-top: 2.0625rem;
  margin-right: 2rem;
  gap: 1.9375rem;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 700;
  flex-wrap: wrap;
}

.moreButton {
  border-radius: 0.5rem;
  background-color: #fe6f61;
  padding: 0.6875rem 1.0625rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.paymentButton {
  border-radius: 0.5rem;
  background-color: #55a61f;
  padding: 0.6875rem 1.25rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
}

@media (max-width: 61.9375rem) {
  .card {
    max-width: 100%;
    margin-top: 5rem;
    box-shadow: none;
  }

  .title {
    margin-left: 0.625rem;
    font-size: 4rem;
  }
  .divider {
    display: none;
  }
  .facilitiesRow {
    align-items: stretch;
    gap: 0;
  }

  .facilitySection {
    width: 100%;
  }
  .container {
    width: 96%;
  }
  .facilityIcon {
    width: 3rem;
  }
  .sectionTitle {
    font-size: 3rem;
    margin-left: 0.625rem;
  }
  .facilityItem {
    gap: 1rem;
    font-size: 3rem;
  }
  .moreButton {
    padding-right: 1.25rem;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.closeButton {
  position: absolute;
  top: 10px;
  color: #fe6f61;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 29px;
  cursor: pointer;
}
