.card {
  display: flex;
  flex-direction: column;
  color: rgba(40, 40, 40, 1);
  font: 600 1.4375rem Inter, sans-serif;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 4rem;
  object-position: center;
  align-self: center;
}

.text {
  margin-top: 1.25rem;
  font-size: 1rem;
  text-align: center;
  color: #5e5a5a;
  text-decoration: underline;
}

@media (max-width: 61.9375rem) {
  .card {
    margin-top: 2.5rem;
  }
}
