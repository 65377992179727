.passenger-form-container {
    border-radius: 0;
    display: flex;
    max-width: 389px;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-bottom: 30px;
}

.gender-selection {
    align-self: center;
    display: flex;
    width: 100%;
    max-width: 343px;
    align-items: start;
    gap: 16px;
    justify-content: start;
}

.gender-option {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #22313f;
    white-space: nowrap;
    justify-content: start;
    font: 500 14px Inter, sans-serif;
}

.gender-option label {
    display: flex;
    font-size: 14px;
    color: #0d1634;
    font-weight: 500;
    cursor: pointer;
    align-items: center;
    column-gap: 5px;
}

.gender-option label.selected span::after {
    background-color: #FE6F61;
}

.gender-option label span {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    border: solid 2px #FE6F61;
    position: relative;
}

.gender-option label span::after {
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
    background: transparent;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.gender-option label.selected span::after {
    background-color: #FE6F61;
}

.gender-option label span {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    border: solid 2px #FE6F61;
    position: relative;
}

.gender-option label span::after {
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
    background: transparent;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.input-field {
    align-items: start;
    border-radius: 8px;
    border: 1px solid rgba(13, 22, 52, 0.05);
    display: flex;
    margin-top: 12px;
    flex-direction: column;
    font-family: Inter, sans-serif;
    font-weight: 500;
    justify-content: center;
    padding: 12px 16px;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
}

.passenger-form-has-error {
    border: 1.5px solid red !important;
    animation: shake 0.2s ease-in-out 0s 5;
}

@keyframes shake {
    0% {
        margin-left: 0rem;
    }

    25% {
        margin-left: 0.5rem;
    }

    75% {
        margin-left: -0.5rem;
    }

    100% {
        margin-left: 0rem;
    }
}

.input-label {
    color: rgba(37, 40, 49, 0.5);
    font-size: 12px;
}

.input-value {
    color: #0d1634;
    font-size: 16px;
    margin-top: 8px;
}

.dual-field-container {
    display: flex;
    margin-top: 12px;
    gap: 20px;
    font-family: Inter, sans-serif;
    font-weight: 500;
}

.country-field {
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(13, 22, 52, 0.05);
    display: flex;
    margin-top: 15px;
    min-height: 64px;
    gap: 32px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    justify-content: start;
    padding: 12px 16px;
    width: 35%;
}

.phone-input-field {
    width: 65%;
}

.country-wrapper {
    align-self: stretch;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    flex: 1;
    flex-basis: 0%;
    margin: auto 0;
}

.country-select {
    display: flex;
    margin-top: 7px;
    width: 100%;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: #0d1634;
    white-space: nowrap;
    justify-content: start;
}

.country-flag {
    aspect-ratio: 1.69;
    object-fit: contain;
    object-position: center;
    width: 42%;
    border-radius: 2px;
    align-self: stretch;
    margin: auto 0;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.facility-info {
    display: flex;
    max-width: 385px;
    flex-direction: column;
    font-family: Inter, sans-serif;
    justify-content: start;
}

.facility-title {
    width: 343px;
    max-width: 100%;
    font-size: 16px;
    color: var(--Other-Blackest, #0d1634);
    font-weight: 600;
    white-space: nowrap;
}

.facility-item {
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Other-Boder, rgba(13, 22, 52, 0.05));
    display: flex;
    margin-top: 12px;
    min-height: 56px;
    width: 100%;
    gap: 16px;
    padding: 0 8px;
}

.facility-content {
    align-self: stretch;
    display: flex;
    min-width: 240px;
    flex-direction: column;
    font-weight: 500;
    justify-content: start;
    flex: 1;
    flex-basis: 0%;
    margin: auto 0;
}

.facility-name {
    color: var(--Other-Blackest, #0d1634);
    font-size: 14px;
}

.facility-description {
    color: var(--Neutral-color-NC40, rgba(37, 40, 49, 0.5));
    font-size: 12px;
    margin-top: 4px;
}

.facility-action {
    color: rgba(254, 111, 97, 1);
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    align-self: stretch;
    width: 19px;
    margin: auto 0;
}

.facility-icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    align-self: stretch;
    margin: auto 0;
}

.addPassengerDataBtn {
    border-radius: 15px;
    display: flex;
    padding: 14px 117px 15px 119px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FE6F61;
    color: #FFF;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0;
}