.centerlise-Testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  margin-top: 6.3931rem;
}

.parent-div-testimonials {
  margin-top: 1.5rem;
  width: 75rem;
  gap: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.testimonialsCard {
  width: 24.9908rem;
  height: 19.7742rem;
  flex-shrink: 0;
  border-radius: 3.375rem;
}

.TestimonialsImg {
  border-radius: 3.375rem;
}

.TestimonialsCardDiv {
  position: relative;
  width: 100%;
  height: 100%;
}

.Testimonials-review-details {
  margin-top: -5rem;
  margin-left: 2rem;
  position: absolute;
  width: 20.9908rem;
  height: 8.1897rem;
  flex-shrink: 0;
  border-radius: 0.875rem;
  background: #fff;
  box-shadow: 0rem 0.1875rem 0.1875rem 0rem rgba(0, 0, 0, 0.16);
}

.top-div-clent-details {
  margin-top: 0.875rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.client-name {
  flex-shrink: 0;
  color: #000;
  font-family: var(--font-family-poppins);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 1rem;
}

.journey-router-logo {
  width: 5.25rem;
  margin-right: 1rem;
}

.Client-comments {
  margin-top: 0.2rem;
  text-align: left;
  margin-left: 1rem;
  width: 19.4962rem;
  height: 2.4221rem;
  flex-shrink: 0;
  color: #000;
  font-family: var(--font-family-poppins);
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.destination-and-ratting {
  margin-left: 1rem;
  margin-top: 1rem;
  width: 87%;
  display: flex;
  justify-content: space-between;
}

.Destination {
  margin-left: 0.125rem;
  color: #000;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rattingStar {
  width: 1.1299rem;
  height: 1.0313rem;
  flex-shrink: 0;
}

.ratting-star-div {
  display: flex;
  flex-direction: row;
}

.destination-parent-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.location-icon {
  width: 0.5099rem;
  height: 0.8016rem;
  flex-shrink: 0;
}
.text1 {
  color: #FE6F61;
  font-family: Poppins;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text2 {
  color: #000;
  font-family: var(--font-family-sofia);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}
@media screen and (max-width: 769px) {
  .TestimonialsMobCardDiv {
    position: relative;
    margin: 0 auto;
    z-index: 2;
    width: 95%;
  }
  .ratting-star-div {
    display: flex;
    padding: 5px 0px;
    flex-direction: row;
  }
  .testimonialCarousel .slick-arrow {
    display: none !important;
  }
  .centerlise-Testimonials {
    display: block;
    overflow: none;
  }
  /* responsive design for carousel */
  .carousel-root {
    width: 100%;
    position: relative;
    top: 18px;
    padding: 0px 12px;
    height: 100%;
  }
  .carousel .control-dots {
    margin: 0 !important;
  }
  .TestimonialsCardDiv {
    position: relative;
    margin-bottom: 30rem;
    margin: 0 auto 30rem auto;
    scroll-snap-align: start;
    width: 79%;
    /* max-width: 345px; */
    height: 100%;
  }

  .carousel.carousel-slider {
    width: 100%;
    height: 104rem;
  }


  .testimonialsCard {
    position: relative;
    z-index: 9;
    width: 41rem;
    height: 31rem;
    overflow: hidden;
    flex-shrink: 0;
    border-radius: 7px;
}
  .testimonialCarousel .slick-slide {
    display: block;
    float: left;
    height: 48rem;
  }
  .testimonialsCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .destination-and-ratting {
    margin-left: 0rem;
    margin-top: 0rem;
    /* width: 98%; */
    display: flex;
    justify-content: space-between;
}
  .testimonailText {
    width: 56%;
    position: absolute;
    right: 0;
  }
  .client-name {
    font-family: var(--font-family-poppins);
    font-weight: 700;
    margin-left: 0rem;
    font-size: var(--para-font-size);
  }
  .Testimonials-review-details {
    margin-left: 5rem;
    width: 95%;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: -28rem;
    height: 100%;
  }
  .Client-comments {
    font-size: 2.4rem;
    font-weight: 500;
    padding-bottom: 5px;
    margin-left: 0rem;
    width: 100%;
    height: 100%;
    font-family: var(--font-family-poppins);
    text-align: left;
  }
  .journey-router-logo {
    width: 20.25rem !important;
    height: 3rem;
    margin-right: 1rem;
  }
  .Destination {
    font-size: var(--para-font-size);
    font-weight: 600;
    font-family: var(--font-family-poppins);
  }
 
  .rattingStar {
    width: 2rem !important;
    height: 2rem;
    flex-shrink: 0;
  }
  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    height: 100%;
    width: 100%;
    transition: height 0.15s ease-in;
  }
  .location-icon {
    width: 3rem !important;
    height: 3.8016rem;
    flex-shrink: 0;
  }
}
@media screen and (max-width: 548px) {
  .testimonailText {
    width: 52%;
    position: absolute;
    right: 0;
}
  .TestimonialsCardDiv {
    position: relative;
    margin: 0 auto 25rem auto;
    scroll-snap-align: start;
    width: 90%;
    height: 100%;
  }
}
.textimonialcarrousel {
  display: grid;
  padding-bottom: 230px;
  gap: 12px;
  grid-auto-flow: column;
  scroll-behavior: auto;
  overflow: visible;
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

@media screen and (max-width: 481px) {
  .TestimonialsMobCardDiv {
    position: relative;
    margin: 0 auto;
    z-index: 2;
    width: 95%;
  }
  .ratting-star-div {
    display: flex;
    padding: 5px 0px;
    flex-direction: row;
  }
  .testimonialCarousel .slick-arrow {
    display: none !important;
  }
  .centerlise-Testimonials {
    display: block;
    overflow: none;
  }
  /* responsive design for carousel */
  .carousel-root {
    width: 100%;
    position: relative;
    top: 18px;
    padding: 0px 12px;
    height: 100%;
  }
  .carousel .control-dots {
    margin: 0 !important;
  }
  .TestimonialsCardDiv {
    position: relative;
    margin-bottom: 30rem;
    margin: 0 auto 30rem auto;
    scroll-snap-align: start;
    width: 79%;
    /* max-width: 345px; */
    height: 100%;
  }

  .carousel.carousel-slider {
    width: 100%;
    height: 104rem;
  }


  .testimonialsCard {
    position: relative;
    z-index: 9;
    width: 41rem;
    height: 31rem;
    flex-shrink: 0;
    border-radius: 7px;
  }
  .testimonialCarousel .slick-slide {
    display: block;
    float: left;
    height: 40rem;
  }
  .testimonialsCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .destination-and-ratting {
    margin-left: 0rem;
    margin-top: 0rem;
    /* width: 98%; */
    display: flex;
    justify-content: space-between;
}
  .testimonailText {
    width: 56%;
    position: absolute;
    right: 0;
  }
  .client-name {
    font-family: var(--font-family-poppins);
    font-weight: 700;
    margin-left: 0rem;
    font-size: var(--para-font-size);
  }
  .Testimonials-review-details {
    margin-left: 5rem;
    width: 95%;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: -28rem;
    height: 100%;
  }
  .Client-comments {
    font-size: 2.4rem;
    font-weight: 500;
    padding-bottom: 5px;
    margin-left: 0rem;
    width: 100%;
    height: 100%;
    font-family: var(--font-family-poppins);
    text-align: left;
  }
  .journey-router-logo {
    width: 20.25rem !important;
    height: 3rem;
    margin-right: 1rem;
  }
  .Destination {
    font-size: var(--para-font-size);
    font-weight: 600;
    font-family: var(--font-family-poppins);
  }
 
  .rattingStar {
    width: 2rem !important;
    height: 2rem;
    flex-shrink: 0;
  }
  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    height: 100%;
    width: 100%;
    transition: height 0.15s ease-in;
  }
  .location-icon {
    width: 3rem !important;
    height: 3.8016rem;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 375px) {
  .TestimonialsMobCardDiv {
    position: relative;
    margin: 0 auto;
    z-index: 2;
    width: 95%;
  }
  .ratting-star-div {
    display: flex;
    padding: 2px 0px;
    flex-direction: row;
  }
  .testimonialCarousel .slick-arrow {
    display: none !important;
  }
  .centerlise-Testimonials {
    display: block;
    overflow: none;
  }
  /* responsive design for carousel */
  .carousel-root {
    width: 100%;
    position: relative;
    top: 18px;
    padding: 0px 12px;
    height: 100%;
  }
  .carousel .control-dots {
    margin: 0 !important;
  }
  .TestimonialsCardDiv {
    position: relative;
    margin-bottom: 30rem;
    margin: 0 auto 30rem auto;
    scroll-snap-align: start;
    width: 79%;
    /* max-width: 345px; */
    height: 100%;
  }

  .carousel.carousel-slider {
    width: 100%;
    height: 104rem;
  }


  .testimonialsCard {
    position: relative;
    z-index: 9;
    width: 33rem;
    height: 26rem;
    flex-shrink: 0;
    border-radius: 7px;
  }
  .testimonialCarousel .slick-slide {
    display: block;
    float: left;
    height: 40rem;
  }
  .testimonialsCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .destination-and-ratting {
    margin-left: 0rem;
    margin-top: 0rem;
    /* width: 98%; */
    display: flex;
    justify-content: space-between;
}
  .testimonailText {
    width: 56%;
    position: absolute;
    right: 0;
  }
  .client-name {
    font-family: var(--font-family-poppins);
    font-weight: 700;
    margin-left: 0rem;
    font-size: 2.7rem;
  }
  .Testimonials-review-details {
    margin-left: 5rem;
    width: 94%;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: -23rem;
    height: 100%;
  }
  .Client-comments {
    font-size: 2rem;
    font-weight: 500;
    padding-bottom: 5px;
    margin-left: 0rem;
    width: 100%;
    height: 100%;
    font-family: var(--font-family-poppins);
    text-align: left;
  }
  .journey-router-logo {
    width: 20.25rem !important;
    height: 3rem;
    margin-right: 1rem;
  }
  .Destination {
    font-size: 2.2rem;
    font-weight: 600;
    font-family: var(--font-family-poppins);
  }
 
  .rattingStar {
    width: 2rem !important;
    height: 2rem;
    flex-shrink: 0;
  }
  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    height: 100%;
    width: 100%;
    transition: height 0.15s ease-in;
  }
  .location-icon {
    width: 2rem !important;
    height: 2.8016rem;
    flex-shrink: 0;
  }
}