.hotelPage {
  background: rgba(255, 251, 251, 1);
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  padding: 8px 0 246px;
}
.applyButton{
  background-color: rgba(254, 111, 97, 1);
  color: white;
  padding: 2rem;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 15px;
  margin-top: 15rem;
  width:  34rem;
  right: 0;
  font-size: 4.5rem;
}
.header {
  display: flex;
  max-width: 392px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
}

.time {
  color: #000;
  font: 500 20px Poppins, sans-serif;
}

.statusIcons {
  display: flex;
  gap: 7px;
  align-items: center;
}

.statusIcon {
  object-fit: contain;
  object-position: center;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 8px 0;
  background: rgba(191, 191, 191, 1);
}

.mainContent {
}

.filterSection {
  margin-top: 20px;
}

.sectionTitle {
  color: #1e1e1e;
  font: 600 18px Inter, sans-serif;
  margin: 0 0 20px;
}

.tagContainer {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.priceSlider {
  display: flex;
  align-items: center;
  margin: 21px 0;
}

.sliderTrack {
  height: 4px;
  background: rgba(127, 127, 127, 1);
  flex: 1;
}

.sliderHandle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(254, 111, 97, 1);
}

.sliderProgress {
  height: 4px;
  background: rgba(254, 111, 97, 0.77);
  width: 73px;
}

.priceRange {
  color: #686868;
  font: 500 15px Inter, sans-serif;
  margin-left: 37px;
}
