.filterdiv {
  width: 99px;
  font-size: 4rem;
  height: 28px;
  flex-shrink: 0;
  color: #fe6f61;
  border-radius: 10px;
  border: 1px solid #fe6f61;
  text-align: center;
}
.filterdivwrpper {
  display: flex;
  gap: 2rem;
  margin: 2rem 3rem;
  overflow-x: scroll;
  scrollbar-width: none;
}
