.mobile-calendar-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 999;
  height: 100dvh;
  padding: 0 26px;
  padding-top: 30px;
  overflow: auto;
  scrollbar-width: none;
}

.mobile-calendar-container {
  display: flex;
  gap: 16px;
}

.mobile-calendar-container.horizontal {
  flex-direction: row;
}

.mobile-calendar-container.vertical {
  flex-direction: column;
}

.mobile-calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-weight: bold;
  margin-bottom: 8px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mobile-calendar-day-name {
  text-align: center;
}

.mobile-calendar-header {
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mobile-calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  place-items: center;
}

.mobile-calendar-grid.vertical {
  grid-template-columns: 1fr;
}

.mobile-calendar-date {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s;
  color: #163041;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mobile-calendar-date.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.mobile-calendar-date.selected {
  background-color: #f57c6c;
  color: #fff;
  font-weight: bold;
}

.mobile-calendar-navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.mobile-calendar-popup-actions {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}
