.content-div {
  margin: 6% 4%;
}
.content-div h1 {
  font-size: 1.8rem;
  color: #2d2f41;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.content-div h2 {
  color: #000;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 0;
}
.content-div p {
  color: #666;
  font-family: Poppins;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  font-weight: bolder;
}

.mainSupportpage {
  position: relative;
  height: 100%;
}

.supportheader {
  width: 100%;
  height: 45vh;
  overflow: hidden;
  background: url("../../Assets/SupportHeader.jpeg") no-repeat;
  object-fit: contain;
  background-size: cover;
  background-position: center 100%;
  filter: brightness(0.7);
}
.supportsection {
  width: 80%;
  min-height: 200vh; /* Ensures enough space for scrolling */
  margin: 0 auto;
  position: relative;
  top: -44px;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #000;
}

.supportstickysection {
  position: sticky;
  display: flex;
  justify-content: space-between;
  margin-top: 34px;
  top: 52px; /* Stick at the top */
  background: #dcdcdc;
  padding: 0 10px;
  /* z-index: 1000; Ensures it stays on top */
}

.supportstickysection p {
  cursor: pointer;
  padding: 12px 5px;
  font-size: 1.2rem;
  color: #484859;
  font-weight: bold;
  transition: color 0.3s ease;
}

.supportstickysection p.active {
  color: black;
  font-weight: bolder;
  border-bottom: 2px solid black;
  font-weight: bold;
}

.content-div li {
  list-style-type: disc; /* Default bullet style */
  font-size: 0.9rem;
  color: #666;
  font-family: Poppins;
  /* font-size: 19px; */
  font-style: normal;
  font-weight: 600;
  line-height: normal; /* Increase text size */
}

.content-div li::marker {
  font-size: 1rem; /* Makes bullet bigger */
  color: black; /* Optional: Change color */
}
@media screen and (max-width: 469px) {
  .content-div h2 {
    font-size: 3.2rem;
    font-weight: bold;
    padding: 1rem 0;
  }
  .content-div li {
    font-size: 2.5rem;
    font-weight: bold;
  }
  .content-div h1 {
    font-size: 4.5rem;
    padding-bottom: 2rem;
  }
  .content-div p {
    font-size: 2.5rem;
    color: #484859;
    font-weight: bolder;
  }
  .supportsection {
    width: 100%;
    top: 0;
    border: none;
  }
  .supportheader {
    height: 19vh;
  }
  .supportstickysection {
    margin-top: 0;
    overflow: scroll;
    scrollbar-width: none;
    top: 58px;
  }
  .supportstickysection p {
    text-wrap: nowrap;
    font-size: 2.9rem;
    padding: 12px 13px;
  }
}
