.flight-booking-containers {
    text-align: center;
    background-image: url("../../Assets/fights_header.png");
    background-size: cover;
    background-position: center;
    padding: 2rem;
  
}
.flight-heading {
  font-family: Poppins;
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-weight: bold;
  color: #fff;
  margin-bottom: 1.5rem;
}

.flight-booking-form {
  background: #fff;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: inline-block;
  text-align: left;

  
  
}
.flight-trip-type {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;

}
.flight-label {
  font-size: 0.9rem;
}
.flight-input {
  margin-right: 0.5rem;
}
.flight-form-fields {
    display: flex;
  flex-wrap: wrap;
  gap: 1rem;

}
.flight-input {
  flex: 1;
  min-width: 150px;
  padding: 0.5rem;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.flight-search-btn {
  margin-top: 1rem;
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #ff6f61;
  border: none;
  border-radius: 5px;
  cursor: pointer;

}
.flight-search-btn:hover {
  background-color: #ff4b3a;
}
  