/* Wrapper styles for calendar and buttons */
abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: none !important;
}
.react-calendar__navigation button:disabled {
  background-color: white !important;
}
.react-calendar__navigation__next2-button {
  display: none !important;
}
.react-calendar__navigation__prev2-button {
  display: none !important;
}
.custom-calendar-wrapper {
  position: absolute;

  transform: translateX(-50%);
  padding: 20px;
  border: 1px solid #ff6b6b;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 1000;
}

/* Calendar header styles */
.react-calendar {
  border: none;
  font-family: Arial, sans-serif;
  color: #333;
}

/* Calendar day styles */
.react-calendar__tile {
  height: 40px;
  font-size: 14px;
  color: #333;
  border-radius: 8px;
}

.react-calendar__tile--now {
  background-color: white !important;
  color: black !important;
}

.react-calendar__tile--active {
  background-color: #ff6b6b !important;
  color: white !important;
  border-radius: 8px;
}

.react-calendar__tile:hover {
  background-color: #ffbaba !important;
  color: white;
}

/* Buttons */
.calendar-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.calendar-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.cancel-button {
  background-color: #e0e0e0;
  color: #333;
}

.done-button {
  background-color: #ff6b6b;
  color: white;
}

@media screen and (max-width: 468px) {
  .custom-calendar-wrapper {
    transform: translateX(-5%) scale(0.7);
    padding: 20px;
    border: 1px solid #ff6b6b;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 1000;
    margin: 0 -49%;
  }
}
