.foodCardContainer{
    display: flex;
    align-items: center;
    gap: 3rem;
}
.foodCarMainImg{
    width: 20%;
  }
.foodCard {
    margin-bottom: 30px;
    width: 80%;
  }
  
  .cardContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .titlePrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .menuItemTitle {
    color: rgb(68, 68, 68);
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }
  
  .addButton {
    border-radius: 8px;
    background-color: rgb(254, 111, 97);
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 500;
    padding: 5px 20px;
    border: none;
    cursor: pointer;
  }
  
  .priceWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .currencyIcon {
    width: 21px;
    height: 28px;
  }
  
  .price {
    font-size: 28px;
    color: rgb(82, 82, 82);
    font-weight: 600;
  }
  .quantity{
  }
  .decbtn,
  .incbtn {
    width: 2rem;
    height: 2rem;
    margin: 0.4rem;
    text-align: center;
    border-radius: 0.3125rem;
    background: #d9d9d9;
  }
  
  @media (max-width: 991px) {
    .addButton {
      padding: 5px 20px;
    }
  }