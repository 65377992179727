@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --heading-font-size: 18px;
  --para-font-size: 14px;
  --font-family-poppins: Poppins;
  --font-family-lover: Honeyfont;
  --font-family-Quadric: Quadric;
  --font-family-sofia: Sofia;
  --font-family-niccone: Niccone;
  --font-family-merriweather: Merriweather;
}
@font-face {
  font-family: Poppins;
  src: url("./Fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: Quadric;
  src: url("./Fonts/qeraldin-gojvy.ttf");
}
@font-face {
  font-family: Niccone;
  src: url("./Fonts/Niconne-Regular.ttf");
}
@font-face {
  font-family: Honeyfont;
  src: url("./Fonts/LoversQuarrel-Regular.ttf");
}
@font-face {
  font-family: Sofia;
  src: url("./Fonts/SofiaPro.ttf");
}
@font-face {
  font-family: Merriweather;
  src: url("./Fonts/Merriweather-Regular.ttf");
}
@font-face {
  font-family: BebasNeue;
  src: url("./Fonts/BebasNeue-Regular.ttf");
}
@font-face {
  font-family: Tangerine-Regular;
  src: url("./Fonts/Tangerine-Regular.ttf");
}
@font-face {
  font-family: Tangerine-Bold;
  src: url("./Fonts/Tangerine-Bold.ttf");
}
@font-face {
  font-family: Stalemate;
  src: url("./Fonts/Stalemate-Regular.ttf");
}

body {
  margin: 0;
  font-family: var(--font-family-poppins);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.carrousel {
  padding: 60px 0px;
  display: grid;
  grid-auto-flow: column;
  scroll-behavior: auto;
  overflow: visible;
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}
