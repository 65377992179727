.pdf_centralise{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 36rem;
    background-color: #fff;
}
.Pdf_itineary-front_page{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36rem;
    flex-direction: column;
}
.Pdf_logo_div{
    display: flex;
    width: 36rem;
    margin: 0 20px;
    justify-content: flex-end;
}
.pdf_front_titles{
    margin: 2rem 0;
}
.Pdf_itineary-front_page h1{
    color: #DB1905;
    font-family: BebasNeue;
    font-size: 82px;
    text-transform: uppercase;
    line-height: 82px;
}
.Pdf_itineary-front_page h3{
    color: #000;
    font-family: Stalemate;
    text-align: right;
    margin-top: -1.75rem;
    font-size: 65px;
    line-height: 65px;
}

.Pdf_itineary_second_page{
    position: relative;
    padding-bottom: 40px;
    width: 36rem;
}
.contact_with_logo {
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: 96%;
    top: 10px;
    padding: 0 13px;
}
.contact_with_logo p{
    color: #FFF;
    font-family: Poppins;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
}
.itineary_top-section{
    position: relative;
    height: 260px;
}
.itineary_top-section img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.itineary_top_title{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.itineary_top_title .itineary_over_img_little_title{
    color: #FFF;
    text-align: center;
    font-family: Sofia;
    font-size: 16px;
    font-weight: 300;
    line-height: 15px;
    margin-left: 35px;
}
.itineary_top_title .itineary_over_img_big_title{
    color: #FFF;
    text-align: center;
    font-family: Tangerine-Bold;
    font-size: 50px;
    font-weight: 700;
    line-height: 20px;
}
.itineary_img_bottom{
    position: absolute;
    bottom: -1px;
    width: 100%;
}
.itineary_img_bottom img{
    /* filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.39)); */
    width: 100%;
}

.itineary_details_contnent .daywise_itineary_plane{
    position: relative;
}
.itineary_details_contnent{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.itineary_details_contnent .Day-wise-DayCard_activity_card{
    position: absolute;
    top: 0;
    width: 150px;
}
.itineary_details_contnent .left_day_wise_content{
    left: 38px;
}
.itineary_details_contnent .right_day_wise_content{
    right: 38px;
}
.Pdf_itineary_second_page .Day-wise-DayCard_activity_card {
    margin-left: 0;
}
.left_day_wise_content p{
    text-align: left;
    margin-left: 16px;
}
.right_day_wise_content p{
    text-align: right;
    margin-right: 16px;
}
.itineary_details_contnent .Day-wise-line-and-card {
    display: flex;
    position: relative;
    justify-content: center;
}

.itineary_details_contnent .Day-wise-counter {
    color: #000;
    padding: 0 10px 0 10px;
    box-shadow: 0rem 0.375rem 0.8125rem 0rem rgba(0, 0, 0, 0.21);
    border-radius: 6px;
    font-size: 20px;
}
.itineary_details_contnent .Day-wise-DayCard_activity_card {
    /* display: flex; */
    justify-content: center;
    align-items: center;
}
.itineary_details_contnent .Day-wise-DayCard_activity_card p{
    font-size: 18px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Limits the text to 1 line */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;
}
.itineary_details_contnent .Day-wise-line {
    border-left: 0.187rem dotted black;
    height: 20px;
    margin-right: 16px;
    border-spacing: 10px;
    margin-left: 16px;
}

.bottome_site_link{
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.bottome_site_link a{
    color: #000;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
}
.Pdf_itineary_third_page{
    position: relative;
    padding-bottom: 40px;
    width: 36rem;
}
.third_page_content{
    margin-top: 30px;
    padding: 0 15px;
}
.itineary_over_img_little_title{
    font-size: 20px;
}
.itineary_details_pdf{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.itineary_details_img{
    width: 35%;
}
.itineary_details_img img{
    border-radius: 47px 47px 0px 0px;
    background: lightgray 0px -19.498px / 102.516% 124.212% no-repeat;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.itineary_details_content{
    width: 60%;
}
.itineary_details_content h2{
    color: #000;
    font-family: Merriweather;
    font-size: 17px;
    line-height: 30px;
}
.itineary_details_content p{
    color: #000;
    font-size: 12px;
    margin-left: 16px;
    margin-bottom: 5px;
    position: relative;
}
.itineary_details_content p::before{
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #000000;
    position: absolute;
    left: -16px;
    top: 7px;
}
.inclusion_para{
    color: #000;
    font-size: 18px;
    position: relative;
}
.inclusion_para::before{
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #000000;
    position: absolute;
    left: -16px;
    top: 7px;
}
.Pdf_itineary_fourth_page{
    padding-top: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding-bottom: 40px;
    width: 36rem;
    padding: 75px 13px;
}
.Pdf_itineary_fourth_page .contact_with_logo p{
    color: #000000;
}
.pdf_inclusions_exclusions{
    padding-left: 32px;
}
.pdf_inclusions_exclusions h2{
    text-align: center;
    color: #1A4789;
    font-family: Merriweather;
    font-size: 25px;
    padding-right: 32px;
    margin-bottom: 16px;
}
.pdf_inclusions_exclusions span{
    color: #F00;
}
.pdf_payment_page h2{
    text-align: center;
    color: #1A4789;
    font-family: Merriweather;
    font-size: 25px;
    padding-right: 32px;
    margin-bottom: 16px;
}
.pdf_payment_page span{
    color: #F00;
}
.outer_payment_box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}
.pdf_payment_box{
    border-radius: 14px;
    border: 1px solid #000;
    background: #FFF;
    width: 65%;
    padding: 20px;
}
.pdf_payment_box p{
    font-size: 15px;
    font-weight: 600;
    line-height: 25px;
}
.pdf_payment_box p span{
    font-weight: 400;
}
.pdf_cancellation_policy{
    margin-left: 32px;
}
.pdf_cancellation_policy p{
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
}

.about_pdf_img {
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 550px; /* Adds depth to the 3D effect */
    margin-top: 50px;
    margin-bottom: 70px;
    padding: 0 20px;
  }
  
  .about_img_child {
    width: 140px;
    height: 170px; /* Fixed height for each child div */
    margin: 0 10px; /* Space between child divs */
    background-color: #3498db; /* Example color */
    border-radius: 12px; /* Slightly rounded corners for each child */
  }
  
  .about_pdf_img .about_img_child:nth-child(1) {
    transform: rotateY(35deg);
  }
  
  .about_pdf_img .about_img_child:nth-child(2) {
    transform: rotateY(0deg);
  }
  
  .about_pdf_img .about_img_child:nth-child(3) {
    transform: rotateY(0deg);
  }
  .about_pdf_img .about_img_child:nth-child(4) {
    transform: rotateY(-35deg);
  }
.about_pdf_content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
  .about_pdf_content span{
    color: #F00;
}
.about_pdf_content h2{
    text-align: center;
    color: #1A4789;
    font-family: Merriweather;
    font-size: 25px;
}
.about_pdf_title{
    font-family: Sofia;
    font-size: 16px;
    font-weight: 600;
}
.about_pdf_para{
    color: #000;
    width: 85%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
}
.download_btn_pdf{
    position: fixed;
    bottom: 20px;
    right: 32%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7.5rem;
    height: 3rem;
    border-radius: 0.6875rem;
    border: 2px solid #fe6f61;
    color: #fe6f61;
    font-size: 12.8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: #fff;
}
@media screen and (max-width:1024px) {
    .pdf_centralise{
        width: 750px;
    }
    .Pdf_itineary-front_page{
        width: 750px;
    }
    .Pdf_logo_div{
        width: 750px;
    }
    .Pdf_itineary_second_page{
        width: 750px;
    }
    .Pdf_itineary_fourth_page{
        width: 750px;
    }
      .Pdf_itineary_third_page{
        width: 750px;
      }
      .download_btn_pdf {
        bottom: 50px;
        right: 10%;
        width: 90px;
        height: 35px;
        border-radius: 5px;
    }
}
@media screen and (max-width:768px) {
    .pdf_centralise{
        width: 400px;
    }
    .Pdf_itineary-front_page{
        width: 400px;
    }
    .Pdf_logo_div{
        width: 400px;
    }
    .pdf_front_titles{
        margin: 32px 0;
    }
    .Pdf_itineary-front_page h1{
        font-size: 57px;
        line-height: 50px;
    }
    .Pdf_itineary-front_page h3{
        font-size: 45px;
        line-height: 30px;
    }
    
    .Pdf_itineary_second_page{
        width: 400px;
    }
    .itineary_details_contnent .Day-wise-DayCard_activity_card{
        width: 160px;
    }
    .itineary_over_img_little_title{
        font-size: 20px;
    }
    .itineary_details_img img{
        border-radius: 15px 15px 0px 0px;
    }
    
    .Pdf_itineary_fourth_page{
        width: 400px;
    }
      .about_img_child {
        width: 77px;
        height: 120px; /* Fixed height for each child div */
      }
      .Pdf_itineary_third_page{
        width: 400px;
      }
      .download_btn_pdf {
        bottom: 50px;
        right: 10%;
        width: 90px;
        height: 35px;
        border-radius: 5px;
    }
      
}
