.Best-selling-centerlise {
  display: flex;
  justify-content: center;
  margin-top: -4rem;
}
.bestSelling__inner {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}
.backgroundText {
  opacity: 0.08;
  font-family: var(--font-family-sofia);
  font-size: 6.2rem;
  margin-top: 1rem;
}

.titletext {
  font-family: var(--font-family-sofia);
  position: absolute;
  font-size: 2.75rem;
  top: 40%;
}
.Packages {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}
.bestsllingtag {
  position: absolute;
  left: 0;
  top: 15%;
}
.bestsllingtag svg {
  position: absolute;
  left: -5px;
  transform: rotate(0.5deg);
}

.bestsllingtag p {
  position: absolute;
  top: 10px;
  left: 7px;
  width: 12rem;
  font-size: 0.7rem;
  color: white;
}

.bestselling-card-parent {
  position: relative;
  width: 16.75rem;
  /* height: 32rem; */
  border-radius: 1.6875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bestselling-card-parent::before {
  content: "";
  background: #000000;
  opacity: 0.28;
  height: 19.775rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 1.4rem;
}

.bestselling-card-Img {
  width: 100%;
  height: 19.775rem;
  flex-shrink: 0;
  border-radius: 1.1875rem;
  /* opacity: 0.37; */
  background: #000;
}

.bestselling-card-details {
  position: absolute;
  width: 15.5625rem;
  height: 18rem;
  color: #fff;
  margin-top: 0.5rem;
}

.bestselling-card-cart-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.6347rem;
  height: 1.7067rem;
  border-radius: 4px;
  border: 2px solid #fff;
}

.bestselling-card-cart-days-div {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 3.6347rem; */
  /* height: 1.7067rem; */
  border-radius: 4px;
  border: 2px solid #ffffff;
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex-direction: column;
  padding: 0.3rem;
  row-gap: 0.2rem;
}

.bestselling-card-cart {
  width: 1.5539rem;
  height: 1.1614rem;
  flex-shrink: 0;
  fill: #fff;
}

.bestselling-card-details-row1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.bestselling_card_bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.bestselling-card-details-row2 {
  margin-top: 1.125rem;
  color: #fff;
  /* font-family: Poppins; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.bestselling-card-details-row3 {
  color: #fff;
  font-family: var(--font-family-merriweather);
  font-size: 1rem;

  font-style: normal;
  /* font-weight: 600; */
  line-height: normal;
  margin: 0 0 0.4rem 0.5rem;
}

.icon-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  /* font-family: Poppins; */
  font-size: 0.5625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.icons {
  width: 1.3719rem;
  height: 1.1521rem;
  flex-shrink: 0;
}

.bestselling-card-details-row4 {
  margin-top: 1rem;
  width: 48%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bestselling-card-details-row5 {
  display: flex;
  font-size: 1rem;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 0.4rem 0.5rem;
  font-family: var(--font-family-merriweather);
}

.bestselling-card-details-price {
  color: #fff;
  /* font-family: Poppins; */
  font-size: 1rem;
  font-style: normal;
  /* font-weight: 600; */
  line-height: 1rem;
}

.bestselling-card-details-per-person {
  color: #fff;
  /* font-family: Poppins; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  margin-left: 0.4rem;
  text-transform: lowercase;
}

.bestselling-card-details-button {
  /* color: #FFF; */
  width: 4.5605rem;
  height: 1.7068rem;
  flex-shrink: 0;
  background: #fe6f61;
  border-radius: 0.4375rem;
  border: none;
  /* filter: drop-shadow(0rem 0.1875rem 0.1875rem rgba(0, 0, 0, 0.16)); */
  color: #fff;
  /* font-family: Poppins; */
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.bestselling_Card_Inclusions {
  height: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bestselling_Card_Inclusions::before {
  content: "";
  background-color: #000000;
  position: absolute;
  border-radius: 0 0 1.3rem 1.3rem;
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 0.3;
}
.bestselling_Card_Inclusions_inner {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  font-size: 0.6rem;
  column-gap: 2rem;
  row-gap: 0.5rem;
  z-index: 0;
  position: inherit;
  padding: 0.4375rem 0 0.75rem 0;
}

.bestselling_Card_text_img {
  display: flex;
  color: #fff;
  align-items: center;
}
.bestselling_Card_text_img span {
  margin-left: 0.2rem;
}
img.bestselling_card_green_tick {
  width: 0.65rem;
}

@media screen and (max-width: 766px) {
  .bestselling-card-parent {
    width: 38.75rem;
    height: 45rem;
  }
  .bestsllingtag {
    position: absolute;
    left: 0;
    top: 9%;
  }
  .bestsllingtag svg {
    position: absolute;
    width: 70px;
    left: -5px;
    transform: rotate(0.5deg);
  }

  .explorebtns {
    color: white;
    border-radius: 6px;
    background: #fe6f61;
    position: relative;
    font-size: 10px;
    padding: 0.5rem 1rem;
    margin: 0.7rem;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
  }
  .bestselling_Card_Inclusions_inner {
    display: none;
  }
  .bestselling-card-Img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    flex-shrink: 0;
    border-radius: 1.1875rem;
    /* opacity: 0.37; */
    background: #000;
  }
  .bestselling-card-details-row3 {
    color: #fff;
    font-family: var(--font-family-merriweather);
    font-size: 2.4rem;
    font-style: normal;
    /* font-weight: 600; */
    line-height: normal;
    margin: 0 0 3.4rem 1.5rem;
  }
  .bestselling-card-details-price {
    color: #fff;
    /* font-family: Poppins; */
    font-size: 2rem;
    font-style: normal;
    /* font-weight: 600; */
    line-height: 1rem;
  }
  .bestselling-card-details-per-person {
    color: #fff;
    /* font-family: Poppins; */
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    margin-left: 0.4rem;
    text-transform: lowercase;
  }
  .bestselling-card-details {
    position: absolute;
    width: 35.5625rem;
    height: 39rem;
    color: #fff;
    margin-top: 0.5rem;
  }
  .bestselling-card-cart-days-div {
    font-size: 2rem;
  }
  .bestselling-card-details-row5 {
    display: flex;
    font-size: 1rem;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 1.4rem 1.5rem;
    font-family: var(--font-family-merriweather);
  }
  .bestsllingtag p {
    position: absolute;
    top: 13px;
    left: 7px;
    width: 12rem;
    font-size: 2rem;
    color: white;
  }
}

.explorebtns {
  color: white;
  border-radius: 6px;
  background: #fe6f61;
  position: relative;
  padding: 0.5rem 1rem;
  margin: 0.7rem;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
}

.Best-to-do-main-div {
  display: block;
  /* width: 72%; */
  width: 87%;
  margin: 0 auto;
  /* overflow-x: auto; */
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  gap: 16px;
  padding-bottom: 10px;
  justify-content: start;
}

.Best-to-do-main-div::before,
.Best-to-do-main-div::after {
  content: "";
  flex: 1;
}

.Best-to-do-main-div::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, and Edge */
}
.snapChild {
  display: block;
  gap: 16px; /* Adjust spacing between cards */
}

.ActivityLayout {
  width: 10rem;
  height: 12rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 28px;
  background: #fff;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.16);

  scroll-snap-align: start; /* Snap the activity items to the start of the container */
}
.snapChild .slick-next::before,
.snapChild .slick-prev::before {
  content: "";
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.snapChild .slick-next:before {
  background-image: url("../../Assets/chevronRight.svg");
}
.snapChild .slick-prev:before {
  background-image: url("../../Assets/chevronLeft.svg");
}
.Activity {
  width: 100%; /* Adjust the size of the image */
  height: 100%;
  object-fit: cover;
}

.limited-text {
  text-align: center;
}

.ss {
  min-width: 150px; /* Ensures every child has a minimum width */
}
@media screen and (max-width: 767px) {
  .Best-to-do-main-div {
    display: flex;
    overflow-x: auto;
  }
  .snapChild {
    display: flex;
  }
}

@media screen and (max-width: 481px) {
  .ActivityLayout {
    width: 38rem;
    height: 44rem;
  }
  .limited-text {
    text-align: center;
    font-size: 3.2rem;
  }
  .snapChild {
    display: flex;
    margin: 4rem -13px;
    gap: 16px;
  }
  .Best-to-do-main-div {
    display: flex;
    width: 97%;
  }
}

.BesttodoMainSection {
  display: flex;
  padding: 4rem 0rem 1rem 0rem;
  /* height: 71rem; */
  height: 100%;
  justify-content: center;
}
.BesttodoCardWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.Besttodocontents {
  height: 3rem;
  font-size: 2rem;
  width: 100%;
  bottom: 0;
  border-radius: 26px;
  padding: 0rem 1rem;
  background-color: white;
  position: absolute;
}

.Besttodocontents p {
  padding: 0.4rem 0;
  font-family: var(--font-family-sofia);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 115%; /* 44.85px */
  letter-spacing: -1.17px;
  text-transform: capitalize;
}
.BesttodocardDivs {
  height: 12rem;
  width: 16rem;
  margin-right: 1rem;

  margin: 2rem 2rem;

  border-radius: 26px;
  box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.11);
}
.BesttodoLayout {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 85rem;
  /* height: 326px; */
  height: 100%;
}
.Besttodocontents h2 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--font-family-poppins);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.88px;
}
.BesttodoLayout h1 {
  font-size: 4.75rem;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.88px;
}

.BesttodoCardWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  margin: 2rem auto;
  /* height: 24rem; */
  height: 100%;
  top: 8%;
  padding: 1rem 0.5rem;
  font-family: var(--font-family-poppins);
  gap: 1.4rem;
}

.BesttodoSection {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  margin: 0 auto;
  /* height: 24rem; */
  height: 100%;
  top: 8%;
  padding: 1rem 0.5rem;
  font-family: var(--font-family-poppins);
  gap: 1.4rem;
}
.BesttodoSection .slick-arrow.slick-prev,
.BesttodoSection .slick-arrow.slick-next {
  display: none !important;
}
.btn {
  width: 6rem;
  height: 1.8rem;
  flex-shrink: 0;
  color: #fff;
  font-family: var(--font-family-poppins);
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 3px;
  background: #fe6f61;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.btnwrapper {
  display: flex;
  padding: 0.5rem 0rem;
  justify-content: end;
}
/* .thingmgsec {
width: 16rem;
overflow: hidden;
left: 1rem;
top: -36px;
position: absolute;
height: 13rem;
flex-shrink: 0;
border-radius: 8px;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
} */
.thingmgsec {
  height: 11.5rem;
  width: 14rem;
  left: 1rem;

  overflow: hidden;
  top: -36px;
  position: absolute;
  flex-shrink: 0;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.BesttodoSection_Img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 468px) {
  .BesttodoLayout {
    width: 100%;
    height: 100%;
  }
  .BesttodoSection h1 {
    font-size: 4rem;
  }
  .BesttodoLayout h1 {
    font-size: 6.75rem;
  }
  .BesttodoMainSection {
    padding: 0;
    height: 100%;
  }
  .BesttodoSection p {
    font-size: 4rem;
  }
  .btnwrapper_mobile .btn {
    width: 12rem;
    height: 4rem;
    font-size: 1.5rem;
    border-radius: 3px;
    background: #fe6f61;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .budgetSection {
    width: auto;
    display: flex;
    justify-content: center;
  }
  .BesttodocardDiv {
    scroll-snap-align: start;
    width: 60rem;
    margin: 2rem 0rem;
    height: 74rem;
  }
  .BudgetLayout {
    width: 100%;
  }
  .BudgetMainSection {
    width: 100%;
    background-color: #fff;
  }

  .BesttodoCardWrapper {
    /* margin-top: -5%; */
    position: relative;
    gap: 2rem;
    height: 100rem;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    scroll-behavior: auto;
    overflow: visible;
    overflow-y: hidden;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
  }
  .FilterOuter {
    width: auto;
    display: flex;
    justify-content: center;
  }
  .BudgetLayout {
    box-shadow: none;
    border-radius: 0%;
    border: none;
  }
  .budgetSection_Img {
    width: 523px;
  }
  .Besttodocontents {
    padding: 0rem 3rem;
    border-radius: 26px;
    height: 7.5rem;
  }

  .Besttodocontents p {
    font-size: 3rem;
  }
  .Besttodocontents h2 {
    font-size: 2.9rem;
    padding: 1rem 0;
    font-weight: 400;
  }
  .Besttodocontents Span {
    font-size: 2rem;
  }
  /*  */
  .Besttodocontents h1 {
    font-size: 8rem !important;
  }
  .BesttodoSection {
    width: 100%;
    height: auto;
    top: 0;
    display: block;
  }
  .BesttodoSection p {
    font-family: Sofia;
  }
  .thingmgsec {
    width: 54rem;
    overflow: hidden;
    left: 3rem;
    top: -36px;
    position: absolute;
    height: 42rem;
    flex-shrink: 0;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .BesttodoLayout h1 {
    font-size: 10rem;
  }
  .BesttodocardDiv {
    scroll-snap-align: start;
    width: 60rem;
    top: 12rem;
    height: 70rem;
    border-radius: none;
  }

  .Besttodocardparent {
    width: 100%;
    height: 30rem;
    border-radius: 8px;
    overflow: hidden;
  }
  .BesttodoCardWrapper_mobile {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    width: 95%;
    height: auto;
  }
  .BesttodoSection_Img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .BesttodoCard_destinationName {
    position: absolute;
    width: 100%;
    display: flex;
    font-size: 4rem;
    align-items: center;
    justify-content: center;
    top: 0;
  }
  .btnwrapper_mobile {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

@media screen and (max-width: 469px) {
  .BesttodoMainSection {
    padding: 0rem 2rem;
  }
  .BesttodocardDiv {
    margin: 0;
  }
  .thingmgsec {
    height: 31.5rem;
    width: 35rem;
  }
  .BesttodocardDivs {
    height: 32rem;
    width: 40rem;
  }
}
@media screen and (max-width: 456px) {
  .BesttodoLayout h1 {
    font-weight: 600;
    font-family: var(--font-family-poppins);
  }
}
