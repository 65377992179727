.payment-option-wrapper {
    background-color: #E0E0E0;
    padding-top: 20px;
    padding-bottom: 37px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
}

.payment-options {
    font-family: Arial, sans-serif;
    width: 600px;
    border: 1px solid #000;
    background-color: #FFF;
    border-radius: 10px;
    padding: 20px 0;
    max-height: fit-content;
}

.payment-options h2 {
    color: #313131;
    font-family: Poppins;
    font-size: 32px;
    padding-left: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.tabs {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    margin-bottom: 20px;
    background-color: #F2F9FF;
}

.tab-payment {
    padding: 10px 15px;
    cursor: pointer;
    color: #22313F;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.tab-payment h6 {
    color: #767676;
    font-family: Inter, sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.tab-payment.active {
    background: rgba(217, 217, 217, 0.40);
}

.content {
    min-height: 200px;
}

.footer-payment {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    padding-top: 10px;
    padding: 0 20px;
}

.pay-button {
    background-color: #FE6F61;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.pay-button:hover {
    background-color: #ff5141;
}

.rightColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 30%;
}