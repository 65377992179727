.itineary-page-package-highlights-title {
  color: #020202;
  font-family: Amsterdam;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.itineary-page-package-highlights-title-statment {
  width: 37.368rem;
  color: #000;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.image-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1rem;
  padding: 1rem;
}

.image-item {
  background-color: rgba(255, 255, 255, 0.8);
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  /* padding: 1.25rem; */
  /* font-size: 30px; */
  text-align: center;
  overflow: hidden;
  border-radius: 1rem;
}

.image-item img {
  width: 13rem;
  height: 13rem;
  border-radius: 1rem;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
}

.image-item img:hover {
  cursor: pointer;
  transform: scale(1.2);
  border-radius: 2rem;
  transition: 0.5s all ease-in-out;
}

.unordered-grid {
  position: relative;
}

.unordered-grid-div1 {
  display: flex;
  margin-left: 5rem;
}

.unordered-grid-div2 {
  display: flex;
}

.unordered-grid-title {
  margin-left: 9rem;
  color: #020202;
  font-family: Sofia Pro;
  font-size: 2.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.unordered-grid-img1 {
  width: 20.3356rem;
  height: 13.5648rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
}

.unordered-grid-img1:hover {
  transform: scale(1.5);
}

.unordered-grid-img2:hover {
  transform: scale(1.5);
}

.unordered-grid-img3:hover {
  transform: scale(1.2);
}

.unordered-grid-img4:hover {
  transform: scale(1.5);
}

.unordered-grid-img5:hover {
  transform: scale(1.5);
}

.unordered-grid-img6:hover {
  transform: scale(1.5);
}

.unordered-grid-img2 {
  width: 12.5389rem;
  height: 15.6127rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
}

.unordered-grid-img3 {
  margin-top: -1.5rem;
  width: 12.5389rem;
  height: 24.6935rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
}

.unordered-grid-img4 {
  margin-top: -1.5rem;
  width: 12.5389rem;
  height: 13.5648rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
}

.unordered-grid-img5 {
  margin-left: 1rem;
  margin-top: 0.5rem;
  width: 20.5144rem;
  height: 11.7752rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
}

.unordered-grid-img6 {
  position: absolute;
  width: 26.2898rem;
  height: 11.589rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
  margin-top: -10rem;
  margin-left: 14rem;
}
.book-now-exclusion p {
  display: flex;
  font-size: 1.1rem;
  gap: 0.4rem;
  align-items: baseline;
  color: #625b5b;
}
.package-highlight {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 79rem;
}
.AllInclusionModal .book-now-inclusion {
  gap: 0.8rem;
}
.Book-now-card-main {
  margin-top: -6rem;
  width: 27rem;
  right: -2%;
  height: 42rem;
  position: relative;
  flex-shrink: 0;
  border-radius: 2.0625rem;
  background: #fff;
  box-shadow: -0.1875rem 0.375rem 0.5625rem 0rem rgba(0, 0, 0, 0.17),
    0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
}

.Book-now-card-row1 {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
}
.book-now-destination p {
  font-family: var(--font-family-poppins);
  font-weight: 600;
  font-size: 1.2rem;
}
.book-now-destination {
  margin-left: 1rem;
  display: flex;
  font-weight: bold;
  align-items: center;
  color: #000;
  font-family: var(--font-family-sofia);
  font-size: 1.6rem;
  font-style: normal;
  line-height: normal;
}

.book-now-location {
  width: 1.2rem;
  height: 1.2rem;
  flex-shrink: 0;
}

.book-now-clock {
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
}

.booknow-png {
  width: 9rem;
  height: 9rem;
  padding: 1rem;
}

.book-now-row1-details {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.book-now-inclusion-exclusion {
  width: 81%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2rem;
}
.hrbar {
  width: 1px;
  /* height: 100%; */
  margin: 1rem;
  background: rgba(0, 0, 0, 0.49);
}
.book-now-inclusion {
  margin-bottom: 1rem;
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  color: #000;
  flex: 1;
  /* font-family: Poppins; */
  font-size: 0.9rem;
  font-style: normal;
  gap: 0.3rem;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.0223rem;
  /* height: 21rem; */
}
.book-now-inclusion p {
  display: flex;
  gap: 0.4rem;
  align-items: center;
}
.book-now-inclusion h3,
.book-now-exclusion h3 {
  font-size: 2rem;
  font-weight: 600;
}
.book-now-exclusion {
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  color: #000;
  /* font-family: Poppins; */
  font-size: 0.9rem;
  font-style: normal;
  gap: 0.3rem;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.0223rem;
  /* height: 21rem; */
}
.book-now-exclusion span {
  display: flex;
  align-items: center;
}
.book-now-green-tick {
  width: 0.9915rem;
  height: 0.7394rem;
  margin-right: 0.3125rem;
}

.book-now-cross-tick {
  width: 1rem;
  height: 1rem;
  margin-right: 0.3125rem;
}

.complete-inclusion-loop-up {
  display: flex;
  justify-content: center;
}

.complete-inclusion-loop-up:hover {
  cursor: pointer;
}

.complete-inclusion-loop-up-button:active {
  cursor: pointer;
  background: #fe6f61;
  color: #fff;
}

.complete-inclusion-loop-up-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.3556rem;
  height: 1.7749rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  background: #fffdfd;
  box-shadow: 0rem 0.0625rem 0.0625rem 0rem rgba(0, 0, 0, 0.22);
  color: #fe6f61;
  font-family: Poppins;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.book-now-price {
  display: flex;
  justify-content: center;
}

.book-now-price- {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* width: 11rem; */
}

.book-now-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.book-now-amount-number {
  margin-left: 0.5rem;
  color: #000;
  /* font-family: Poppins; */
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.book-now-package-type {
  color: #000;
  /* font-family: Poppins; */
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.book-now-ul li {
  margin: 1rem 0rem;
}
.book-now-ul {
  color: #000;
  padding: 1rem 2rem;
  font-family: var(--font-family-poppins);
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.book-now-ul ul {
  list-style: disc;
}
.book-now-download-buttons-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-now-download-buttons-div {
  width: 25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.book-now-download-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.5rem;
  height: 3rem;
  border-radius: 0.6875rem;
  border: 2px solid #fe6f61;
  color: #fe6f61;
  /* font-family: Poppins; */
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.book-now-download-buttons:hover {
  cursor: pointer;
}

.book-now-download-buttons:active {
  cursor: pointer;
  color: #fff;
  background: #fe6f61;
}

.Book-now-button {
  display: flex;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  height: 5.4355rem;
  flex-shrink: 0;
  border-radius: 0rem 0rem 2.0625rem 2.0625rem;
  background: #fe6f61;
  box-shadow: 0.75rem 0.75rem 0.5rem 0rem rgba(0, 0, 0, 0.07);
  /* ///////////////////////// */
  color: #fff;
  /* font-family: Poppins; */
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Book-now-button:hover {
  cursor: pointer;
}

.Book-now-button:active {
  cursor: pointer;
  background: #db766d;
  color: #fe6f61;
}

.AllInclusionModal {
  display: flex;
  justify-content: center;
}

.AllInclusionModalParentDiv {
  display: flex;
  width: 57rem;
  padding: 2rem;
  flex-direction: row;
  justify-content: space-around;
  height: 35.7904rem;
  overflow-y: scroll; /* Make the content scrollable */
  font-family: var(--font-family-poppins);
  border-radius: 1.5625rem;
  background: #fff;
  box-shadow: 0.125rem 0.25rem 0.6875rem 0rem rgba(0, 0, 0, 0.25);
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
.AllInclusionModalParentDiv::-webkit-scrollbar {
  display: none; /* WebKit browsers */
}
.AlertSnackbar {
  background-color: green;
  color: #000;
}

.InputAreaerror {
  border-radius: 0.4375rem;
  border: 0.0625rem solid #000;
  width: 25.1696rem;
  height: 2.89rem;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 0.25rem solid red;
}

.query_submit_button {
  margin-top: 2rem;
  width: 14.9834rem;
  height: 3.4577rem;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0.75rem 0.75rem 0.5rem 0rem rgba(0, 0, 0, 0.16);
  color: #000;
  /* font-family: Poppins; */
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.query_submit_button:hover {
  cursor: pointer;
  background-color: #fe6f61;
  color: #020202;
}

@media screen and (max-width: 616px) {
  .AllInclusionModalParentDiv {
    display: flex;
    width: 98%;
    flex-direction: column;
    justify-content: start;
    height: 98vh;
  }
  .hrbar {
    width: 0;
    height: 0;
    margin-bottom: 5rem;
  }

  .Book-now-card-main {
    margin: 10rem auto;
    width: 58.7495rem;
    height: 100.2205rem;
    padding: 3rem;
    box-shadow: -0.1875rem 0.375rem 0.5625rem 0rem rgba(0, 0, 0, 0.17),
      0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
    position: relative;
  }
  .book-now-download-buttons-main {
    margin-top: 2.1438rem;
  }
  .book-now-row1-details {
    height: auto;
  }
  .book-now-destination {
    font-size: 18px;
  }
  .book-now-inclusion h3,
  .book-now-exclusion h3 {
    font-size: 3.2rem;
    font-weight: 800;
  }
  .Book-now-card-row1 {
    padding-bottom: 2rem;
  }
  .booknow-png {
    width: 17.5rem;
    height: 18.625rem;
    padding: 3rem;
    object-fit: cover;
  }

  .book-now-clock {
    width: 2.531rem;
    height: 2.531rem;
  }
  .book-now-green-tick {
    width: 2.9915rem;
    height: 2.7394rem;
  }
  .book-now-amount-number {
    font-size: 4.5rem;
  }
  .book-now-package-type {
    font-size: 2rem;
    font-weight: 600;
  }
  .book-now-location {
    width: 2.5rem;
    height: 5rem;
    position: relative;
    top: 0.2rem;
  }
  .book-now-inclusion-exclusion {
    width: 96%;
  }
  .book-now-inclusion,
  .book-now-exclusion {
    margin-bottom: 1rem;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    color: #000;
    flex: 0 0 auto;
    /* font-family: Poppins; */
    font-size: 2.3rem;
    font-style: normal;
    gap: 8px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.0223rem;
    /* height: 21rem; */
  }
  .book-now-inclusion-exclusion .book-now-exclusion {
    font-size: 10px;
  }
  .book-now-ul {
    padding: none;
  }
  .book-now-destination p {
    font-family: var(--font-family-poppins);
    font-weight: 600;
    font-size: 3.2rem;
  }
  .Book-now-button {
    margin-left: -5%;
    height: 8.4355rem;
    font-size: 4.25rem;
  }
  .inf p {
    font-size: 3rem;
    margin: 1rem 0rem;
    display: flex;
    align-items: center;
  }
  .inf h3 {
    font-size: 5rem;
    font-weight: bold;
  }
  .inf h3:last-child {
    margin-top: 4rem;
  }
  .complete-inclusion-loop-up-button {
    width: 15.3556rem;
    height: 4.7749rem;
    border-radius: 1.375rem;
    font-size: 2.8125rem;
  }
  .book-now-ul li {
    font-size: 2rem;
    /* margin-left: 5px; */
  }
  .book-now-download-buttons-div {
    width: 40rem;
  }
  .book-now-price {
    padding: 1rem 0rem;
  }
  .book-now-download-buttons {
    width: 16.3289rem;
    height: 5.094rem;
    font-size: 2rem;
    border: 1px solid #fe6f61;
  }
  .book-now-bottom {
    position: absolute;

    width: 100%;
  }
}

@media screen and (max-width: 469px) {
  .image-item {
    width: 95% !important;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    left: 3rem;
  }
  .image-item img {
    margin: 0 auto;
    width: 100%;
    height: 56rem;
    border-radius: 1rem;
    object-fit: cover;
    transition: 0.5s all ease-in-out;
  }
  .itenaryCarousel .slick-arrow {
    display: none !important;
  }
  .itenaryCarousel {
    margin: 7rem 1px;
  }
  .itineary-page-package-highlights-title {
    text-align: center;
    font-size: 6rem;
    font-weight: 800;
  }
  .itineary-page-package-highlights-title-statment {
    display: none;
  }
  .unordered-grid-title {
    display: none;
    text-align: center;
    color: #020202;
    font-family: cursive;
    font-size: 5.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
