.worldmapcenterlise {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 7.3931rem;
}

.quote {
  margin-left: 6.0625rem;
  margin-right: 6.0625rem;
  color: #202020;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.025rem;
}

.WorldmapImg {
  width: 73.5311rem;
  height: 43.2079rem;
}

.AllWorldMapPackage {
  /* margin-left: 7.5625rem; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 2rem;
}

.wordmapPackage {
  /* margin-right: 5.375rem; */
  width: 16.6875rem;
  height: 16.6875rem;
  flex-shrink: 0;
  border-radius: 1.375rem;
  border: 0.0625rem solid #707070;
  /* opacity: 0.4; */
  background: #0c0c0c;
}

.worldmapunicomponent {
  position: relative;
}

.worldmapunicomponent:hover {
  cursor: pointer;
}

.worldmappackageDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -16.5rem;
  position: absolute;
  width: 15.6875rem;
  height: 15.6875rem;
  flex-shrink: 0;
}

.packageTitle {
  margin-left: 0.6775rem;
  color: #fff;
  font-family: Poppins;
  font-size: 2.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0563rem;
  text-transform: capitalize;
}

.worldmapPackagedetailsdays {
  margin-left: 1rem;
  color: #fff;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0175rem;
  text-transform: uppercase;
}

.Sepratorbar {
  margin-left: 1rem;
  width: 13.3927rem;
  height: 0.4375rem;
  background: #fff;
}

.FROMRUPEES {
  margin-left: 1rem;
  color: #fff;
  font-family: Poppins;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0138rem;
  text-transform: uppercase;
}

.perPerson {
  margin-left: 1rem;
  color: #fff;
  font-family: Poppins;
  font-size: 0.5625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0138rem;
  text-transform: uppercase;
}

.fromrupeesperpersonDiv {
  margin-top: 0.625rem;
}

.worldmapPackagePrice {
  margin-left: 7rem;
  margin-top: -1.3rem;
  color: #fff;
  font-family: Poppins;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.0375rem;
}

.map-main-div {
  margin-bottom: 4rem;
  width: 79rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.Map-details1 {
  width: 100%;
  height: 100%;
  flex: 2;
}
.Map-details {
  color: #060606;
  flex: 8;
  /* font-family: Poppins; */
  font-size: 1.3125rem;
  font-style: normal;
  font-family: var(--font-family-poppins);
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0262rem;
}

.map-facts-main-div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.map-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  flex-shrink: 0;
}

.map-facts {
  width: 15.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #07476d;
  /* font-family: Poppins; */
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0213rem;
}

.map-facts-row {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.drop-icon {
  margin-right: 1rem;
  width: 1.0253rem;
  height: 1.4647rem;
  flex-shrink: 0;
}

.map-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7.3931rem;
  align-items: center;
}

.Itineary-page-map {
  margin-top: -10rem;
  width: 38.2041rem;
  margin: 0rem 0rem;
}
/* .Map-details1{
  width: ;
} */
.itnearymap-main {
  width: 79rem;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 500px) {
  .quote {
    color: #302f2f;
    text-align: justify !important;
    text-align: center;
    font-size: var(--para-font-size);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.14px;
  }
  .WorldmapImg {
    height: 60rem;
    width: 89.5311rem;
  }
  .worldmapunicomponent {
    scroll-snap-align: start;
  }
  .worldmapunicomponent {
    width: 198px;
    display: flex;
    height: 198px;
    flex-shrink: 0;
  }
  .wordmapPackage {
    width: 198px;
    height: 199px;
    filter: brightness(0.7);
    flex-shrink: 0;
  }
  .AllWorldMapPackage {
    display: grid;
    width: 95%;
    grid-auto-flow: column;
    scroll-behavior: auto;
    gap: 14px;
    overflow: visible;
    overflow-y: hidden;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
  }
  .map-main-div {
    flex-direction: column;
  }
  .map-img {
    width: 100%;
    height: 100%;
  }
  .FROMRUPEES {
    color: #fff;
    font-family: Poppins;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.14px;
  }
  .Map-details span {
    padding: 1rem 0rem;
  }
  .Map-details {
    padding: 12px 0;
    text-align: center;
    font-size: var(--para-font-size);
  }
  .fromrupeesperpersonDiv {
    padding: 12px 0px;
    display: flex;
    justify-content: space-between;
  }
  .worldmapPackagePrice {
    color: #fff;
    font-family: Poppins;
    font-size: 19px;
    margin-top: 0px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.28px;
  }
  .perPerson {
    color: #fff;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
  .Sepratorbar {
    margin-left: 1rem;
    width: 47.5rem;
    height: 1.4375rem;
    background: #fff;
  }
  .worldmappackageDetails {
    display: flex;
    font-size: 14px;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.5rem;
    /* padding: 0px; */
    position: absolute;
    width: 98%;
    height: 15.6875rem;
    flex-shrink: 0;
  }
  .worldmapPackagedetailsdays {
    color: #fff;
    font-family: Poppins;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
  }
  .map-facts-row {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .drop-icon {
    width: 2rem;
    height: 2.5rem;
  }
  .map-facts {
    width: 40.5rem;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    color: #07476d;
    /* font-family: Poppins; */
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    letter-spacing: 0.0213rem;
  }
  .packageTitle {
    color: #fff;
    font-family: "Source Sans Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
  }
}

@media screen and (max-width: 600px) {
  .Itineary-page-map {
    width: 90vw;
    height: auto;
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
}
