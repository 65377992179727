.ratingDiv {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 10px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s;
  background-color: white;
}

.ratingDiv svg {
  transition: transform 0.2s ease-in-out;
}

.ratingDiv:hover {
  background-color: #f7f7f7;
}

.ratingDiv.selected {
  background-color: rgba(254, 111, 97, 1);
  border-color: rgba(254, 111, 97, 1);
  color: white;
}

.ratingDiv.selected svg {
  transform: scale(1.2);
}

.applyButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.applyButton:hover {
  background-color: #45a049;
}
