.queryParentDiv {
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  width: 47.125rem;
  height: 38.5625rem;
  border-radius: 1.6875rem;
  background: #334b50;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(15px);
  background-image: url("../../Assets/SuuportImg/queryModalBackground.png");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.queryParentDiv h3 {
  font-size: 2rem;
  padding-top: 2rem;
}
.queryParentDiv p,
.queryParentDiv span {
  font-size: 1rem;
  padding: 0.3rem;
}
.queryParentDiv span {
  width: 69%;
}

.queryModalSetting {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactInput {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  width: 27.1696rem;
  height: 3.0076rem;
  border-radius: 0.4375rem;
  border: 0.0625rem solid #000;
  background: #fff;
  box-shadow: 0.75rem 0.75rem 0.5rem 0rem rgba(0, 0, 0, 0.16);
}

.InputArea {
  margin-top: 2rem;
  border-radius: 0.4375rem;
  border: 0.0625rem solid #000;
  background-color: white;
  width: 28.1696rem;
  height: 2.89rem;
  font-size: 1.3rem;
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: none;
}
.dest-depart{
  display: flex;
  width: 28.1696rem;
  justify-content: space-between;
}
.dest-depart input{
  width: 45%;
}
.queryParentDiv span {
  width: 84%;
}

.InputAreaerror {
  border-radius: 0.4375rem;
  border: 0.0625rem solid #000;
  width: 28.1696rem;
  height: 2.89rem;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 0.25rem solid red;
}

.query_submit_button {
  margin-top: 2rem;
  width: 14.9834rem;
  height: 3.4577rem;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0.75rem 0.75rem 0.5rem 0rem rgba(0, 0, 0, 0.16);
  color: #000;
  /* font-family: Poppins; */
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.query_submit_button:hover {
  cursor: pointer;
  background-color: #fe6f61;
  color: #020202;
}

@media screen and (max-width: 768px) {
  .queryParentDiv {
    width: 66.27rem;
    height: 72.75rem;
  }
  .InputArea {
    width: 44.1696rem;
    height: 2.89rem;
    color: black;
    font-size: 1.9rem;
  }
  .queryParentDiv h3 {
    font-size: 3.2rem;
    /* padding-top: 2rem; */
  }
  .queryParentDiv p,
  .queryParentDiv span {
    font-size: 1.8rem;
    padding: 0.8rem;
  }
  .query_submit_button {
    margin-top: 3rem;
    width: 15.9834rem;
    height: 4.4577rem;
    border-radius: 0.75rem;
    background: #fff;
    box-shadow: 0.75rem 0.75rem 0.5rem 0rem rgba(0, 0, 0, 0.16);
    color: #000;
    font-family: var(--font-family-poppins);
    font-size: 1.6rem;
  }
}
