.infoContainer {
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  margin: 28px 0 0 18px;
}

.titleRow {
  display: flex;
  gap: 2px;
  font-size: 16px;
  color: rgba(24, 24, 24, 1);
  font-weight: 500;
}

.icon {
  aspect-ratio: 1.14;
  object-fit: contain;
  object-position: center;
  width: 16px;
  margin: auto 0;
}

.title {
  flex-basis: auto;
}

.description {
  color: rgba(96, 96, 96, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  margin-top: 4px;
}

@media (max-width: 991px) {
  .infoContainer {
    margin-left: 10px;
  }
}
