.new_profilePage_outer{
  background: #EBEBEB;
  /* height: 100dvh; */
  padding-bottom: 1.625rem;
}
.new_interiorPara,
.new_AccFormWrapper p {
  color: #000;
  font-family: var(--font-family-poppins);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  position: static;
  width: 50%;
}
.new_interiorPara,
.new_AccFormWrapper span{
  width: 50%;
  display: block;
  text-align: left;
}
.new_AcountTabForm {
  /* height: 22rem; */
  width: 40.25rem;
  background-color: #f5f5f5;
  margin: 0 auto;
  border: 1px solid #FE6F61;
  border-radius: 10px;
  padding: 44px 60px;
}

.new_AcountTabForm::-webkit-scrollbar {
  width: 0px;
}
.new_AcountTabForm::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.new_AcountTabForm::-webkit-scrollbar-thumb {
  background: #888;
}
.new_AcountTabForm::-webkit-scrollbar-thumb:hover {
  background: #302f2f;
}
.new_operatableBtn {
  color: orangered;
  font-size: 1rem;
  font-weight: 600;
}
.new_AccFormWrapper {
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 12px 0 12px;
  border-top:1px solid #dcdcdc;
}
.new_AccFormWrapper:nth-child(1) {
  padding: 0 0 12px;
  border: none;
}
.new_profileCenter {
  width: 78rem;
  margin: 0 auto;
}
.new_profileTabs {
  display: flex;
  position: relative;
  gap: 4rem;
  /* top: 2rem; */
  margin-top: 2rem;
  flex-direction: row;
  width: 100%;
}
.new_profileDetails {
  display: flex;
  padding: 0 1.4rem;
  gap: 1rem;
  align-items: center;
  padding: 0 0 1.3125rem 1rem;
}
.new_ProfileAvatarName {
  font-size: 1.5rem;
  font-weight: bolder;
  display: flex;
  align-items: center;
}
.new_ProfileAvatar {
  position: relative;
  overflow: hidden;
}
.new_ProfileAvatar img{
  width: 100%;
  height: 100%; 
  object-fit: cover;
}
.new_ProfileAvatar p {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1.5rem;
  transform: translate(-50%, -50%);
}
.new_tabButtons {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 19.5rem;
  flex-shrink: 0;
  flex: 3;
  border-radius: 10px;
  background: #fff;
  transition: all 0.5s ease-in-out;
  padding: 0.9375rem 0.4375rem 0;
}
.new_renderTabsleft{
  left: 0;
}
.new_renderTabs{
  flex: 0;
  overflow: hidden;
}
.new_downloadPdfComporight{
  right: 0;
}
.new_downloadPdfCompo{
  flex: 0;
  overflow: hidden;
}
.new_tabButtons p {
  font-weight: bold;
}
.new_tabContent {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  flex: 9;
}


.new_Profileactive {
  color: #fe6f61;
}
.new_profilelinksOuter{
  border-top: 1.5px solid #dcdcdc;
  padding: 1.3125rem 0 1.3125rem 1rem;
  cursor: pointer;
}
.new_profileDetailsDivider {
  display: none;
}
.new_AuthBtn {
  border-radius: 0.95588rem;
  text-align: center;
  background: #fe6f61;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  width: 10.97059rem;
}
.new_profileMobback {
  display: none;
}
.new_accEditInput {
  padding: 0 1rem;
  border-width: 1px;
  width: 16rem;
  height: 3rem;
  border-radius: 3px;
}
@media screen and (max-width: 481px) {
  .new_profilePage_outer{
    background: #ffffff;
    height: 100dvh;
  }
  .new_tabfooterIng {
    display: flex;
    margin-bottom: -34rem;
    flex-direction: column;
  }
  .new_accEditInput {
    font-size: 3rem !important;
    padding: 0 2rem;
    border-width: 1px;
    width: 34rem;
    height: 8rem;
    border-radius: 3px;
  }
  .new_operatableBtn {
    font-size: 3rem;
  }
  .new_profileTabs {
    height: 100%;
    margin-top: 0;
  }
  .new_AccFormWrapper {
    justify-content: flex-start;
    margin: 9.9rem 0;
  }

  .new_profileCenter {
    width: 95%;
  }
  .new_tabContent {
    padding: 10px;
    flex: 1;
  }
}
@media screen and (max-width: 469px) {
  .new_AcountTabForm{
    height: 100%;
    overflow-y: hidden;
  }
  .new_AccFormWrapper span {
    font-size: 4rem;
    font-weight: bolder;
    color: #292525;
  }
  .new_AccFormWrapper p {
    font-size: 4.2rem;
  }
  .new_AcountTabForm {
    height: 100%;
    overflow-x: hidden;
  }
  .new_interiorPara,
  .new_AcountTabForm label {
    font-size: 4.2rem;
  }
  .new_interiorPara {
    line-height: normal;
    text-align: left;
    margin: 0;
  }
  .new_AuthBtn {
    border-radius: 0.95588rem;
    text-align: center;
    background: #fe6f61;
    font-size: 4rem;
    color: white;
    cursor: pointer;
    padding: 2.5rem;
    width: 27.97059rem;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    width: 32rem;
  }

  .MuiFormLabel-root.MuiInputLabel-root {
    width: 32rem;
    font-size: 5.8rem !important;
  }

  .new_profileMobback,
  .new_profileMobback2 {
    width: 100%;
    display: block;
    margin-top: 21.79rem;
  }
  .new_profileNavLinks {
    font-size: 4.5rem;
    margin-bottom: 2.25rem;
  }
  .new_profileDetailsDivider {
    width: 100%;
    display: block;
    height: 0.25rem;
    opacity: 0.25;
    margin-bottom: 5.14rem;
    background: rgba(112, 112, 112, 0.12);
  }
  .new_tab-buttons .new_Profileactive {
    all: unset;
  }
  .new_ProfileAvatarName {
    font-size: 5rem;
  }
  .new_profileDetails {
    margin: 0;
    gap: 4.9rem;
  }
  .new_ProfileAvatar {
    width: 10.585rem;
    height: 10.585rem;
  }
  .new_ProfileAvatar p {
    font-size: 4rem;
  }
  .new_tablsit {
    font-size: 4.5rem;
    margin: 0 2rem;
  }
  .new_tabButtons > a:first-child .new_tablsit {
    background-color: red;
  }

  .new_tabButtons {
    width: 100%;
    box-shadow: none;
  }
}
@media screen and (max-width: 768px){
  .new_AcountTabForm {
    padding: 12px 30px;
    overflow-y: auto;
}
  .new_AccFormWrapper {
    padding: 6px 0 6px;
}
}
/* animations for rght left */
/*
@keyframes slideLeft {
  0% {
    flex: 4;
  }
  100% {
    flex: 0;
  }
}

@keyframes slideRight {
  0% {
    flex: 0;
  }
  100% {
    right: 4;
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
*/

.new_profileContainer_mob {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  background-color: white;
  padding-bottom: 20px;
}

.new_profileHeader_mob {
  text-align: center;
  background-color: #fff;
  padding: 20px 0;
}

.new_profileImageContainer_mob {
  position: relative;
  text-align: center;
  background-color: #f9f9f9;
  padding: 20px;
  height: 247px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new_profileImageContainer_mob::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--background-image);
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  z-index: 0;
}
.user_profile_image_container_mob{
  z-index: 1;
  }
.new_profileImage_mob {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.new_editProfile_mob {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(30px);
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.new_profileName_mob {
  margin-top: 15px;
  font-size: 18px;
  font-weight: bold;
}

.new_tabButtons_mob {
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  padding-top: 55px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  z-index: 5;
  position: relative;
  background-color: #fff;
}

.new_profilelinksOuter_mob {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: white;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
}

.new_profileNavLinks_mob {
  font-size: 16px;
  color: #333;
}

.menu-arrow_mob {
  font-size: 18px;
  color: #ff5a5f;
}

.new_Profileactive_mob .new_profileNavLinks_mob {
  color: #ff5a5f;
}

@media (max-width: 480px) {
  .new_profilePage_outer{
    background-color: #fff;
  }
  .new_profileContainer_mob {
    padding: 10px;
  }

  .new_profileImage_mob {
    width: 120px;
    height: 120px;
  }

  .new_profileName_mob {
    font-size: 24px;
    margin-top: 0;
  }

  .new_profilelinksOuter_mob {
    padding: 10px 15px;
  }

  .new_profileNavLinks_mob {
    font-size: 24px;
  }
  .new_chevron_right_mob{
    color: #FE6F61;
  }

  .menu-arrow_mob {
    font-size: 16px;
  }
}

