.filterButton {
  border-radius: 10px;
  display: flex;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
  padding: 6px 8px;
  border: 1px solid rgba(254, 111, 97, 1);
  color: rgba(127, 127, 127, 1);
  font: 15px Roboto, sans-serif;
}

.icon {
  aspect-ratio: 0.86;
  object-fit: contain;
  object-position: center;
  width: 12px;
  align-self: start;
}
