.new_AcountTabForm_mob {
    margin-top: -50px;
    display: flex;
    flex-direction: column;
    padding-top: 90px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    z-index: 5;
    position: relative;
    background-color: #fff;
  }
/* 
  .new_AcountTabForm_mob {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
  
  .new_AccFormWrapper_mob {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    position: relative;
  }
  
  .new_accEditInput_mob {
    display: flex;
    flex: 1;
    padding: 10px 15px;
    border: 2px solid #fe6f61;
    border-radius: 5px;
    font-size: 1.25rem;
    color: #999;
    outline: none;
    margin-bottom: 23px;
  }
  .new_Input_mob{
    width: 100%;
  }
  .new_Input_mob p{
    font-size: 5rem;
    color: #2A2828;
    margin-bottom: 7px;
  }
  .new_Input_mob input{
    display: flex;
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #fe6f61;
    border-radius: 5px;
    font-size: 5rem;
    color: #999999;
    outline: none;
  }
  .new_AccFormWrapper_mob:last-child{
    margin-bottom: 30px;
  }
  
  .new_accEditInput_mob:focus {
    border-color: #fe6f61;
  }
  
  .new_accEditInput_mob input{
    flex: 1;
    width: 100%;
  }
  .new_AccFormWrapper_mob svg {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
  
  .new_AcountTabForm_mob input[type="date"] {
    color: #333;
    background-color: transparent;
  }

  .new_AcountTabForm_mob .button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .new_acc_actions_button{
    height: 11rem;
    width: 40%;
    font-size: 5.75rem;
    border-radius: 13px;
    background: #FE6F61;
    color: #fff;
  }
  .vouchrs_n_bookings{
    width: 100%;
    background-color: #fff;
    padding: 20px;
    flex: 9;
    border-radius: 10px;
  }
  
  @media screen and (max-width: 600px) {
    .new_AccFormWrapper_mob {
      margin-bottom: 10px;
    }
  
    .new_accEditInput_mob {
      padding: 8px 12px;
      font-size: 5rem;
    }

  }
  