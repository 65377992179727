
.filterTag {
  padding: 8px 12px;
  margin: 4px;
  border: 1px solid #ccc;
  border-radius: 16px;
  cursor: pointer;
  text-decoration:underline;
  border-radius: 10px;
  background-color: rgba(254, 111, 97, 0.12);
  color: rgba(254, 111, 97, 1);
  font: 400 2.9rem "Open Sans", sans-serif;
  white-space: nowrap;
  user-select: none;
  transition: all 0.2s;
}

.filterTag.selected {
  background-color: #ff6b6b;
  color: white;
  border: 1px solid #ff6b6b;
}



.applyButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.applyButton:hover {
  background-color: #45a049;
}
