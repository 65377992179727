.outerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flightWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80vh;
  overflow: auto;
  scrollbar-width: none;
  width: 80%;
}

.iconflFront {
  background-image: url("../../../../../../Assets/flightHeader.svg");
  background-size: cover;
  width: 100%;
}

.flightSeatMatrix {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  width: 100%;
  margin-top: -23px;
}

.seatRow {
  display: flex;
}

.seatCol {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.8px;
}

.seatBlock {
  height: 50px;
  width: 48px;
  border-radius: 2px;
  text-align: center;
  background-color: transparent;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.seatBlock span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.seatToolTip {
  position: absolute;
  top: -30px;
  padding: 10px;
  background: #FE6961;
  color: #fff;
  border-radius: 5px;
}

.seatToolTip::after {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #FE6961;
  display: block;
  position: absolute;
  right: 32%;
  transform: rotate(45deg);
  bottom: -7px;
  border-radius: 5px;
}

.noReclineExitSeat {
  border: 2px dashed #000;
}

.seatEmptyLabel {
  background: transparent;
  color: #fff;
  width: 47px;
  height: 50px;
  display: inline-block;
  color: #000;
}

.seatEmpty {
  opacity: 0.5;
}