.container {
    font-family: Inter, sans-serif;
    position: fixed;
    top: 0;
    z-index: 999;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
  }
  .crossBtn{
    position: fixed;
    top: 20px;
    right: 30px;
    cursor: pointer;
    font-size: 20px;
    color: black;
    font-weight: bold;
  }
  .tabHeader {
    display: flex;
    justify-content: space-around;
    background-color: #f4f4f4;
    padding: 10px;
  }
  
  .tab {
    background: none;
    border: none;
    font-weight: bold;
    font-size: 18px;
    padding: 10px 20px;
    cursor: pointer;
    color: gray;
  }
  
  .activeTab {
    font-weight: bold;
    color: #FE6F61;
    border-bottom: 2px solid #FE6F61;
    font-size: 18px;
    padding: 10px 20px;
    cursor: pointer;
  }
  