.galleryContainer {
  border-radius: 0.625rem;
  width: 77rem;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.galleryGrid {
  display: flex;
  width: 100%;
  height: 27rem;
}
.mainImageColumn1 {
  width: 100%;
  height: 100%;
}
.mainImageColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 80%;
}

.thumbnailColumn {
  display: flex;

  flex-direction: column;
  line-height: normal;
  width: 60%;
}

.thumbnailGrid {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  flex-direction: column;
  padding: 0rem 0.6rem;
}
.thumbImageColumn1 {
  width: 18rem;
  height: 12.9rem;
}
.thumbnailRow {
  gap: 0.5rem;
  display: flex;
}

.galleryImage {
  width: 20rem;
  border-radius: 0.625rem;
  object-fit: contain;
  object-position: center;
}

.mainImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bottomThumbnailImage {
  aspect-ratio: 1.56;
}
