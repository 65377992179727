.payment-option {
    display: flex;
    width: 100%;
    max-width: 369px;
    align-items: start;
    gap: 40px 46px;
}

.option-content {
    display: flex;
    gap: 40px 42px;
    width: 100%;
}

.option-icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 35px;
}

.option-details {
    display: flex;
    margin-top: 5px;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
}

.option-header {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.option-title {
    color: rgba(34, 49, 63, 1);
    font-size: 16px;
    font-weight: 500;
}

.arrow-icon {
    aspect-ratio: 0.58;
    object-fit: contain;
    object-position: center;
    width: 7px;
    align-self: center;
}

.option-description {
    color: rgba(118, 118, 118, 1);
    font-size: 10px;
    font-weight: 400;
}