.container {
  display: flex;
  flex-direction: column;
}

.wrapper {
  background: #fff;
  width: 100%;
  padding: 1.9375rem 4.375rem 1.4375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 100%;
  max-width: 99.8125rem;
}

.featureGrid {
  display: flex;
  gap: 1.25rem;
}

.featureSection {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.featureColumn {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  justify-content: center;
  gap: 2rem;
  font-family: Inter, sans-serif;
  color: #636363;
  font-weight: 500;
}

.featureItem {
  display: flex;
  gap: 1.125rem;
  align-items: center;
}

.featureIcon {
  width: 2.25rem;
  height: 3.25rem;
  object-fit: contain;
}

.featureText {
  font-size: 1.2rem;
}

@media (max-width: 61.9375rem) {
  .wrapper {
    max-width: 100%;
    padding: 0 1.25rem;
  }
  .featureText {
    font-size: 3rem;
  }
  .featureColumn {
    box-shadow: none;
  }
  .featureIcon {
    width: 4.25rem;
    height: 4.25rem;
    object-fit: contain;
  }
  .content {
    max-width: 100%;
  }

  .featureGrid {
    flex-direction: column;
    gap: 0;
  }

  .featureSection {
    width: 100%;
    margin-top: 2.5rem;
  }

  .featureItem {
    margin-left: 0.5rem;
  }
}
