.ForgotWrappermain {
  border-radius: 2.5625rem;
  background: #fff;
  width: 28.625rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin: 0 auto; */
  height: 37.1875rem;
  flex-shrink: 0;
}
.btnwrap{
  display: flex;
  flex-direction: column;
  top: 5rem;
  position: relative;
}
.ForgotWrappermain .LoginWrapper {
  border-radius: 2.5625rem;
  background: #fff;
  width: 28.625rem;
  display: flex;
  margin: 0 auto;
  height: 30.1875rem;
  flex-shrink: 0;
}
.ForgotWrappermain .loginBtn {
  position: absolute;
  left: 7rem;
}
.ForgotWrappermain .LoginMainTitle {
  text-align: center;
  z-index: 9;
  font-weight: bold;
  position: relative;
  color: #000;
  font-family: var(--font-family-sofia);
  font-size: 1.5rem;
  top: 4rem;
}
.btnwrap button {
  padding: -0.5rem 0;
  padding: 1px;
  color: #fe6f61;
  color: #fe6f61;
  position: relative;
  top: 1rem;
}
.ForgotWrappermain .LoginForm {
  width: 100%;
  padding: 0 3rem;
  position: relative;
  top: 0rem;
  height: 24rem;
  display: flex;
  padding-top: 2rem;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}
.LoginWrappermain {
  border-radius: 2.5625rem;
  background: #fff;
  width: 28.625rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin: 0 auto; */
  height: 39.1875rem;
  flex-shrink: 0; 
}
.LoginWrapper {
  border-radius: 2.5625rem;
  background: #fff;
  width: 28.625rem;
  display: flex;
  margin: 0 auto;
  height: 33.1875rem;
  flex-shrink: 0;
}

.loginBtn {
  cursor: pointer;
  position: static;
margin: 0 auto;
  position: static;
margin: 0 auto;
  bottom: 3rem;
  border-radius: 0.875rem;
  background: #fe6f61;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.13);
  width: 15.8125rem;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.25rem;
  flex-shrink: 0;
}
.loginBtn2{
  cursor: pointer;
  position: absolute;
  left: 7rem;
  bottom: 1.5rem;
  border-radius: 0.875rem;
  background: #fe6f61;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.13);
  width: 15.8125rem;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.25rem;
  flex-shrink: 0;
}
.LoginForm input {
  width: 100%;
  padding: 1.2rem;
  font-size: 1rem;
}
.loginformdiv {
  width: 100%;
  border-bottom: 1px solid #fe6f61;
}
.LoginMainTitle {
  text-align: center;
  z-index: 9;
  font-weight: bold;
  position: relative;
  color: #000;
  font-family: var(--font-family-sofia);
  font-size: 1.5rem;
  top: 3rem;
}
.loginlogo {
  width: 8rem;
  position: relative;
  top: 2rem;
  left: 2rem;
  z-index: 9;
}
/* .LoginForm {
  width: 100%;
  position: relative;
  top: 3rem;

  height: 12rem;
  display: flex;
  padding-top: 2rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
} */

.closeModalBtn{
  display: none;
}
.LoginForm {
  width: 100%;
  position: relative;
  top: 3rem;
  height: 22rem;
  display: flex;
  padding: 2rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}


@media screen and (max-width:481px) {
  
  .ForgotWrappermain {
    border-radius: 2.5625rem;
    background: #fff;
    width:100%;
    margin: 0 auto;
    height: 100%;
    flex-shrink: 0;
}
  .btnwrap{
    display: flex;
    flex-direction: column;
    top: -22rem;
    position: relative;
  }
  .ForgotWrappermain .LoginWrapper {
    border-radius: 2.5625rem;
    background: #fff;
    width: 100%;
    display: flex;
    margin: 0 auto;
    height: 100%;
    flex-shrink: 0;
}
.closeModalBtn {
  color: black;
  display: block;
  position: absolute;
  z-index: 9;
  right: 0;
  padding: 5rem;
}
  .ForgotWrappermain .loginBtn {
    position: static;
   margin: 0 auto;
  }
  .ForgotWrappermain .LoginMainTitle {
    text-align: center;
    z-index: 9;
    font-weight: bold;
    position: relative;
    color: #000;
    font-family: var(--font-family-sofia);
    font-size: 4.5rem;
    top: 4rem;
  }
  .btnwrap button {
    padding: -0.5rem 0;
    padding: 1px;
    font-size: 3.5rem;
    color: #fe6f61;
    position: relative;
    top: 2rem;
}

  .ForgotWrappermain .LoginForm {
    width: 100%;
    padding: 0 8rem;
    position: relative;
    top: 8rem;
    height: 41%;
    display: flex;
    padding-top: 2rem;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8rem;
}
.LoginWrappermain {
  border-radius: 2.5625rem;
  background: #fff;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  flex-shrink: 0;
}
.LoginWrapper {
  border-radius: 2.5625rem;
  background: #fff;
  width: 90%;
  display: flex;
  margin: 0 auto;
  height: 100%;
  flex-shrink: 0;
}
.loginBtn {
  cursor: pointer;
  position: absolute;
  left: 37rem;
  margin: 0 auto;
  bottom: 17rem;
  font-size: 4rem;
  border-radius: 1.875rem;
  background: #fe6f61;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.13);
  width: 31.8125rem;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9.25rem;
  flex-shrink: 0;
}
.errmsgforgotpassword{
  font-size: 3rem;
}
.loginBtn2 {
  cursor: pointer;
  position: static;
  margin: 0 auto;
  bottom: 8rem;
  font-size: 4rem;
  border-radius: 1.875rem;
  background: #fe6f61;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.13);
  width: 38.8125rem;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11.25rem;
  flex-shrink: 0;
}
  .LoginForm input {
    width: 100%;
    padding: 2.2rem;
    font-size: 5rem;
}
  .loginformdiv {
    width: 100%;
    border-bottom: 1px solid #fe6f61;
  }
  .LoginMainTitle {
    text-align: center;
    z-index: 9;
    font-weight: bold;
    position: relative;
    color: #000;
    font-family: var(--font-family-sofia);
    font-size: 4.5rem;
    top: 3rem;
  }
  .loginlogo {
    width: 24rem;
    position: relative;
    padding-bottom: 3rem;
    top: 2rem;
    padding-top: 6rem;
   margin: 0 auto;
    z-index: 9;
}
  /* .LoginForm {
    width: 100%;
    position: relative;
    top: 3rem;
  
    height: 12rem;
    display: flex;
    padding-top: 2rem;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  } */
  
  
  .LoginForm {
    width: 100%;
    padding: 0 4rem;
    position: relative;
    top: 8rem;
    height: 54%;
    display: flex;
    padding-top: 2rem;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8rem;
  }
  
}
@media screen and (max-width:376px) {
  .loginBtn2 {
  position: static;
  margin: 0 auto;
  }
}