.centerlise-Footer {
    justify-content: space-around;
    margin-top: 7.3931rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Footer-img {
    width: 100%;
    /* height: 26rem; */
}

.detailsFooter {
    background: black;
    margin-top: -1rem;
    padding-bottom: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
}

.deatilsInner {
    width: 79rem;
    padding-top: 3rem;
}

.JRLOGO {
    padding-top: 4rem;
    padding-bottom: 2rem;
    margin-left: 5rem;
    width: 24.4375rem;
}

.footer-paralel-div {
    display: flex;
    flex-direction: row;
    color: #fff;
    justify-content: space-around;
    margin-left: 2.8rem;
}

.footer-paralel-div h2>img {
    display: none;
}

.footer-location-icon {
    width: 0.7962rem;
    height: 1.1414rem;
    flex-shrink: 0;
}

.footerDetailsView {
    transition: 0.5s;
    margin-top: 3rem;
    border-right: 1px solid #707070;
    opacity: 0.72;
}

.footerDetailsView>div {
    cursor: pointer;
}

.footerDetailsViewLast {
    margin-top: 3rem;
}

.footerDetailsViewLast>div {
    cursor: pointer;
}

.footerSeasonally {
    margin-top: 3rem;
    display: flex;
    justify-content: space-around;
    border-right: 1px solid #707070;
    opacity: 0.72;
}

.footerSeasonally>div {
    cursor: pointer;
}

.footer-packagetypetext {
    margin-left: 0.4rem;
    color: #FAFAFA;
    font-family: Poppins;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.025rem;
}

.email-details-collector {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2.5rem;

}

.emaillogo {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    margin-right: 0.5rem;
}

.footer-bottom-parent-div {
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-bottom-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 1.375rem;
}

.footer-bottom-icon:hover {
    cursor: pointer;
}

.footer-bottom-holder {
    margin-left: 6rem;
    margin-top: 3rem;
}

.last-title {
    width: 35rem;
}

.subscribe-parent-div {
    width: 25.0625rem;
    height: 3.7668rem;
    flex-shrink: 0;
    border-radius: 0.75rem;

    box-shadow: 0.75rem 0.75rem 0.5rem 0rem rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

input.addemial {
    width: 53%;
    flex-shrink: 0;
    color: #FFF;
    /* font-family: Poppins; */
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #000;
    height: 90%;
    border: none;
}

input:focus-visible {
    outline: none;
}

.subscribe-button {
    width: 9.1456rem;
    height: 2.58rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    background: #FE6F61;
    color: #FFF;
    /* font-family: Poppins; */
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media screen and (max-width:768px) {
    .deatilsInner {
        width: 90vw;
        padding-top: 5rem;
    }

    .subscribe-holder {
        margin-left: 5rem;
        margin-top: 3rem;
    }

    .email-title {
        width: 35vw;
        font-size: 2rem;
        color: #fff;
    }

    .subscribe-parent-div {
        font-size: 1.75rem;
        width: 45.0625rem;
        height: 8.7668rem;
        justify-content: space-between;
        padding: 1rem;
        margin-top: 1.25rem;
    }

    input.addemial {
        width: 67%;
        font-size: 2.5rem;
        padding-left: 1rem;
        border: 1px solid #707070;
        border-radius: 3px;
    }

    .subscribe-button {
        width: 16.1456rem;
        height: 6rem;
        font-size: 2.5rem;
    }

    .footer-paralel-div {
        flex-direction: column;
        margin-left: 5rem;
        row-gap: 3rem;
        margin-top: 3rem;
    }

    .footer-paralel-div h2 {
        font-size: 2rem;
        padding-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #707070;
    }

    .footer-paralel-div h2>img {
        display: block;
    }

    h2.activeHeader {
        border-bottom: none;
    }

    h2.activeHeader img {
        transform: rotate(180deg);
    }

    .footerDetailsView {
        visibility: hidden;
        max-height: 0;
        opacity: 0;
        margin-top: 0;
        border-right: unset;
        width: 100% !important;
        transition: 0.6s all ease-in-out;
    }

    .footerDetailsView img {
        width: 3rem;
        height: auto;
    }

    .footerDetailsView span {
        font-size: 2rem;
        margin-left: 2rem;
    }

    .footerSeasonally {
        max-height: 0;
        visibility: hidden;
        opacity: 0;
        margin-top: 0;
        border-right: unset;
        width: 100% !important;
        transition: 0.6s all ease-in-out;
        justify-content: flex-start;
        gap: 40%;
    }

    .footerSeasonally img {
        width: 3rem;
        height: auto;
    }

    .footerSeasonally span {
        font-size: 2rem;
        margin-left: 2rem;
    }

    .footerDetailsViewLast {
        visibility: hidden;
        max-height: 0;
        opacity: 0;
        margin-top: 0;
        border-right: unset;
        width: 100% !important;
        transition: 1s all ease-in-out;
    }

    .footerDetailsViewLast img {
        width: 3rem;
        height: auto;
    }

    .footerDetailsViewLast span {
        font-size: 2rem;
        margin-left: 2rem;
    }

    .active {
        visibility: visible;
        max-height: 500px;
        opacity: 1;
        border-bottom: 2px solid #707070;
        transition: 1s all ease-in-out;
    }

    .footer-bottom-holder h3 {
        font-size: 2.5rem;
    }

    .footer-bottom-holder>div {
        display: flex;
        row-gap: 1rem;
    }

    .footer-bottom-icon {
        width: 3rem;
        height: auto;
        margin-right: 1.375rem;
    }

    .footer-bottom-holder>div span {
        font-size: 2rem;
    }
}