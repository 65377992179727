.container {
  transform: scale(0.8);
  width: 60rem;
  background-color: white;
  border-radius: 15px;
}

.cardWrapper {
  gap: 1.25rem;
  display: flex;
}

@media (max-width: 61.9375rem) {
  .cardWrapper {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
}

.imageColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 58%;
  margin-left: 0;
}

@media (max-width: 61.9375rem) {
  .imageColumn {
    width: 100%;
  }
}

.hotelImage {
  aspect-ratio: 1.72;
  object-position: center;
  width: 100%;
  border-radius: 0.625rem 0 0 0.625rem;
  flex-grow: 1;
}

@media (max-width: 61.9375rem) {
  .hotelImage {
    max-width: 100%;
    margin-top: 2.3125rem;
  }
}

.contentColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 45%;
}

@media (max-width: 61.9375rem) {
  .contentColumn {
    width: 100%;
  }
}

.contentWrapper {
  display: flex;
  width: 32rem;
  transform: scale(0.9);

  flex-direction: column;
  align-self: stretch;
  align-items: start;
  margin: auto 0;
}

@media (max-width: 61.9375rem) {
  .contentWrapper {
    max-width: 100%;
    margin-top: 2.5rem;
  }
}

.hotelName {
  color: rgb(10, 10, 10);
  font: 600 1.875rem Poppins, sans-serif;
}

.tagContainer {
  align-self: stretch;
  display: flex;
  margin-top: 3.375rem;
  gap: 1.0625rem;
  color: rgb(94, 94, 94);
  font: 500 1.25rem Poppins, sans-serif;
}

@media (max-width: 61.9375rem) {
  .tagContainer {
    max-width: 100%;
    margin-top: 2.5rem;
  }
}

.tag {
  border-radius: 0.3125rem;
  background-color: rgb(233, 217, 217);
  padding: 0.3125rem 1.0625rem;
}

@media (max-width: 61.9375rem) {
  .tag {
    padding-right: 1.25rem;
  }
}

.priceSection {
  display: flex;
  width: 16.4375rem;
  max-width: 100%;
  flex-direction: column;
  margin: 3.1875rem 0 0 3.375rem;
}

@media (max-width: 61.9375rem) {
  .priceSection {
    margin: 2.5rem 0 0 0.625rem;
  }
}

.priceWrapper {
  align-self: center;
  display: flex;
  width: 13.125rem;
  max-width: 100%;
  gap: 0.25rem;
  color: rgb(0, 0, 0);
  font: 700 2.5rem Inter, sans-serif;
}

.currencyIcon {
  aspect-ratio: 0.77;
  object-fit: contain;
  object-position: center;
  width: 1.5rem;
  margin: auto 0;
}

.priceText {
  flex-grow: 1;
  width: 11.0625rem;
}

.priceSubtext {
  font-size: 1.25rem;
  color: rgb(135, 135, 135);
}

.bookButton {
  border-radius: 0.625rem;
  background-color: rgb(254, 111, 97);
  margin-top: 1.375rem;
  color: rgb(255, 255, 255);
  padding: 0.5625rem 1.625rem;
  font: 600 1.875rem Poppins, sans-serif;
  border: none;
  cursor: pointer;
}

@media (max-width: 61.9375rem) {
  .bookButton {
    padding: 0 1.25rem;
  }
}
