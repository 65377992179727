.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ff5e5e !important;
}

.custom-date-picker .MuiOutlinedInput-root:hover .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #ff5e5e !important;
}

.MuiInputBase-input.MuiOutlinedInput-input{
  padding: 12.5px 14px !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiInputLabel-outlined {
  position: relative !important;
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  -webkit-transform: translate(14px, -9px) scale(0.75) !important;
  -moz-transform: translate(14px, -9px) scale(0.75) !important;
  -ms-transform: translate(14px, -9px) scale(0.75) !important;
  transform: translate(14px, -9px) scale(0.75) !important;
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms !important;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd{
  box-sizing: content-box !important;
}
.custom-date-picker .MuiOutlinedInput-root {
  transition: border-color 0.2s ease-in-out;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd{
  border-right: none;
  border-radius: 5px 0 0 5px;
}
@media screen and (max-width:768px) {
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd{
    padding-right: unset !important;
  }
  .MuiFormLabel-root.MuiInputLabel-root{
    font-size: 3.2rem !important;
  }
  
  /* .MuiFormLabel-root.MuiInputLabel-root {
    color: #ff5e5e2B; 
    transform: translate(0, 50%) scale(1) !important;
    text-align: center;
    left: 25% !important;
    transform: translateX(-50%) translateY(75%) !important;
  } */
  
  .custom-date-picker .MuiInputBase-root {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px; /* Adjust height as needed */
  }

  /* Increase the size of the input and label */
  .custom-date-picker {
    font-size: 1.8rem; /* Adjust as needed */
  }
  
  .custom-date-picker .MuiInputBase-root {
    font-size: 1.8rem; /* Adjust font size for the input field */
  }
  
  .custom-date-picker .MuiInputLabel-root {
    font-size: 1.8rem; /* Adjust font size for the label */
  }
  
  /* Increase the size of the calendar days */
  .MuiPickersDay-root {
    font-size: 2.8rem !important; /* Increase font size for day buttons */
    width: 40px !important; /* Increase button size */
    height: 40px !important; /* Increase button size */
  }
  /* .MuiFormLabel-root.MuiInputLabel-root{
    font-size: 3.5rem !important;
  } */
  .MuiTypography-root.MuiTypography-overline{
    font-family: var(--font-family-poppins) !important;
    font-size: 3.2rem;
  }
  .MuiTypography-root.MuiDatePickerToolbar-title {
    margin: 0;
    font-family: var(--font-family-poppins) !important;
    font-size: 4.125rem !important;
  }
  
  .MuiPickersCalendarHeader-label {
    font-size: 2.8rem;
    margin-right: 6px;
  }
  .MuiSvgIcon-root.MuiPickersCalendarHeader-switchViewIcon{
    width: 5rem !important;
    height: 5rem !important;
  }
  
  .MuiPickersYear-yearButton {
    font-size: 4rem !important;
    font-family: var(--font-family-poppins) !important;
  }
  /* Increase the size of the calendar header (month and year) */
  .MuiPickersCalendarHeader-root,
  .MuiPickersCalendarHeader-switchHeader .MuiTypography-root {
    font-size: 1.8rem !important; /* Increase font size for month and year */
    font-family: var(--font-family-poppins) !important;
  }
  
  /* next month and and previous month icon css */
  .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button, .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button{
    font-size: 5.5rem !important;
    font-family: var(--font-family-poppins) !important;
  }
  /* Increase the size of the day names (S, M, T, W, T, F, S) */
  .MuiTypography-root.MuiDayCalendar-weekDayLabel {
    font-size: 2.75rem !important; /* Increase font size for day labels */
    font-family: var(--font-family-poppins) !important;
  }
  
  /* Increase the size of the toolbar (date at the top) */
  .MuiPickersToolbarText-toolbarBtnSelected,
  .MuiPickersToolbarText-toolbarTxt {
    font-size: 2rem !important; /* Increase font size for the selected date */
  }
  
  /* Increase the size of the action buttons (CANCEL, OK) */
  .MuiDialogActions-root .MuiButton-root {
    font-size: 2.8rem !important; /* Increase font size for action buttons */
    padding: 8px 24px !important; /* Increase padding for buttons */
    color: #FE6F61;
  }
  .plan_trip_form_group .MuiFormLabel-root.MuiInputLabel-root input:focus + label,.plan_trip_form_group .MuiFormLabel-root.MuiInputLabel-root input:not(:placeholder-shown) + label {
    font-size: 14px !important;
    color: #ff5e5e2B;
    text-align: center;
    left: 25% !important;
    transform: translateX(-50%) translateY(0) !important;
}
}

.MuiButtonBase-root.MuiPickersDay-root:focus, .MuiButtonBase-root.MuiPickersDay-root:focus {
  background-color: #FE6F612B !important;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected:hover {
  will-change: background-color;
  background-color: #FE6F61 !important;
}
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected, .MuiButtonBase-root.MuiPickersDay-root:focus.Mui-selected{
  background-color: #FE6F61 !important;
}


