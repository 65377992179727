/* General Styles */
.HeroBanner {
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 200;
  font-size: 10px;
  color: white;
}

.overridecentalise {
  padding-top: 7.3931rem;
  margin-top: 0;
}

.Logo {
  width: 5rem;
}

.profileIcon {
  height: 1rem;
}

.instagram {
  width: 1.7rem;
  height: 1.7rem;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("../../Fonts/SofiaPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.InfluencerParentDiv {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.influercardHeader {
  font-family: Sofia Pro;
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 3.8rem;
  letter-spacing: 0.03em;
  text-align: center;
}

.cardcontainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Header {
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.headerIconDiv {
  width: 7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btmcenterlise {
  background: rgba(255, 247, 247, 0.63);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-around;
}

@font-face {
  font-family: "Amsterdam";
  src: url("../../Fonts/Amsterdam.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.yourSearchEndsHere {
  font-family: Amsterdam;
  font-size: 3.3rem;
  font-weight: 400;
  line-height: 13rem;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.Profile {
  width: 2rem;
  height: 2rem;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeaderIcon, .HeaderIcon1 {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.HeaderIcon {
  font-size: 0.7rem;
}

.HeaderIcon1 {
  font-size: 0.6rem;
}

.HeaderIcon:hover, .HeaderIcon1:hover {
  cursor: pointer;
}

.herobannerfooterMainDiv {
  display: flex;
  justify-content: center;
  margin-top: -2rem;
}

.herobannerfooter {
  margin-top: -9rem;
  display: flex;
  width: 60%;
  height: 3.5rem;
  background: #ffffff;
  border-radius: 24px;
  justify-content: space-around;
  align-items: center;
  box-shadow: 12px 12px 16px #00000029;
}

.sticky-navbar {
  position: fixed;
  top: 0;
  height: 3.8rem;
  background-color: #d3d3d37d;
  backdrop-filter: blur(6px);
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.SearchNavBar {
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: center;
  height: 66%;
}

.searchbar, .searchbar2 {
  width: 26rem;
  padding: 0 2%;
  display: flex;
  flex-direction: row-reverse;
  height: 2.2rem;
  background: #ffffff;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
}

.searchIcon {
  width: 1rem;
  position: relative;
  right: 0.5rem;
}

.SearchPlacholder {
  font-size: 0.7rem;
  color: #929292;
  margin-right: 2rem;
}

/* Influencer Card */
.greencheck {
  width: 1.2rem;
  height: 1rem;
}

.InfluencerName {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.8rem;
}

.influercard {
  margin-right: 3.0625rem;
  display: flex;
  flex-direction: column;
  width: 11.62rem;
  height: 14.62rem;
  box-shadow: 0.75rem 0.75rem 1rem #00000029;
  border-radius: 1.75rem;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
}

.influercard:hover {
  transform: scale(1.1) rotate(12deg);
  background-color: #000;
  color: #ffffff;
}

.influencerImg {
  width: 6.9rem;
  height: 6.9rem;
  border-radius: 50%;
  margin-top: 1rem;
  margin-bottom: 0.375rem;
  border: 3px solid #e68e9d;
}

.influercardHeader {
  display: flex;
  justify-content: center;
  font-size: 2.8rem;
}

.video-container {
  position: relative;
  width: 100%;
  height: 35rem;
  overflow: hidden;
}

#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
}

.DestinationModal {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  border: none;
}

.DestinationMapperModal {
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 56rem;
  height: 25rem;
  background-color: #fffffff0;
  border: none;
}

.Destination_trigger {
  margin-bottom: 0.125rem;
  color: #000;
  font-size: 1.125rem;
  font-weight: 600;
}

.Destination_trigger:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.DomesticDestination {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}

/* Responsive Styles */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .sticky-navbar {
    height: 5.8rem;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .sticky-navbar {
    height: 13rem;
  }
  .searchbar2 {
    font-size: 4rem;
    flex-direction: row;
    justify-content: flex-start;
    width: 46rem;
    height: 6rem;
  }
  .searchIcon {
    width: 3.3rem;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 1000px) {
  .yourSearchEndsHere {
    font-size: 4.125rem;
  }
}

@media screen and (max-width: 768px) {
  .btmcenterlise {
    background: #fff7f7;
  }
  .yourSearchEndsHere {
    font-size: 7.125rem;
  }
  .video-container {
    height: 61rem;
  }
  .searchbar {
    font-size: 4rem;
    flex-direction: row;
    justify-content: flex-start;
    width: 56rem;
    height: 6rem;
    top: 8rem;
  }
  .herobannerfooter {
    height: 5.5rem;
    border-radius: 4px;
  }
  .SearchPlacholder {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 638px) and (max-width: 637px) {
  .herobannerfooter {
    width: 98%;
  }
  .SearchPlacholder {
    font-size: 4rem;
  }
}

@media only screen and (min-width: 300px) and (max-width: 480px) {
  .sticky-navbar {
    height: 14.8rem;
  }
  .SearchPlacholder {
    font-size: 3rem;
  }
  .searchIcon {
    width: 3.3rem;
    margin-left: 1rem;
  }
  .yourSearchEndsHere {
    font-size: 5.4rem;
    padding: 12px;
  }
  .searchbar, .searchbar2 {
    font-size: 4rem;
    flex-direction: row;
    justify-content: flex-start;
    width: 46rem;
    height: 6rem;
  }
}

@media only screen and (min-width: 468px) and (max-width: 637px) {
  .SearchPlacholder {
    font-size: 3rem;
  }
  .SearchNavBar {
    height: 18rem;
  }
  .herobannerfooter {
    width: 98%;
  }
}
