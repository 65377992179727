.container {
  display: flex;
  gap: 16px;
  margin-top: 3rem;
  font-size: 12px;
  color: rgba(254, 111, 97, 1);
}

.dateContainer {
  border-radius: 10px;
  background-color: rgba(254, 111, 97, 0.12);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 13px;
}

.calendarIcon {
  aspect-ratio: 0.94;
  object-fit: contain;
  object-position: center;
  width: 15px;
}

.guestCount {
  border-radius: 10px;
  background-color: rgba(254, 111, 97, 0.12);
  padding: 7px 28px 13px;
}
