.servicesCard {
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  flex-direction: column;
  padding: 0.5rem 0 1.8125rem;
  border: 0.0625rem solid rgba(153, 153, 153, 1);
}

.title {
  color: rgba(49, 49, 49, 1);
  align-self: start;
  margin-left: 1rem;
  font: 600 1.5rem Poppins, sans-serif;
}

.divider {
  margin-top: 0.5625rem;
  width: 100%;
  height: 0.0625rem;
  border: 0.0625rem solid rgba(159, 159, 159, 1);
}

.servicesList {
  display: flex;
  margin-top: 0.8125rem;
  width: 100%;
  flex-direction: column;
  align-items: start;
  color: rgba(99, 99, 99, 1);
  padding: 0 0.5rem 0 1.25rem;
  font: 400 1rem Inter, sans-serif;
}

.serviceBullet {
  background-color: rgba(77, 77, 77, 1);
  border-radius: 50%;
  width: 0.5625rem;
  height: 0.5625rem;
  margin: auto 0;
}

.serviceItem {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  margin-bottom: 0.625rem;
}

.moreButton {
  border-radius: 0.5rem;
  background-color: rgba(254, 111, 97, 1);
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  padding: 0.4375rem 1.875rem;
  border: none;
  cursor: pointer;
  align-self: flex-end;
  margin-top: 0.8125rem;
}
