.container {
  border-radius: 0;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  height: fit-content;
  flex-direction: column;
  color: rgba(99, 99, 99, 1);
  font: 600 1.375rem Inter, sans-serif;
}

.formWrapper {
  border-radius: 0.625rem;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 1.875rem 2.5625rem 4rem;
}

.title {
  color: #313131;
  font: 600 1.375rem Poppins, sans-serif;
  margin: 0;
}

.roomInfo {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1.15rem;
}

.roomNumber {
  font-weight: 700;
  color: #181818;
}

.nameFields {
  display: flex;
  margin-top: 1.1rem;
  width: 100%;
  max-width: 54.0625rem;
  gap: 1.8125rem;
  color: #3f3f3f;
}

.phoneSection {
  display: flex;
  margin-top: 1.375rem;
  gap: 1.9375rem;
  color: #3f3f3f;
}

.countryCode {
  display: flex;
  flex-direction: column;
}

.phoneLabel {
  font-size: 1rem;
  margin-bottom: 0.75rem;
}

.codeSelector {
  border-radius: 0.3125rem;
  background-color: #d9d9d9;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.6);
  display: flex;
  gap: 1.25rem;
  font-size: 1rem;
  justify-content: space-between;
  padding: 0.6rem;
  border: 0.0625rem solid #d9d9d9;
}

.dropdownIcon {
  aspect-ratio: 1.7;
  width: 1.0625rem;
  margin: auto 0;
}
