.payment-flight-review-container {
    border-radius: 10px;
    display: flex;
    max-width: 406px;
    flex-direction: column;
    font-family: Inter, sans-serif;
}

.payment-flight-card {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 13px 0 42px;
    border: 1px solid rgba(0, 0, 0, 1);
}

.payment-flight-header {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0 19px;
}

.payment-review-title {
    color: rgba(82, 82, 82, 1);
    font-size: 18px;
    font-weight: 600;
    align-self: start;
}

.payment-flight-details {
    display: flex;
    margin-top: 27px;
    gap: 31px;
    font-size: 14px;
}

.payment-location-info {
    display: flex;
    flex-direction: column;
}

.payment-date {
    color: rgba(82, 82, 82, 1);
    font-weight: 600;
}

.payment-city {
    color: rgba(58, 58, 58, 1);
    font-weight: 500;
    margin-top: 20px;
}

.payment-flight-duration {
    display: flex;
    flex-direction: column;
    color: rgba(82, 82, 82, 1);
    font-weight: 600;
    margin: auto 0;
}

.payment-flight-type {
    align-self: start;
}

.payment-flight-line {
    width: 100px;
    height: 2px;
    background-color: #ccc;
}

.flightwithLine {
    display: flex;
    align-items: center;
}

.payment-destination-info {
    align-self: start;
    display: flex;
    gap: 7px;
}

.payment-flight-icon {
    aspect-ratio: 1.04;
    object-fit: contain;
    object-position: center;
    width: 23px;
    margin: auto 0;
}

.payment-divider {
    min-height: 1px;
    margin-top: 23px;
    width: 100%;
    border: 1px solid rgba(202, 202, 202, 1);
}

.payment-passenger-section {
    display: flex;
    margin-top: 12px;
    flex-direction: column;
    align-items: start;
    font-size: 14px;
    color: rgba(82, 82, 82, 1);
    font-weight: 600;
    padding: 0 64px 0 19px;
}

.payment-passenger-title {
    font-size: 18px;
}

.payment-passenger-name {
    font-weight: 500;
    margin: 16px 0 0 31px;
}

.payment-passenger-contact {
    align-self: stretch;
    margin: 10px 0 0 31px;
}

.payment-bold {
    font-weight: 600;
}