.payment-mobile-container {
    border-radius: 0;
    display: flex;
    max-width: 427px;
    padding: 10px 5px;
    flex-direction: column;
    font-family: Inter, sans-serif;
}

.payment-mobile-container h2 {
    color: #22313F;
    font-family: Inter, sans-serif;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 40px;
}

.payment-wrapper {
    border-radius: 10px;
    background-color: rgba(240, 240, 240, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 20px 15px;
}


.divider {
    margin: 8px 0;
    height: 3px;
    border: 1px solid rgba(218, 218, 218, 1);
}

.final-payment-screen {
    position: fixed;
    right: 0;
    top: 0;
    background-color: #fff;
    height: 100dvh;
    width: 100vw;

}

.enter-upi-mobile {
    padding: 0 30px;
    color: #565656;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin-top: 30px;
}

.enter-upi-mobile img {
    width: 30px;
    height: 100%;
}

.enter-upi-mobile h3 {
    display: flex;
    column-gap: 10px;
}

.enter-upi-mobile .upi-input-mobile {
    border-bottom: 1px solid #adadad;
    width: 100%;
    padding: 5px 10px;
    color: #C2C2C2;
    font-size: 16px;
    font-weight: 600;
}

.footer-payment-upi {
    background: #5D5D5D;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    color: #fff;
    align-items: center;
    padding-right: 20px;
    position: absolute;
    bottom: 30px;
    width: 100%;
    font-size: 12px;
}

.mobilePaymentWrapper {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
    padding: 10px 20px;
}

.mobileCheckoutbtn {
    width: 203px;
    height: 75px;
    position: relative;
    left: 528px;
    color: white;
    flex-shrink: 0;
    border-radius: 24px;
    background: #fe6f61;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    top: 32px;
}

.mobileHeaderSection {
    display: flex;
    width: 100%;
    /* max-width: 52.25rem; */
    gap: 8px 61px;
    flex-wrap: wrap;
}

.mobileTitleWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    width: fit-content;
}

.mobilePaymentTitle {
    color: rgba(49, 49, 49, 1);
    font: 800 24px Poppins, sans-serif;
}

.mobileSecurityMessage {
    display: flex;
    width: fit-content;
    gap: 9px;
    color: rgba(51, 169, 36, 1);
    font: 12px Inter, sans-serif;
    align-items: center;
}

.mobileSecurityIcon {
    aspect-ratio: 1.32;
    object-fit: contain;
    object-position: center;
    width: 14px;
    margin: auto 0;
}

.mobileSecurityText {
    flex-grow: 1;
    flex-basis: auto;
}

.mobileProtectionMessage {
    align-self: end;
    display: flex;
    flex-direction: column;
    gap: 6px;
    color: rgba(51, 169, 36, 1);
    flex-grow: 1;
    flex-basis: auto;
    justify-content: space-between;
}

.mobileCardIcon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 36px;
    margin: 12px;
}

.mobileFormLabel {
    color: rgba(63, 63, 63, 1);
    margin-top: 17.6px;
    font: 600 14px Inter, sans-serif;
}

.mobileFormInput {
    padding: 16px;
    display: flex;
    margin-top: 10px;
    width: 100%;
    font-size: 14px;
    max-width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #A2A2A2;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.60);
}

.mobileDateContainer {
    display: flex;
    margin-top: 12px;
    gap: 11px;
    color: rgba(99, 99, 99, 1);
    white-space: nowrap;
    font: 500 24px Inter, sans-serif;
}

.mobileMonthnyear {
    border-radius: 5px;
    background-color: rgba(217, 217, 217, 1);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
    display: flex;
    margin-top: 10px;
    color: rgba(99, 99, 99, 1);
    white-space: nowrap;
    justify-content: space-between;
    padding: 6.4px 9px;
    font: 500 16px Inter, sans-serif;
    border: 1px solid rgba(162, 162, 162, 1);
    column-gap: 20px;
    align-items: center;
}

.mobileDropdownIcon {
    aspect-ratio: 1.7;
    object-fit: contain;
    object-position: center;
    width: 17px;
    margin: auto 0;
}

.mobileSecurityCodeInput {
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
    padding: 16px;
    font-size: 16px;
    display: flex;
    margin-top: 12px;
    width: 155px;
    height: 40px;
    border: 1px solid rgba(162, 162, 162, 1);
    margin-top: 10px;
}

.mobileCountry-n-securityWrapper {
    display: flex;
    width: 100%;
    column-gap: 10px;
    margin-top: 16px;
}

.mobilePaymentCountry {
    width: 50%;
}

.mobileCountrySelect {
    border-radius: 5px;
    background-color: rgba(217, 217, 217, 1);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
    display: flex;
    margin-top: 10px;
    color: rgba(99, 99, 99, 1);
    white-space: nowrap;
    justify-content: space-between;
    padding: 6.4px 25px;
    font: 500 16px Inter, sans-serif;
    border: 1px solid rgba(162, 162, 162, 1);
}

.mobileVisually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}