.modal-content {
  background-color: red;
  /* Example: Background color for modal content */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  /* Example: Box shadow for modal */
}

.NavLinks {
  color: white;
  gap: 3.2rem;
  filter: drop-shadow(2px 4px 6px black);
  margin-top: -0.3rem;
  font-size: 0.9rem;
  display: flex;
  background-color: #FFFFFF;
  height: 2rem;
  align-items: center;
  border-radius: 1rem;
  color: black !important;
}
.Two_Row_NavBar_Parent{
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
 
}
.FirstRow_NavLinks {
  border-radius: 1rem;
  display: flex;
  align-items: center;
 margin-right: -95%;
  height: 2.2rem;
  color: white;
  gap: 3.2rem;
  filter:drop-shadow(2px 4px 6px black);
  margin-top: -0.3rem;
  font-size: 0.9rem;
  /* display: flex; */
  margin-bottom: 0.5rem;
}

.logo {
  width: 94px;
  height: 82px;
}

.logo img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@media screen and (max-width:900px) {

  .NavLinks,
  .navbtnImg {
    display: none;
  }

  .navButtons img {
    display: none;
  }

  .navButtons svg {
    display: block;
  }
  .Two_Row_NavBar_Parent{
    display: none;
  }
  .FirstRow_NavLinks{
    display: none;
  }
}



