.download_voucher {
    /* background-color: #fff7f7;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 18.75rem;
    top: -1px;
    left: -1px;
    position: absolute;
    width: 248px; */
  }
  
  .voucher_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .voucher_item:last-child  .voucher_name{
    border-bottom: none; /* Remove bottom border for last item */
  }
  
  .voucher_dot {
    color: #ff6f61; /* Red color for dot */
    margin-right: 8px;
  }
  
  .voucher_name {
    flex-grow: 1;
    color: #ff6f61; /* Red color for text */
    font-size: 1rem;
    margin-right: 1.875rem;
    padding: 8px 0;
    border-bottom: 1px solid #656565; /* Light border for separation */
  }
  
  .download_icon {
    color: #ff6f61; /* Red color for icon */
    cursor: pointer;
    width: 14px;
  }
  @media screen and (max-width: 468px) {
    .voucher_dot {
      font-size: 4rem;
    }
    
    .voucher_name {
      font-size: 4rem;
    }
    
    .download_icon {
      width: 18px;
    }
  }
  