.flight-listing-wraper {
  margin: auto 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.location-card {
  border-radius: 0px 0px 0px 0px;
  display: flex;
  max-width: 393px;
  flex-direction: column;
  color: rgba(255, 255, 255, 1);
  font: 600 16px Poppins, sans-serif;
  width: 100%;
  margin-bottom: 28px;
}

.location-wrapper {
  border-radius: 15px;
  background-color: rgba(254, 111, 97, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 100%;
  padding: 17px 23px;
}

.location-icon {
  aspect-ratio: 0.59;
  object-fit: contain;
  object-position: center;
  width: 10px;
  margin: auto 0;
}

.location-name {
  margin: 0 auto;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}