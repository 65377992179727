.container {
  border-radius: 0;
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.facilitySection {
  border-radius: 0.625rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0.25rem 0 3.375rem;
  border: 0.0625rem solid rgba(137, 137, 137, 1);
}

.heading {
  color: rgba(24, 24, 24, 1);
  align-self: start;
  margin-left: 1.3125rem;

  font: 800 1.4rem Poppins, sans-serif;
}

.divider {
  min-height: 0.0625rem;
  margin-top: 0.375rem;
  width: 100%;
  border: 0.0625rem solid rgba(137, 137, 137, 1);
}

.content {
  display: flex;
  margin-top: 1.8125rem;
  width: 100%;
  flex-direction: column;
  padding: 0 2.8125rem;
}

.facilityRow {
  display: flex;
  width: 100%;
  align-items: start;
  gap: 1.25rem;
  justify-content: space-between;
}

.secondRow {
  margin-top: 2.125rem;
  max-width: 60.8125rem;
}

@media (max-width: 61.9375rem) {
  .facilitySection {
    max-width: 100%;
  }

  .heading {
    margin-left: 0.625rem;
  }

  .divider {
    max-width: 100%;
  }

  .content {
    max-width: 100%;
    padding: 0 1.25rem;
  }

  .facilityRow {
    max-width: 100%;
  }

  .secondRow {
    margin-right: 0.625rem;
  }
}
