.Day-wise-centerlise {
  margin: 6rem 0rem;
  display: flex;
  justify-content: center;
}

.Day-wise-individual-day-main {
  width: 100%;
  z-index: 9;
  position: sticky;
  top: 7.2rem;
  background-color: white;
  display: flex;
  flex-direction: row;
  column-gap: 2.5rem;
  margin-top: 3rem;
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0255rem;
  overflow: auto;
}
.Day-wise-individual-day-main::-webkit-scrollbar {
  display: none;
}

.Day-wise-main {
  /* margin-left: 3rem; */
  width: 91rem;
}

.Day-wise-individual-day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.25rem;
  height: 2.8125rem;
  flex-shrink: 0;
  border-radius: 1.4375rem;
  border: 0.0625rem solid #000;
  background: #fff;
}

.Day-wise-individual-day:hover {
  cursor: pointer;
}

.Day-wise-individual-day:active {
  cursor: pointer;
  background: #fe6f61;
  color: #fff;
}
.Day-wise-individual-day:hover {
  cursor: pointer;
  background: #fe6f61;
  color: #fff;
}

.Day-wise {
  color: #020202;
  font-family: Sofia Pro;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Day-wise-DayCard-parent {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  /* margin-top: 5rem; */
}

.Day-wise-DayCard {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 73.0866rem;
  height: 26.479rem;
  border-radius: 3rem;
  background: #fff;
  box-shadow: 0rem 0.375rem 0.8125rem 0rem rgba(0, 0, 0, 0.21);
  background-image: url("https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/daydetailbackground.png");
  opacity: 1;
}
.Day-wise-DayCard_activity_card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.Day-wise-DayCard-img-Activity {
  border-radius: 1rem;
  width: 22.8604rem;
  height: 22.0987rem;
  margin-left: 3.7rem;
  background-image: var(--background-image);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Day-wise-DayCard-img-Hotel {
  width: 14.8026rem;
  height: 23.3637rem;
  margin-left: 1rem;
}

.Day-wise-line-and-card {
  display: flex;
  align-items: end;
  position: relative;
}

.Day-wise-line {
  border-left: 0.187rem dotted black;
  height:9.5964rem;
  margin-right: 1rem;
  border-spacing: 10px;
  margin-left: 2rem;
}

.Day-wise-lastCompo_img_div {
    margin-right: 3rem;
    height: 11rem;
}

.Day-wise-lastCompo_img {
    width: 6rem;
    height: 8rem;
    position: absolute;
    left: -1rem;
}

.Day-wise-counter {
  color: #000;
  /* font-family: Poppins; */
  padding: 0 10px 0 10px;
  box-shadow: 0rem 0.375rem 0.8125rem 0rem rgba(0, 0, 0, 0.21);
  border-radius: 10px;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.itineary-hotel-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: -1.3rem;
  width: 13.7617rem;
  height: 7.5161rem;
  border-radius: 1.5rem;
  background: #fff;
  box-shadow: 0.125rem 0.1875rem 0.3125rem 0rem rgba(0, 0, 0, 0.16);
  /* ///////// */
  color: #000;
  /* font-family: Poppins; */
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.itineary-Activity-details {
  margin-left: 3.2rem;
  display: flex;
  column-gap: 5px;
}

.itineary-details-parent {
  width: 39rem;
  /* height: 18rem; */
  padding-bottom: .625rem;
  border-radius: 0 1rem 1rem 0;
  background: url("../../../../Assets/SearchFilter/searchFilterBG.svg") center
    center / contain padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0rem 0.375rem 0.8125rem 0rem rgba(0, 0, 0, 0.21);
}

.ActivityNameHighLighter {
  color: #fff;
  margin-left: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
  /* height: 3.375rem; */
  border-radius: 10px;
  background: #75B1D2;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.ActivityName {
  margin-left: 1rem;
  margin-right: 1rem;
}
.itineary-day-desc {
  width: 38.5216rem;
  height: 4.9943rem;
  /* ////// */
  color: #000;
  /* font-family: Poppins; */
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Day-wise-review {
  width: 1rem;
  height: 1rem;
}

.DayTitle_DayDescription {
  width: 32rem;
  height: 9rem;
  overflow-y: auto;
  margin-left: 3.2rem;
  margin-top: 1rem;
  background-image: url("https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/daydetailbackground.png");
}
.DayTitle_description_para {
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  font-family: var(--font-family-sofia);
}
.DayTitle_DayDescription::-webkit-scrollbar {
  width: 2px;
}
.DayTitle_DayDescription::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.DayTitle_DayDescription::-webkit-scrollbar-thumb {
  background: #888;
}
.DayTitle_DayDescription::-webkit-scrollbar-thumb:hover {
  background: #302f2f;
}
@media screen and (max-width: 768px) {
  .Day-wise-main {
    width: 95dvw;
  }
  .Day-wise-DayCard {
    width: 90vw;
    height: 33rem;
  }
  .Day-wise-centerlise {
    margin-top: 5rem;
  }
  .itineary-details-parent {
    width: 73%;
    height: 30rem;
  }
  .ActivityNameHighLighter {
    font-size: 12px;
  }
  .DayTitle_DayDescription {
    height: 18rem;
    width: auto;
    font-size: 10px;
  }
  .Day-wise-review {
    width: 2rem;
    height: 2rem;
  }
  .Day-wise-individual-day-main {
    width: 90%;
    font-size: 2.5rem;
  }

  .Day-wise-individual-day {
    width: 11.25rem;
    height: 4.8125rem;
  }
}
