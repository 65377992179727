.paymentContainer {
  border-radius: 0;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  width: 49rem;
  flex-direction: column;
  font-weight: 600;
}

.paymentWrapper {
  border-radius: 0.625rem;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 2rem 0;
  align-items: start;
  padding: 0.8125rem 2.375rem 2.5rem;
}
.checkoutbtn {
  width: 12.6875rem;
  height: 3.6875rem;
  position: relative;
  left: 33rem;
  color: white;
  flex-shrink: 0;
  border-radius: 15px;
  background: #fe6f61;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  top: 2rem;
}
.headerSection {
  display: flex;
  width: 100%;
  max-width: 52.25rem;
  gap: 0.5rem 3.8125rem;
  flex-wrap: wrap;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
}

.paymentTitle {
  color: rgba(49, 49, 49, 1);
  font: 800 1.5rem Poppins, sans-serif;
}

.securityMessage {
  display: flex;
  margin-left: 0.625rem;
  width: 100%;
  gap: 0.5625rem;
  color: rgba(51, 169, 36, 1);
  font: 1.375rem Inter, sans-serif;
}

.securityIcon {
  aspect-ratio: 1.32;
  object-fit: contain;
  object-position: center;
  width: 1.5625rem;
  margin: auto 0;
}

.securityText {
  flex-grow: 1;
  width: 18.25rem;
  flex-basis: auto;
}

.protectionMessage {
  align-self: end;
  display: flex;
  gap: 0.375rem;
  color: rgba(51, 169, 36, 1);
  flex-grow: 1;
  flex-basis: auto;
  font: 1.375rem Inter, sans-serif;
}

.cardIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 2.25rem;
  margin: 0.75rem;
}

.formLabel {
  color: rgba(63, 63, 63, 1);
  margin-top: 1.1rem;
  font: 600 1rem Inter, sans-serif;
}

.formInput {
  padding: 1rem;
  border-radius: 0.3125rem;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.6);
  display: flex;
  margin-top: 0.625rem;
  width: 100%;
  font-size: 1rem;
  max-width: 100%;
  height: 2.5rem;
  border: 0.0625rem solid rgba(162, 162, 162, 1);
}

.dateContainer {
  display: flex;
  margin-top: 0.75rem;
  gap: 0.6875rem;
  color: rgba(99, 99, 99, 1);
  white-space: nowrap;
  font: 500 1.5rem Inter, sans-serif;
}

.dateSelect {
  border-radius: 0.3125rem;
  background-color: rgba(217, 217, 217, 1);
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.6);
  display: flex;
  gap: 2.5rem;
  font-size: 1rem;
  padding: 0.4rem 1.25rem;
  border: 0.0625rem solid rgba(162, 162, 162, 1);
}

.dropdownIcon {
  aspect-ratio: 1.7;
  object-fit: contain;
  object-position: center;
  width: 1.0625rem;
  margin: auto 0;
}

.securityCodeInput {
  border-radius: 0.3125rem;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.6);
  padding: 1rem;
  font-size: 1rem;
  display: flex;
  margin-top: 0.75rem;
  width: 11.6875rem;
  height: 2.5rem;
  border: 0.0625rem solid rgba(162, 162, 162, 1);
}

.countrySelect {
  border-radius: 0.3125rem;
  background-color: rgba(217, 217, 217, 1);
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.6);
  display: flex;
  margin-top: 0.625rem;
  width: 28.3125rem;
  max-width: 100%;
  font-size: -1rem;
  gap: 1.25rem;
  height: 3rem;
  color: rgba(99, 99, 99, 1);
  white-space: nowrap;
  justify-content: space-between;
  padding: 0.875rem 1.5625rem;
  font: 500 1rem Inter, sans-serif;
  border: 0.0625rem solid rgba(162, 162, 162, 1);
}

.visually-hidden {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  margin: -0.0625rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
