.priceOption {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.priceRow {
  display: flex;
  gap: .875rem;
  align-items: center;
}


.radioWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 5px;
  column-gap: 5px;
}

.radioInput {
  display: none;
  /* Hide the default radio button */
}

.radioCircle {
  width: 20px;
  height: 20px;
  border: 2px solid #636363;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.radioInput:checked+.radioCircle::after {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #FE6F61;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.priceDisplay {
  display: flex;
  gap: .0625rem;
  color: #484848;
  white-space: nowrap;
  font: 600 1.125rem Inter, sans-serif;
}

.currencyIcon {
  aspect-ratio: 0.75;
  object-fit: contain;
  object-position: center;
  width: .75rem;
  margin: auto 0;
}

.fareType {
  color: #757575;
  background: rgba(254, 111, 97, 0.30);
  width: fit-content;
  font: 500 .875rem Inter, sans-serif;
}

.divider {
  align-self: stretch;
  margin-top: .375rem;
  width: 100%;
  height: .0625rem;
  border: .0625rem solid #AFAFAF;
}

@media (max-width: 61.9375rem) {
  .priceDisplay {
    white-space: initial;
  }

  .fareType {
    margin-left: .4375rem;
  }

  .divider {
    margin-left: .1875rem;
  }
}