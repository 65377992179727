.gallery_outer_div {
  width: 100%;
  height: 100vh;
  margin: 10px 0px !important;
}

.gallery_centralise {
  display: flex;
  justify-content: space-around;
  margin-top: 7.3931rem;
}
.gallery_outer_container {
  width: 79rem;
}
.gallery-container {
  display: flex;
  justify-content: space-between; /* Distribute columns evenly */
  gap: 20px; /* Space between columns */
  padding: 20px;
}

.gallery-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between items in each column */
}

/* .gallery-item {
    background-color: #f0f0f0;
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
  } */

.gallery-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.top_shimmer {
  animation: shimmer 2s linear infinite;
  background: #f0f0f0
    linear-gradient(
      90deg,
      transparent 0,
      rgba(0, 0, 0, 0.2) 25%,
      transparent 50%
    );
  background-size: 200% 100%;
  height: 100%;
  width: 100%;
  font-size: 3rem;
}
@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }

  to {
    background-position: -200% 0;
  }
}
@media screen and (max-width: 1024px) {
  .gallery_outer_container {
    width: 100%;
  }
}
