.ActivityLayouts {
  width: 100%;
  height: 12rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px 15px 0px 0px;
  background: #fff;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.16);

  scroll-snap-align: start; /* Snap the activity items to the start of the container */
}
.ActivityLayouts img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ActivitymobLayouts {
  width: 100%;
  height: 52rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.16);

  scroll-snap-align: start; /* Snap the activity items to the start of the container */
}
.ActivitymobLayouts img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thingstodoCard {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  width: 24rem;
  height: 29rem;
  padding: 1rem;
  position: relative;
  border-radius: 10px;
  background: #f1f1f1;
}

.thingstodomobileCard {
  width: 100vw;
  height: 62rem;
  padding: 3rem;
}

.thingstodoCard h1 {
  color: #000;
  width: 100%;
  font-size: 1.7rem;
  margin-top: 2.2rem;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.thingstodoCard p {
  color: #373636;
  width: 100%;

  text-align: left;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.thingstodomobileCard h1 {
  color: #000;
  width: 100%;
  font-size: 2.5rem;
  margin-top: 2.2rem;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.thingstodomobileCard p {
  color: #373636;
  width: 100%;
  font-size: 2.5rem;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.exprlorepara {
  width: 100%;
  height: 4.4rem;
  border-top: 1px solid black;
  position: absolute;
  bottom: 0;
  color: #fe6f61;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.exprlorepara p {
  color: #fe6f61;
  text-align: center;
}

.thingstodofilterWrapper {
  height: 12rem;
  background: #ececec;
}
.thingstodomainpara {
  font-size: 2rem;
  font-weight: 600;
  padding: 0.5rem 4rem;
}
.thingstodosearch {
  box-shadow: 12px 12px 8px 0px rgba(0, 0, 0, 0.2);
}

.thingssearch {
  width: 43rem;
  height: 3.5rem;
}

@media screen and (max-width: 480px) {
  .thingssearch {
    width: 49rem;
    height: 4.5rem;
  }
  .thingstodofilterWrapper {
    height: 20rem;
    background: #ececec;
  }
}
