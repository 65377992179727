.container {
  border-radius: 0;
  display: flex;
  width: 33rem;
  flex-direction: column;
}

.roomCard {
  border-radius: 0.625rem;
  background-color: rgba(217, 217, 217, 1);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1.625rem 0.9375rem 1rem;
}

.roomImage {
  aspect-ratio: 1.82;
  object-fit: contain;
  object-position: center;
  width: 100%;
  border-radius: 0.625rem;
}

.roomTitle {
  color: rgba(24, 24, 24, 1);
  align-self: start;
  margin-top: 0.6875rem;
  font: 900 1rem Poppins, sans-serif;
}

.contentWrapper {
  margin-top: 0.375rem;
}

.twoColumnLayout {
  gap: 1.25rem;
  display: flex;
}

.amenitiesColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 34%;
  margin-left: 0;
}

.amenitiesList {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: rgba(51, 169, 36, 1);
  font: 400 0.9rem Inter, sans-serif;
}

.amenityItem {
  display: flex;
  gap: 0.625rem;
  margin-top: 0.625rem;
}

.bulletPoint {
  background-color: rgba(77, 77, 77, 1);
  border-radius: 50%;
  display: flex;
  width: 0.5625rem;
  height: 0.5625rem;
  margin: auto 0;
}

.amenityText {
  flex-grow: 1;
}

.restrictionsColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 66%;
  margin-left: 1.25rem;
}

.restrictionsList {
  display: flex;
  margin-top: 0.25rem;
  width: 100%;
  flex-direction: column;
  font-family: Inter, sans-serif;
}

.restrictionItem {
  display: flex;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: rgba(202, 69, 69, 1);
  font-weight: 400;
  margin-top: 0.625rem;
}

.priceSection {
  display: flex;
  margin-top: 0.2rem;
  width: 100%;
  flex-direction: column;
  align-items: start;
}

.priceWrapper {
  display: flex;
  margin-left: 0.9375rem;
  gap: 0.1875rem;
  font-size: 1.8rem;
  color: rgba(49, 49, 49, 1);
  font-weight: 600;
}

.currencyIcon {
  aspect-ratio: 0.76;
  object-fit: contain;
  object-position: center;
  width: 1.625rem;
  margin: auto 0;
}

.taxInfo {
  color: rgba(40, 40, 40, 1);
  font-size: 0.9rem;
  font-weight: 500;
  align-self: stretch;
  margin-top: 0.4375rem;
}

.selectButton {
  border-radius: 0.625rem;
  background-color: rgba(254, 111, 97, 1);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  font-size: 1rem;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  margin: 1.125rem 0 0 0.9375rem;
  padding: 0.8125rem 2.625rem;
  border: none;
  cursor: pointer;
}

@media (max-width: 61.9375rem) {
  .roomCard {
    max-width: 100%;
  }
  .container {
    border-radius: 0;
    display: flex;
    width: 90rem;
    flex-direction: column;
  }
  .roomImage {
    max-width: 100%;
  }

  .roomTitle {
    max-width: 100%;
    font-size: 3rem;
  }

  .contentWrapper {
    max-width: 100%;
  }

  .twoColumnLayout {
    align-items: stretch;
    gap: 0;
  }

  .amenitiesColumn {
    width: 100%;
  }

  .amenitiesList {
    margin-top: 2.5rem;
    font-size: 3rem;
  }
  .restrictionItem {
    font-size: 3.3rem;
  }
  .restrictionsColumn {
    width: 100%;
  }

  .restrictionsList {
    margin-top: 2.5rem;
  }

  .priceSection {
    padding: 0 1.25rem;
  }

  .priceWrapper {
    margin-left: 0.625rem;
    white-space: initial;
    font-size: 4rem;
  }
  .bulletPoint {
    width: 1rem;
    height: 1rem;
  }
  .taxInfo {
    font-size: 2.9rem;
  }
  .selectButton {
    padding: 2rem 3.25rem;
    font-size: 4rem;
    margin: 2rem 0;
  }
}
