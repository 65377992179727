.navbar {
  width: 100%;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
}

.navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 20px;
  background-color: #e0e0e0;
}

/* 
  .navbar-logo {
    height: 50px;
  } */

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-icons {
  list-style: none;
  display: flex;
  gap: 1.25rem;
  padding: 0;
}

.navbar-icons li {
  font-size: 14px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-weight: 600;
}

.navbar-links li {
  font-weight: 600;
  padding: 10px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.navbar-links li:hover {
  border-bottom: 2px solid #ff6b6b;
  transition: 0.5s left ease-in;
}

.navbar-icons div a:last-child::after {
  content: '';
  display: block;
  /* Ensures the pseudo-element is rendered */
  height: 32px;
  width: 2px;
  background-color: #B9B9B9;
  position: absolute;
  right: -10px;
}

.navbar-icons .icon {
  margin-right: 5px;
}

.login-btn {
  font-weight: bold;
  margin-left: 15px;
}

.navbar-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-top: 1px solid #ddd;
  position: relative;
  z-index: 300;
  width: 100%;
}

.navbar-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links a {
  text-decoration: none;
  /* font-size: 16px; */
  color: #333;
  display: flex;
  align-items: center;
  column-gap: 6px;
  color: #FE6F61;
}

.flight-n-hotels img {
  width: 12px;
}

.plan-holiday-btn {
  border: 1px solid #ff6b6b;
  background-color: transparent;
  color: #ff6b6b;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.plan-holiday-btn:hover {
  background-color: #ff6b6b;
  color: white;
}

.Destinationlisting_Outer_wrapper {
  position: absolute;
  right: 0;
  width: 100vw;
  bottom: 0;
}

.Destinationlisting_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-top: 1px solid #e1e1e1;
  width: 72rem;
  height: 25rem;
  background-color: #fff;
  z-index: 200;
  position: absolute;
}

.DestinationListingBackground {
  width: 100vw;
  position: absolute;
  background-color: #0000004a;
  height: 200vh;
  z-index: 100;
}

.mobileNavbarSticky {
  width: 100%;
  z-index: 300;
  height: 15rem;
  align-items: center;
}

.cursor_not_allowed {
  cursor: not-allowed;
}

.ProfileAvatar-name {
  font-size: 1.5rem;
  font-weight: bolder;
  display: flex;
  align-items: center;
}

.ProfileAvatar {
  position: relative;
  overflow: hidden;
  border: 1px solid;
}

.ProfileAvatar p {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1.5rem;
  transform: translate(-50%, -50%);
}

@media screen and (max-width:640px) {
  .ProfileAvatar p {
    font-size: 7.5rem;
  }
}