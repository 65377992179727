.progressBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.step {
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.circle {
  width: 30px;
  height: 30px;
  border: 2px solid #c4c4c4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #c4c4c4;
  font-size: 16px;
  font-weight: bold;
  z-index: 1;
}
.circle.completed{
  cursor: pointer;
}
.completed {
  border-color: #4caf50;
  background-color: #4caf50;
  color: white;
}

.line {
  flex-grow: 1;
  height: 2px;
  background-color: #c4c4c4;
  margin: 0 5px;
  z-index: 0;
  width: 100px;
}

.line.completed {
  background-color: #4caf50;
}

.label {
  font-size: 14px;
  color: #c4c4c4;
  text-align: center;
}

.activeLabel {
  color: #4caf50;
  cursor: pointer;
}
