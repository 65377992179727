.container {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;
}

.headerSection {
    display: flex;
    width: 100%;
    gap: 20px;
    font-weight: 600;
    flex-wrap: wrap;
    justify-content: space-between;
}

.flightInfo {
    display: flex;
    gap: 15px;
    color: rgba(82, 82, 82, 1);
}

.airlineInfo {
    align-self: start;
    display: flex;
    gap: 5px;
    font-size: 20px;
}

.airlineLogo {
    aspect-ratio: 1.79;
    object-fit: contain;
    object-position: center;
    width: 45px;
}

.airlineName {
    flex-basis: auto;
}

.locationName {
    font-size: 20px;
}

.destinationInfo {
    align-self: start;
    display: flex;
    margin-top: 4px;
    gap: 14px;
}

.destinationIcon {
    aspect-ratio: 1.04;
    object-fit: contain;
    object-position: center;
    width: 20px;
    margin: auto 0;
}

.destinationName {
    color: rgba(82, 82, 82, 1);
    font-size: 20px;
    flex-basis: auto;
}

.flightDate {
    color: rgba(129, 129, 129, 1);
    font-size: 18px;
    flex-basis: auto;
}

.legendContainer {
    display: flex;
    width: 100%;
    max-width: 632px;
    align-items: start;
    gap: 20px;
    font-size: 16px;
    color: var(--Primary-shade-02, #003);
    font-weight: 400;
    white-space: nowrap;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 37px 0 0 59px;
}

.legendItem {
    display: flex;
    gap: 6px;
}

.legendDot {
    border-radius: 50%;
    display: flex;
    width: 15px;
    height: 15px;
    margin: auto 0;
}

.selectedDot {
    background-color: rgba(253, 114, 100, 1);
}

.unavailableDot {
    background-color: var(--Neutral-gray05, #adadad);
    fill: var(--Neutral-gray05, #adadad);
}

.availableDot {
    stroke-width: 1.264px;
    border: 1px solid rgba(0, 0, 128, 1);
    stroke: var(--Primary-Primary, #000080);
}

.passengerSection {
    display: flex;
    margin-top: 28px;
    width: 100%;
    flex-direction: column;
    font-size: 17px;
    color: var(--Primary-shade-02, #003);
    padding: 0px 14px;
    border: 1px solid rgba(0, 0, 0, 1);
}

.sectionHeader {
    display: flex;
    width: 100%;
    max-width: 726px;
    gap: 20px;
    font-weight: 600;
    flex-wrap: wrap;
    justify-content: space-between;
}

.divider {
    margin-top: 16px;
    height: 1px;
    border: 1px solid rgba(97, 97, 97, 1);
}

.passengerName {
    font-weight: 500;
    align-self: start;
    margin: 19px 0 0 45px;
}

.passengerDetails {
    display: flex;
    margin-top: 19px;
    width: 100%;
    max-width: 688px;
    gap: 20px;
    font-weight: 500;
    flex-wrap: wrap;
    justify-content: space-between;
}

.passengerDetails div {
    width: 25%;
}

.seatNumber {
    display: flex;
    align-items: center;
    justify-content: center;
}

.priceInfo {
    display: flex;
    font-weight: 400;
    white-space: nowrap;
    justify-content: flex-end;
}

.currencyIcon {
    aspect-ratio: 0.74;
    object-fit: contain;
    object-position: center;
    width: 12px;
    margin: auto 0;
}

.actionButtons {
    display: flex;
    margin-top: 40px;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    font: 700 16px Poppins, sans-serif;
}

.skipButton {
    border-radius: 10px;
    background-color: rgba(240, 240, 240, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: rgba(254, 111, 97, 1);
    padding: 10px 21px;
}

.proceedButton {
    border-radius: 10px;
    background-color: rgba(254, 111, 97, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: rgba(255, 255, 255, 1);
    padding: 10px 21px;
    ;
}

@media (max-width: 991px) {
    .headerSection {
        max-width: 100%;
        margin-right: 5px;
    }

    .legendContainer {
        max-width: 100%;
        white-space: initial;
    }

    .legendItem {
        white-space: initial;
    }

    .passengerSection {
        max-width: 100%;
        margin: 40px 5px 0 0;
        padding: 0 20px;
    }

    .sectionHeader {
        max-width: 100%;
    }

    .divider {
        max-width: 100%;
    }

    .passengerName {
        margin-left: 10px;
    }

    .actionButtons {
        max-width: 100%;
        margin-top: 40px;
    }

    .skipButton {
        max-width: 100%;
        padding-left: 20px;
    }

    .proceedButton {
        padding: 0 20px;
    }
}