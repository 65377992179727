.inputContainer {
  display: flex;
  font-size: 1rem;
  flex-direction: column;
  flex: 1;
}

.labels {
  color: #3f3f3f;
  margin-bottom: 0.5625rem;
}

.input {
  border-radius: 0.3125rem;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.6);
  height: 2.5rem;
  margin-bottom: 1rem;
  width: 16rem;
  border: 0.0625rem solid #a2a2a2;
  padding: 0 1rem;
  font-size: 1rem;
}
