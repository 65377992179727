.passenger-container {
    border-radius: 0;
    display: flex;
    max-width: 379px;
    flex-direction: column;
    font-family: Inter, sans-serif;
    font-weight: 500;
    width: 90%;
    margin: auto;
}

.passenger-heading {
    color: rgba(37, 40, 49, 0.50);
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 20px;
}

.passenger-heading h2 {
    color: #0D1634;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.header-section {
    display: flex;
    width: 100%;
    gap: 20px;
    font-size: 14px;
    color: rgba(254, 111, 97, 1);
    justify-content: space-between;
}

.disable-addpassenger {
    cursor: not-allowed;
    color: rgba(254, 110, 97, 0.301);
}

.header-text {
    margin: auto 0;
}

.header-icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
}

.action-buttons {
    align-self: center;
    display: flex;
    width: 100%;
    align-items: start;
    justify-content: space-between;
    gap: 10px;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
}

.fare-button {
    border-radius: 8px;
    width: 48%;
    color: #fe6f61;
    border: 1px solid rgba(254, 111, 97, 1);
    display: flex;
    height: 48px;
    padding: 14px 27px 14px 27px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.review-button {
    border-radius: 8px;
    width: 48%;
    background-color: rgba(254, 111, 97, 1);
    color: #fff;
    white-space: nowrap;
    padding: 15px 50px;
    display: flex;
    height: 48px;
    padding: 14px 0px 14px 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}