.flight-card {
    border-radius: 0;
    display: flex;
    max-width: 396px;
    flex-direction: column;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
}

.flight-details {
    border-radius: 15px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 23px 0;
    border: 1px solid rgba(222, 222, 222, 1);
}

.flight-type {
    color: #22313f;
    text-align: center;
    font: 500 12px Inter, sans-serif;
}

.route-info {
    display: flex;
    align-items: end;
    gap: 4px;
    justify-content: start;
}

.location-info {
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;
    font-weight: 500;
    justify-content: start;
}

.airport-code {
    color: #22313f;
    font-size: 20px;
}

.city-name {
    color: #808991;
    font-size: 12px;
}

.flight-duration {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 156px;
}

.trip-icon {
    display: flex;
    max-width: 100%;
    width: 156px;
    align-items: center;
    gap: 2px;
    padding: 1px 16px;
}

.dash-line {
    align-self: stretch;
    width: 45px;
    height: 1px;
    margin: auto 0;
    border: 1px dashed rgba(172, 172, 172, 1);
}

.plane-icon {
    aspect-ratio: 1.06;
    object-fit: contain;
    object-position: center;
    width: 18px;
    align-self: stretch;
}

.duration-text {
    color: #22313f;
    text-align: center;
    margin-top: 5px;
    font: 500 12px Inter, sans-serif;
}

.schedule-info {
    display: flex;
    margin-top: 23px;
    align-items: start;
    gap: 40px 80px;
    font-family: Inter, sans-serif;
    justify-content: start;
}

.departure-time {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.time {
    color: #22313f;
    font-size: 16px;
    font-weight: 500;
}

.date {
    color: #808991;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
}

.arrival-time {
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: right;
    justify-content: start;
}

.airline-price {
    display: flex;
    width: 100%;
    max-width: 313px;
    gap: 20px;
    font-family: Inter, sans-serif;
    justify-content: space-between;
    margin: 28px 0 0 14px;
}

.airline-info {
    background-color: rgba(236, 236, 236, 1);
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 12px;
    color: #808991;
    font-weight: 400;
    justify-content: start;
    margin: auto 0;
}

.airline-logo {
    aspect-ratio: 1.16;
    object-fit: contain;
    object-position: center;
    width: 29px;
    align-self: stretch;
    margin: auto 0;
}

.airline-name {
    align-self: stretch;
    margin: auto 0;
}

.aeroplane-price {
    border-radius: 10px;
    background-color: rgba(254, 111, 97, 1);
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    white-space: nowrap;
    text-align: right;
    padding: 10px 12px;
}

.divider {
    align-self: stretch;
    min-height: 1px;
    margin-top: 15px;
    width: 100%;
    border: 1px solid rgba(191, 191, 191, 1);
}

.detailed-info {
    align-self: stretch;
    display: flex;
    margin-top: 23px;
    width: 100%;
    flex-direction: column;
    align-items: start;
    color: rgba(34, 49, 63, 1);
    padding: 0 56px 0 16px;
    font: 500 15px Inter, sans-serif;
}

.timeline {
    display: flex;
    margin-left: 20px;
    gap: 7px;
}

.timeline-line {
    width: 1px;
    height: 46px;
    margin: auto 0;
    border: 1px dashed rgba(172, 172, 172, 1);
}

.timeline-content {
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: start;
    flex-grow: 1;
    flex-basis: 0;
}

.timeline-header {
    align-self: stretch;
    display: flex;
    gap: 11px;
}

.airport-name {
    flex-grow: 1;
    width: 210px;
}

.timeline-duration {
    color: #7b7b7b;
    text-align: center;
    font-size: 12px;
    margin-top: 13px;
}

.arrival-details {
    margin-top: 11px;
}

.arrival-date {
    margin-top: 30px;
}