.container {
  background-color: rgba(255, 251, 251, 1);
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  margin: 0 auto;
  padding: 8px 0 476px;
}

.header {
  display: flex;
  width: 100%;
  max-width: 392px;
  gap: 40px 100px;
  align-self: center;
}

.time {
  color: rgba(0, 0, 0, 1);
  font: 500 20px Poppins, sans-serif;
}

.statusIcons {
  display: flex;
  align-items: center;
  gap: 7px;
}

.statusIcon {
  width: 17px;
  height: 17px;
  object-fit: contain;
}

.content {
  padding: 0 8px;
}

.title {
  color: rgba(68, 68, 68, 1);
  text-align: center;
  font: 700 20px Open Sans, sans-serif;
  margin: 20px 0;
}

.hotelInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.hotelHeader {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hotelName {
  color: rgba(49, 49, 49, 1);
  font: 600 14px Poppins, sans-serif;
}

.rating {
  display: flex;
  align-items: center;
  gap: 3px;
  color: rgba(82, 82, 82, 1);
  font: 500 12px Roboto, sans-serif;
}

.reviewsButton {
  color: rgba(254, 111, 97, 1);
  font: 500 14px Inter, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
}

.roomDetails {
  margin-bottom: 20px;
}

.roomType {
  color: rgba(24, 24, 24, 1);
  font: 500 16px Roboto, sans-serif;
  margin-bottom: 10px;
}

.roomInfo {
  display: flex;
  gap: 8px;
  font: 12px Inter, sans-serif;
  margin-bottom: 5px;
}

.roomCount {
  color: rgba(28, 28, 28, 1);
  font-weight: 600;
}

.roomCategory {
  color: rgba(99, 99, 99, 1);
  font-weight: 500;
}

.refundPolicy {
  color: rgba(99, 99, 99, 1);
  font: 500 12px Inter, sans-serif;
}

.bookingSummary {
  background-color: rgba(243, 226, 224, 1);
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 20px;
}

.services {
  margin-bottom: 20px;
}

.servicesTitle {
  color: rgba(49, 49, 49, 1);
  font: 600 12px Poppins, sans-serif;
  margin-bottom: 10px;
}

.servicesList {
  display: flex;
  flex-direction: column;
}

.moreServices {
  color: rgba(254, 111, 97, 1);
  font: 700 12px Inter, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.pricing {
  margin-bottom: 20px;
}

.pricingTitle {
  color: rgba(49, 49, 49, 1);
  font: 600 14px Poppins, sans-serif;
  margin-bottom: 15px;
}

.paymentButton {
  background-color: rgba(254, 111, 97, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 7px;
  font-size: 20px;
  padding: 14px;
  width: 100%;
  max-width: 326px;
  margin: 20px auto;
  cursor: pointer;
}
