.baggage-container {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    scrollbar-width: none;
    background: #fff;
}

.baggage-title {
    color: rgba(82, 82, 82, 1);
    align-self: start;
    font: 600 24px Inter, sans-serif;
}

.baggage-subtitle {
    color: rgba(82, 82, 82, 1);
    align-self: start;
    margin-top: 5px;
    font: 500 20px Inter, sans-serif;
}

.baggage-options-container {
    display: flex;
    margin-top: 67px;
    width: 100%;
    padding-left: 19px;
    padding-right: 19px;
    flex-direction: column;
    font: 500 20px Inter, sans-serif;
}

.baggage-options-wrapper {
    display: flex;
    width: 100%;
    gap: 35px 0;
    flex-wrap: wrap;
    flex-direction: column;
}

.baggage-option {
    display: flex;
    gap: 15px;
    color: rgba(82, 82, 82, 1);
    flex: 1;
    margin: auto 0;
    align-items: center;
}

.baggage-baggage-icon {
    aspect-ratio: 1.03;
    object-fit: contain;
    object-position: center;
    width: 20px;
    align-self: start;
}

.baggage-text {
    color: #525252;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.baggage-price-section {
    display: flex;
    gap: 29px;
    white-space: nowrap;
    flex: 1;
    justify-content: space-between;
}

.baggage-price-wrapper {
    display: flex;
    gap: 8px;
    color: rgba(82, 82, 82, 1);
    margin: auto 0;
    color: #525252;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.baggage-currency-icon {
    aspect-ratio: 0.75;
    object-fit: contain;
    object-position: center;
    width: 12px;
    margin: auto 0;
}

.baggage-add-button {
    border-radius: 8px;
    border: 1px solid #FE6F61;
    padding: 3px 12px;
    color: #FE6F61;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


/* @media (max-width: 991px) {
    .baggage-priceSection {
        white-space: initial;
    }

    .baggage-priceWrapper {
        white-space: initial;
    }

    .baggage-addButton {
        white-space: initial;
        padding: 0 20px;
    }

    .baggage-subtitle {
        max-width: 100%;
    }

    .baggage-optionsContainer {
        max-width: 100%;
        margin-top: 40px;
    }

    .baggage-optionsWrapper {
        max-width: 100%;
    }
} */