.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.selectionSummary {
  width: 60%;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
  background: #fff;
  max-height: 80vh;
  height: fit-content;
  overflow: auto;
  scrollbar-width: none;
}

.seatMap {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
  width: 40%;
}

.row {
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
}

.businessClass .seat {
  background-color: #d1c4e9;
}

.economyClass .seat {
  background-color: #bbdefb;
}

.seat {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
}

.selectedSeat {
  background-color: #ffcccb;
  border-color: #f44336;
}

.availableSeat:hover {
  background-color: #c8e6c9;
  border-color: #388e3c;
}


.proceedButton {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.proceedButton:hover {
  background-color: #d32f2f;
}