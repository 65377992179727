.facilityItem {
  display: flex;
  gap: 0.6875rem;
}

.facilityIcon {
  aspect-ratio: 1.32;
  object-fit: contain;
  object-position: center;
  width: 1rem;
}

.facilityText {
  flex-basis: auto;
  text-wrap: nowrap;
  color: rgba(40, 40, 40, 1);
  font: 1rem Inter, sans-serif;
}

.lightWeight {
  font-weight: 400;
}

.smallText {
  font-size: 1rem;
}
