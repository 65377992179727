.container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
  }
  
  .reviewCard {
    flex: 2;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    margin-bottom: 20px;
  }
  
  .journeyDetails {
    margin-bottom: 20px;
  }
  
  .routeInfo {
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    background-color: #d9d9d9;
  }
  
  .routeInfo h3 {
    margin: 0;
    font-size: 15px;
    padding: 1rem 3rem;
  }
  
  .duration {
    font-weight: bold;
    margin: 10px 0;
  }
  
  .passengerDetails {
    margin-bottom: 20px;
  }
  
  .passengerDetails table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .passengerDetails th,
  .passengerDetails td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .contactDetails {
    margin-bottom: 20px;
  }
  
  .actions {
    display: flex;
    justify-content: space-between;
  }
  
  .backButton {
    background-color: #e0e0e0;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .payButton {
    background-color: #ff5c5c;
    border: none;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .fareSummary {
    flex: 1;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .fareSummary h3 {
    margin-bottom: 20px;
  }
  
  .fareRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .totalAmount {
    font-weight: bold;
    font-size: 16px;
  }
  
  .container {
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }
  
  
  .mainContent {
    align-self: center;
    width: 100%;
    max-width: 106.6875rem;
    padding: .375rem 0 0 0.8rem;
  }
  
  .contentGrid {
    gap: 1rem;
    display: flex;
    justify-content: space-evenly;
  }
  
  .leftColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 62%;
    margin-left: 0;
  }
  .leftColumn h1{
    font-weight: 600;
  }
  
  .formContainer {
    border-radius: .625rem;
    display: flex;
    margin-top: .625rem;
    width: 100%;
    flex-direction: column;
    align-items: start;
    padding: 1.0625rem 0.75rem 3.375rem;
    border: 1px solid rgba(0, 0, 0, 1);
  }
  
  .formGrid {
    width: 54.375rem;
    max-width: 100%;
  }
  
  .rightColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 30%;
  }
  
  .flightDetails {
    display: flex;
    margin-top: 2.75rem;
    width: 100%;
    flex-direction: column;
  }