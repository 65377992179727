.container {
  border-radius: 0;
  width: 77rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
  font-weight: 400;
  margin: 1rem auto;
}
.SelectCategoryWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}
.selectorWrapper {
  border-radius: 0.625rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0.5625rem 0 3.5rem;
  border: 0.0625rem solid #898989;
}

.heading {
  color: #181818;
  align-self: flex-start;
  margin-left: 0.875rem;
  font: 600 1.55rem Poppins, sans-serif;
}

.divider {
  min-height: 0.0625rem;
  margin-top: 0.6875rem;
  width: 100%;
  border: 0.0625rem solid #898989;
}

.topGrid {
  align-self: center;
  display: flex;
  justify-content: center;
  margin-top: 2.5625rem;
  width: 100%;
  max-width: 96.9375rem;
  gap: 0.6875rem;
  flex-wrap: wrap;
}

.bottomGrid {
  align-self: center;
  display: flex;
  margin-top: 1.3125rem;
  width: 100%;
  max-width: 96.6875rem;
  gap: 2.5rem 4.8125rem;
  flex-wrap: wrap;
}

.bottomGridRight {
  display: flex;
  gap: 1.0625rem;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-basis: auto;
}

@media (max-width: 61.9375rem) {
  .selectorWrapper {
    max-width: 100%;
  }

  .heading {
    margin-left: 0.625rem;
    font-size: 4rem;
    padding: 2rem;
  }
  .divider {
    max-width: 100%;
  }

  .topGrid {
    max-width: 100%;
    margin-top: 2.5rem;
  }

  .bottomGrid {
    max-width: 100%;
  }

  .bottomGridRight {
    max-width: 100%;
  }
  .container {
    border-radius: 0;
    width: 93%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;
    font-weight: 400;
    margin: 1rem auto;
  }
}
