.container {
  border-radius: 0;
  display: flex;
  max-width: 480px;
  width: 20%;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  margin: 0 auto;
}

.filterCard {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 46px 0 20px;
}

.filterSection {
  display: flex;
  margin-left: 20px;
  width: 100%;
  flex-direction: column;
  align-items: start;
  font-size: 20px;
  color: #0a0a0a;
  font-weight: 400;
}

.sectionTitle {
  font-size: 1.1rem;
  font-weight: 600;
  text-wrap: nowrap;
}

.optionContainer {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin: 15px 0 0 10px;
}

.checkbox {
  display: flex;
  width: 16px;
  height: 16px;
  border: 1px solid #FE6F61;
}

.optionContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.checkbox {
  display: none;
}

.checkbox_text {
  font-size: 16px;
  color: #333;
  transition: color 0.2s;
  cursor: pointer;
}

.custom_checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #FE6F61;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s, border-color 0.2s;
  position: relative;
}

.custom_checkbox::after {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #FE6F61;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.2s;
}

.checkbox:checked+.custom_checkbox::after {
  opacity: 1;
}

.checkbox:focus-visible+.custom_checkbox {
  outline: 2px dotted #FE6F61;
}


.checkbox_text {
  font-size: 1rem;
}

.divider {
  min-height: 1px;
  margin-top: 20px;
  width: 100%;
  border: 1px solid #8b8b8b;
}

.durationSlider {
  width: 193px;
  max-width: 100%;
  height: 5px;
  margin: 21px 0 0 0;
  border: 3px solid #b5b5b5;
  border-radius: 5px;
}

.priceSlider {
  width: 90%;
  margin: 20px auto;
  text-align: left;
  margin-left: 20px;
}

.priceSliderLabel {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sliderContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;;
}

.priceSliderInput {
  -webkit-appearance: none;
  width: 95%;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
  outline: none;
  margin: 0 10px;
}

.priceSliderInput::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #ff6b6b;
  border-radius: 50%;
  cursor: pointer;
}

.priceSliderInput::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #ff6b6b;
  border-radius: 50%;
  cursor: pointer;
}

.priceText {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  width: 95%;
}

.minPrice,
.maxPrice {
  font-size: 14px;
  color: #666;
}